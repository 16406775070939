<template>
  <div class="paywallView flex-col gap40 jst-spc-bt">
    <SubscriptionModule :expired="true"/>

    <white-box v-if="userCanRenew">
      <h3>Test-Abonnement verlängern</h3>
      <p class="red">{{errorMessage}}</p>
      <p>Du hast die Möglichkeit, dein Testabonnement insgesamt 2 mal um 7 Tage zu verlängern. <br>
        Klicke auf den Button um deine Testversion um 7 Tage zu verlängern.</p>
      <button class="secondary" @click="renewTrial">Testabo um 7 Tage verlängern</button>
      <p class="smallText">Du kannst dein Testabonnement noch {{2-user.renewalCount}}x verlängern.</p>
    </white-box>
    <white-box v-if="!userCanRenew && user.subscription === 'trial'">
      <h3>Testabo-Verlängerung nicht mehr möglich</h3>
      <p>Du hast dein Testabonnement bereits 2x verlängert.</p>
    </white-box>
  </div>
</template>

<script>
import axios from "../../axios";
import SubscriptionModule from "../../modules/SubscriptionModule";
import WhiteBox from "../../components/WhiteBox";

export default {
  name: "PaywallView",
  components: {WhiteBox, SubscriptionModule},
  data() {
    return {
      errorMessage: ''
    }
  },
  computed: {
    user: function () {
      return this.$store.getters['auth/user'].user;
    },
    userCanRenew(){
      return (this.user.renewalCount < 2 && this.user.plan.slug === 'free');
    }
  },
  methods: {
    getStripeUrl() {
      this.stripeLoading = true;
      let user = this.$store.getters['auth/user'].user;
      let init = user.stripeId === null || user.subscription === 'trial';
      let apiPath = init === true ? 'billingInit' : 'billingPortal'
      axios.get(apiPath).then(res => {
        let form = document.createElement('form');
        form.method = 'get'
        form.action = res.data.url
        document.body.appendChild(form);
        form.submit()
        this.stripeLoading = false;
      }).catch(() => {
        this.stripeLoading = false;
      })
    },
    renewTrial(){
      axios.get('auth/renewTrial').then(() => {
        location.reload();
      }).catch(error => {
        this.errorMessage = error.response.data.message
      })
    }
  }
}
</script>

<style scoped lang="scss">
.paywallView {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding-top: 64px;
  text-align: left;
}

.whiteBox {
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
