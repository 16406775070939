const initialState = false
export const mobileModal = {
    state: {
        isActive: initialState
    },
    getters: {
        modalIsActive: state => state.isActive
    },
    mutations: {
        setModalActive(state){
            state.isActive = true;
        },
        setModalInactive(state){
            state.isActive = false;
        }
    }
}
