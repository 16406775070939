<template>
  <div v-if="test" class="flex-column gap8">
    <GoBackComponent title="Zurück zu allen Splittests" />
    <div class="headline flex-row jst-spc-bt alg-it-c">
      <h1>{{ test.name }}</h1>
      <section class="flex-row gap20">
        <DecentButtonComponent text="Beenden" v-if="test.end == null" icon-before="stop-circle" @click="showEndModal = true"/>
        <DecentButtonComponent text="Reaktivieren" v-else icon-before="play-circle" @click="showReactivateModal = true" />
        <DecentButtonComponent text="Bearbeiten" icon-before="edit" @click="showEditModal = true"/>
        <DecentButtonComponent text="Löschen" icon-before="trash" class="red"/>
      </section>
    </div>
    <div class="tags flex-row gap8">
      <TagComponent name="beendet" color="red" class="bigTag currency" v-if="test.hasEnded"/>
      <TagComponent name="aktiv" color="green" class="bigTag currency" v-else/>
      <TagComponent :name="'Start: '+test.startFormatted" color="gray" class="bigTag currency" />
    </div>
    <section class="flex-column gap20">
      <div class="flex-row gap20">
        <white-box class="tag">
          {{ test.usersCountGroupA }}
          <span>User in Gruppe A</span>
        </white-box>
        <white-box class="tag">
          {{ test.conversionsCountA }}
          <span>Conversions Gruppe A</span>
        </white-box>
        <white-box class="tag" :class="{ green: test.conversionRateA > test.conversionRateB }">
          {{ test.conversionRateA }}%
          <span>Conversionrate Gruppe A</span>
        </white-box>
      </div>
      <div class="flex-row gap20">
        <white-box class="tag">
          {{ test.usersCountGroupB }}
          <span>User in Gruppe B</span>
        </white-box>
        <white-box class="tag">
          {{ test.conversionsCountB }}
          <span>Conversions in Gruppe B</span>
        </white-box>
        <white-box class="tag" :class="{ green: test.conversionRateB > test.conversionRateA }">
          {{ test.conversionRateB }} %
          <span>Conversionrate Gruppe B</span>
        </white-box>
      </div>
    </section>
    <br>
    <SubMenuComponent :options="['Verhalten', 'User']" v-model="subMenuSelected" />
    <section v-show="subMenuSelected === 'Verhalten'">
      <div class="flex-row gap20">
        <div class="w100">
          <h3>Userverhalten</h3>
          <table-module :table-data="tableData" primarySortDefault="metric" secondary-sort-default="all-total" />
        </div>
      </div>
    </section>
    <SplitTestModal :splitTestId="id" v-if="showEditModal" @close="showEditModal = false" @success="getTest"/>
    <ModalModule v-if="showEndModal">
      <template v-slot:header>
        <h2>Splittest beenden</h2>
      </template>
      <template v-slot:body>
       <label class="flex-column">
         <span class="label">Enddatum</span>
         <input type="datetime-local" v-model="endDate" class="input">
       </label>
      </template>
      <template v-slot:footer>
        <button @click="showEndModal = false">Abbrechen</button>
        <button class="primary" @click="endTest(); showEndModal = false">Beenden</button>
      </template>
    </ModalModule>
    <ModalModule v-if="showReactivateModal">
      <template v-slot:header>
        <h2>Splittest reaktivieren?</h2>
      </template>
      <template v-slot:body>
        <p>Willst du diesen Test reaktivieren? Alle Daten, die in der Zwischenzeit gesammelt wurden, werden hier angezeigt werden.</p>
      </template>
      <template v-slot:footer>
        <button @click="showReactivateModal = false">Abbrechen</button>
        <button class="primary" @click="endDate=null;endTest(); showReactivateModal = false">Reaktivieren</button>
      </template>
    </ModalModule>
  </div>
</template>

<script>
import axios from '../../axios';
import WhiteBox from '../../components/WhiteBox.vue';
import DecentButtonComponent from "@/components/DecentButtonComponent.vue";
import SplitTestModal from "@/modules/modals/SplitTestModal.vue";
import ModalModule from "@/modules/ModalModule.vue";
import TagComponent from "@/components/TagComponent.vue";
import GoBackComponent from "@/components/GoBackComponent.vue";
import SubMenuComponent from "@/components/SubMenuComponent.vue";
import TableModule from "@/modules/TableModule.vue";

export default {
  name: 'SplitTestDetailView',
  components: {
    TableModule,
    SubMenuComponent,
    GoBackComponent, TagComponent, ModalModule, SplitTestModal, DecentButtonComponent, WhiteBox},
  data() {
    return {
      showEditModal: false,
      showEndModal: false,
      showReactivateModal: false,
      id: this.$route.params.id,
      test: {},
      endDate: new Date().toISOString().slice(0, 16),
      subMenuSelected: 'Verhalten',
      userBehaviour: [],
      tableData: {
        head: [
          { value: 'metric', label: 'Metrik', sortable: true, style: { width: '30%' } },
          { value: 'a-total', label: 'A Total', sortable: true },
          { value: 'b-total', label: 'B Total', sortable: true },
          { value: 'a-average', label: 'A Average', sortable: true },
          { value: 'b-average', label: 'B Average', sortable: true },
          { value: 'a-median', label: 'A Median', sortable: true },
          { value: 'b-median', label: 'B Median', sortable: true },
        ],
        body: [],
      },
    };
  },
  created() {
    this.getTest();
  },
  methods: {
    getTest() {
      axios.get('admin/split/' + this.id).then(res => {
        this.test = res.data;
        this.getUserBehaviour()
      });
    },
    getUserBehaviour() {
      axios.get('admin/split/' + this.id + '/user-behaviour').then(res => {
        this.userBehaviour = res.data;
        let body = [];
        let keys = Object.keys(res.data.a);


        keys.forEach(key => {
          let aTotalGreen = res.data.a[key].total > res.data.b[key].total;
          let bTotalGreen = res.data.a[key].total < res.data.b[key].total;
          let aAverageGreen = res.data.a[key].average > res.data.b[key].average;
          let bAverageGreen = res.data.a[key].average < res.data.b[key].average;
          let aMedianGreen = res.data.a[key].median > res.data.b[key].median;
          let bMedianGreen = res.data.a[key].median < res.data.b[key].median;

          body.push({
            metric: { value: key },
            'a-total': { value: res.data.a[key].total, valClasses: {green: aTotalGreen, bold: aTotalGreen} },
            'b-total': { value: res.data.b[key].total, valClasses: {green: bTotalGreen, bold: bTotalGreen}},
            'a-average': { value: res.data.a[key].average, valClasses: {green: aAverageGreen, bold: aAverageGreen} },
            'b-average': { value: res.data.b[key].average, valClasses: {green: bAverageGreen, bold: bAverageGreen} },
            'a-median': { value: res.data.a[key].median, valClasses: {green: aMedianGreen, bold: aMedianGreen} },
            'b-median': { value: res.data.b[key].median, valClasses: {green: bMedianGreen, bold: bMedianGreen} },
          });
        });
        this.tableData.body = body;

      });
    },
    endTest() {
      axios.post('admin/split/' + this.id, {end: this.endDate}).then(() => this.getTest())
    }
  },
};
</script>

<style scoped lang="scss">
.tags {
  margin-bottom: 24px;
}
.subMenu {
  box-shadow: none;
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  width: fit-content;
  padding: 4px;
  margin-bottom: 0;
}
::v-deep {
.subMenu {
span {
  padding: 4px 12px;
}
}
table {
  box-shadow: 0 0 1px 1px #e8e8e8;
}
}
</style>
