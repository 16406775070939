<template>
  <div class="flex-column wrapper">
    <v-multiselect
      v-model="selected"
      :options="usable"
      track-by="name"
      label="name"
      :max-height="150"
      open-direction="below"
      :multiple="multiple"
      :taggable="allowNew"
      @tag="createTag"
      placeholder="Tags auswählen"
      selectLabel="Auswählen"
      selectedLabel="Ausgewählt"
      deselectLabel="Entfernen"
      tagPlaceholder="+ Tag erstellen"
      @remove="removeTag">
      <template slot="option" slot-scope="props">
        <div class="option__desc">
          <span class="option__title">
            <tag-component v-if="props.option.name" :name="props.option.name" :color="props.option.color" />
            <tag-component v-else :name="props.option.label" :color="newColor" />
          </span>
        </div>
      </template>
      <template slot="tag" slot-scope="{ option, remove }" @click="remove">
        <span @click.prevent @mousedown.prevent.stop="remove(option, $event)">
          <tag-component :name="option.name" :color="option.color" :with-hover-x="true" />
        </span>
      </template>
      <template slot="caret">
        <div class="multiselect__select">
          <span><font-awesome-icon icon="chevron-down" /></span>
        </div>
      </template>
      <span slot="noResult">Keine Tags gefunden.</span>
      <span slot="noOptions" v-if="allowNew">Tippen, um einen neuen Tag zu erstellen.</span>
      <span slot="noOptions" v-else>Keine Tags verfügbar.</span>
    </v-multiselect>
    <!--    <div class="selectedTags flex-row jst-fs alg-it-c" ref="selectedTags">
          <tag-component v-for="(tag, index) in selected" :key="index" :name="tag.name" :color="tag.color"/>
        </div>
        <input class="input" v-model="searchTerm" @keyup="search" v-on:keyup.enter="enter" ref="tagSearch" :style="{paddingLeft: paddingLeft}">
        <span class="iconWrapper" @click="$refs.tagSearch.focus()">
          <font-awesome-icon icon="chevron-down" />
        </span>
        <div class="options flex-column">
          <div class="tagOptionWrapper flex-column jst-c pointer" v-for="(option, index) in filtered" :key="index" @click="selectTagByClick(option)">
            <tag-component :color="option.color" :name="option.name" @click="selectTagByClick(option)"/>
          </div>
        </div>-->
  </div>
</template>

<script>
import TagComponent from './TagComponent';

export default {
  name: 'TagInputComponent',
  components: { TagComponent },
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    allowNew: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selected: [],
      newTags: [],
      usable: JSON.parse(JSON.stringify(this.options)),
      filtered: JSON.parse(JSON.stringify(this.options)),
      searchTerm: '',
      paddingLeft: '8px',
      availableColors: [
        'orange',
        'blue',
        'lime',
        'yellow',
        'turquoise',
        'marine',
        'purple',
        'pink',
        'green',
        'red',
        'gray',
      ],
      newColor: 'orange',
    };
  },
  created() {
    this.setNewColor();
  },
  methods: {
    search() {
      let objs = this.usable;
      if (this.searchTerm !== '') {
        objs = objs.filter(obj =>
          JSON.stringify(Object.values(obj)).toLowerCase().includes(this.searchTerm.toLowerCase())
        );
      }
      this.filtered = objs;
    },
    createTag(option) {
      let tag = {
        name: option,
        color: this.newColor,
        new: true,
      };
      this.selected.push(tag);
      this.usable.push(tag);
      this.setNewColor();
    },
    setNewColor() {
      let max = this.availableColors.length - 1;
      let random = Math.floor(Math.random() * (max + 1));
      this.newColor = this.availableColors[random];
    },
    removeTag(tag) {
      if (tag.new) {
        let options = this.usable;
        options = options.filter(function (obj) {
          return obj.name !== tag.name;
        });
        this.usable = options;
      }
    },
  },
  watch: {
    selected: {
      deep: true,
      handler() {
        this.$emit('input', this.selected);
      },
    },
    options: {
      deep: true,
      handler(newOptions) {
        this.usable = JSON.parse(JSON.stringify(newOptions));
      },
    },
    value: {
      deep: true,
      handler(newSelected) {
        this.selected = newSelected;
      },
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../node_modules/vue-multiselect/dist/vue-multiselect.min.css';

.wrapper {
  width: 100%;
  position: relative;
  margin-bottom: 8px;
}

.multiselect {
  box-sizing: border-box;

  .input {
    box-shadow: none !important;
  }

  .multiselect__tags-wrap {
    display: flex;
    max-width: 100%;
    flex-wrap: wrap;
    gap: 4px;
    span {
      margin-right: 4px;
    }
  }

  .multiselect__select {
    border-left: 1px solid #e8e8e8;
    height: calc(100% - 1px);
    display: flex;
    justify-content: center;
    align-items: center;
    color: $black;

    &::before {
      color: transparent;
      border: none;
    }
  }
}

.multiselect--active {
  .multiselect__select {
    transform: none;
  }
}

/*.input {
  padding-right: 40px;
}

.input:focus ~ .options {
  visibility: visible;
  z-index: 100;
}*/

/*.options {
  visibility: visible;
  //z-index: -1;
  z-index: 100;

  position: absolute;
  top: 100%;
  background-color: white;
  width: 100%;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 2px;
  padding: 12px 0px;
  box-sizing: border-box;
  max-height: 150px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 14px;
    margin-right: 8px;
  }

  !* Track *!
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  !* Handle *!
  &::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #e8e8e8;
  }

  !* Handle on hover *!
  &::-webkit-scrollbar-thumb:hover {
    background: $black;
  }

  .tagOptionWrapper {
    box-sizing: border-box;
    padding: 4px 12px;
    width: 100%;
    height: fit-content;
    background: transparent;
    transition: 200ms all;

    &:hover {
      background: $lightViolet;
    }
  }
}*/
</style>
