<template>
  <div class="filterContainer relative flex-row alg-it-c">
    <section>
      <section class="filters flex-row">
        <div v-for="(filter, key) in usable" :key="key" class="flex-column">
          <FilterComponent :title="filter.title"
                           :icon="filter.icon"
                           :filter="filter"
                           v-model="filter.selected"
                           v-on="$listeners"
                           />
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import FilterComponent from "@/components/FilterComponent";

export default {
  name: "FiltersModule",
  components: {FilterComponent},
  props: {
    filters: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      usable: JSON.parse(JSON.stringify(this.filters)),
      activeFilters: [],
      showFilters: false,
      stopUsableWatcher: true,
      lastUpdateCameFromParent: false,
    }
  },
  created() {
    this.setActiveFilters()
  },
  methods: {
    setActiveFilters() {
      let results = [];
      for (let [key, filter] of Object.entries(this.usable)) {

        // Check if valid filter object
        if (typeof filter !== 'object' || !Object.prototype.hasOwnProperty.call(filter, 'default')) {
          break;
        }

        // Check if filter is active.
        if (filter.default === filter.selected && filter.addDefaultToRoute !== true) {
          continue;
        }

        // Get the label of the selected value.
        if (Array.isArray(filter.options) && filter.options.length > 0) {
          results.push({filterKey: key, filter: filter})
        } else if (!Array.isArray(filter.options)) {
          // Filter is an object with key value pairs.
          results.push({filterKey: key, filter: filter})
        }
      }
      this.activeFilters = results;
      this.setActiveFilterQueries()
    },
    setActiveFilterQueries() {
      let query = {};
      // Loop through active filters.

      for (let [, activeFilter] of Object.entries(this.activeFilters)) {
        let filter = activeFilter.filter;
        let filterKey = activeFilter.filterKey;

        // Set the basic values like radio and select.
        if (filter.type === 'select' || filter.type === 'radio') {
          Object.assign(query, {[filterKey]: filter.selected});
        }

        if (filter.type === 'tags') {
          Object.assign(query, {[filterKey]: filter.selected})
        }

        // Set the date values with custom start and end date.
        if (filter.type === 'date') {
          Object.assign(query, {[filterKey]: filter.selected});
          if (filter.selected === 'custom') {
            Object.assign(query, {startDate: filter.startDate});
            Object.assign(query, {endDate: filter.endDate});
          }
        }
      }

      // Convert object to string and set it as query.
      if (JSON.stringify(query) !== JSON.stringify(this.$route.query)) {
        this.$router.push({query: query})
      }
    },
    resetFilter(filter) {
      this.usable[filter].selected = this.usable[filter].default;
      this.emitFilters()
    },
    resetAllFilters(){
      for (const [key] of Object.entries(this.usable)){
        this.usable[key].selected = this.usable[key].default;
      }
      this.emitFilters()
    },
    emitFilters() {
      this.setActiveFilters()
      this.$emit('updateFilters', this.usable)
    },
  },
  watch: {
    usable: {
      handler(){
        if (!this.lastUpdateCameFromParent){
          this.emitFilters()
        } else {
          this.lastUpdateCameFromParent = false
        }
      },
      deep: true
    },
    filters: {
      handler(newFilters){
        this.lastUpdateCameFromParent = true;
        this.usable = JSON.parse(JSON.stringify(newFilters))
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
.filterContainer {
  position: relative;
}

.title, .subtitle {
  font-family: Poppins;
}

.subtitle {
  color: $labelGray;
  margin-top: 12px;
  margin-bottom: 4px;
}

.activeFilters {
  gap: 18px;
  margin-left: 24px;

  .activeFilter {
    background-color: $violet;
    padding: 6px 18px;
    border-radius: 50px;
    color: white;

    &:hover {
      background-color: $darkViolet;
    }

    .times {
      padding-left: 4px;
    }
  }
}

#dateSelectors {
  gap: 16px;

  & > .flex-column {
    width: 100%;

    & > .input {
      min-width: 100%;
      max-width: 100px;
    }
  }
}

.overlay {
  position: absolute;
  top: calc(100% + 4px);
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
  padding: 24px;
  min-width: 300px;
  border-radius: 8px;
  z-index: 100;
}

.filterPopover {
  position: absolute;
  background: white;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
  width: 120%;
  transform: translateX(-10%);
}

@media screen and (max-width: 1024px) {
  .filterContainer {
    flex-direction: row;
  }
  .toggleButton {
    width: 100%;
  }

  .filters {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start;
    box-sizing: border-box;

    .filter {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      margin-bottom: 24px;
      width: 100%;

      & > section {
        width: 100%;

        & > button {
          width: 100%;
        }
      }
    }

    .input, label {
      margin-right: 0;
      margin-bottom: 8px;
      width: 100%;
    }

    label {
      margin-bottom: 4px;
    }

    .subtitle {
      margin-top: 8px;
    }

    .closeIcon {
      position: absolute;
      right: 20px;
      top: 50px;
      font-size: 50px;
      font-weight: lighter;
      color: #333;
      text-align: right;
    }
  }
}

</style>
