<template>
  <div class="wrapper">
    <input
      required
      ref="inputField"
      type="date"
      :value="value"
      class="input"
      v-on="{ ...$listeners, input: $event => onDateSelected($event) }" />
    <div class="behindWrapper">
      <div class="behind" @click="$event => showPicker($event)">
        <font-awesome-icon icon="calendar" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DateInputComponent',
  props: {
    value: {
      required: true,
    },
  },
  methods: {
    onDateSelected: function (event) {
      this.$emit('input', event.target.value);
    },
    showPicker: function (event) {
      event.preventDefault();
      this.$refs.inputField.showPicker();
    },
  },
};
</script>

<style scoped lang="scss">
// Date Input

.wrapper {
  position: relative;
  margin-bottom: 12px;

  input {
    width: 100%;
    margin-bottom: 0;
    min-height: 40px;
  }
}

.behind {
  position: absolute;
  color: $black;
  width: 40px;
  height: 100%;
  top: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-left: 1px solid #e8e8e8;
  cursor: pointer;
  svg {
    color: $black;
    font-size: 15px;
    background-color: white;
    width: 50%;
  }
  &:active {
    box-shadow: inset 0 0 10px 1px #80808030;
  }
}
</style>
