<template>
  <div class="tableModule">
    <section class="tableControl flex-column jst-spc-bt" v-if="tableControl">
      <div class="right flex-row alg-it-c hideOnTablet">
        <Search :searchData="searchData" @updateSearch="updateSearch" v-if="search" ref="search" />
        <span id="resetFilters" class="pointer" @click="resetFilters">
          <font-awesome-icon icon="redo" />
          Filter zurücksetzen
        </span>
        <CsvExport :data="getDownloadObject" v-on="$listeners" v-if="withExport" />
      </div>
      <div v-if="hasFilters" class="left">
        <Filters :filters="filters" v-on="$listeners" ref="filterComponent" />
      </div>
    </section>
    <table-new
      :header="tableHeader"
      :body="filteredData"
      :sortable="sortable"
      :highlight-first="highlightFirst"
      :primarySortDefault="primarySortDefault"
      :secondarySortDefault="secondarySortDefault"
      :tr-clickable="trClickable"
      :empty-text="emptyText"
      v-on="$listeners"
      v-model="selectedEntries"
      :with-select="withSelect" />
    <div class="selectedEntriesControl" :class="{ show: selectedEntries.length }">
      <section>
        <b>{{ selectedEntries.length }} Einträge ausgewählt</b>
      </section>
      <section class="actions flex-row">
        <span class="pointer" @click="selectedEntries = []">Abbrechen</span>
        <span class="pointer delete" @click="showDeleteMultipleModal = true">Löschen</span>
      </section>
    </div>
    <!-- Delete Multiple Modal -->
    <ModalModule v-if="showDeleteMultipleModal">
      <div slot="header">
        {{ selectedEntries.length }}
        <span v-if="selectedEntries.length === 1">Eintrag</span><span v-else>Einträge</span> löschen?
      </div>
      <div slot="body">
        <span>
          Diese Aktion kann nicht rückgängig gemacht werden.<br /><br />
          Die zugehörigen Belege werden auch gelöscht.
        </span>
      </div>
      <div slot="footer" class="flex-row jst-spc-bt">
        <button @click="showDeleteMultipleModal = false">Abbrechen</button>
        <button class="red active" @click="deleteMultiple">
          <span>Löschen</span>
        </button>
      </div>
    </ModalModule>
  </div>
</template>

<script>
import Filters from '@/modules/FiltersModule';
import CsvExport from '@/components/CsvExportComponent';
import Search from '@/components/SearchComponent';
import TableNew from '@/components/TableNew';
import ModalModule from './ModalModule';

export default {
  name: 'TableModule',
  components: { ModalModule, TableNew, Search, CsvExport, Filters },
  props: {
    tableData: {
      type: Object,
      required: true,
      default() {
        return {
          head: [],
          body: [],
        };
      },
    },
    filters: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    search: {
      type: Boolean,
      default: false,
    },
    withExport: {
      type: Boolean,
      default: false,
    },
    exportFilename: {
      type: String,
      default: 'milkee-export.csv',
    },
    sortable: {
      type: Boolean,
      default: true,
    },
    highlightFirst: {
      type: Boolean,
      default: false,
    },
    primarySortDefault: {
      type: String,
      required: false,
      default: '',
    },
    secondarySortDefault: {
      type: String,
      required: false,
      default: 'number',
    },
    trClickable: {
      type: Boolean,
      required: false,
      default: false,
    },
    emptyText: {
      type: String,
      required: false,
    },
    withSelect: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tableControl() {
      return this.withExport || this.search || this.hasFilters;
    },
    hasFilters() {
      return !(
        this.filters &&
        Object.keys(this.filters).length === 0 &&
        Object.getPrototypeOf(this.filters) === Object.prototype
      );
    },
    filteredData() {
      return this.searchedData == null ? this.searchData : this.searchedData;
    },
    getDownloadObject() {
      let header = this.tableHeader
        .filter(obj => {
          return !(Object.prototype.hasOwnProperty.call(obj, 'classes') && obj.classes.includes('exportHidden'));
        })
        .map(obj => obj.label);

      let body = this.filteredData.map(obj => {
        let row = [];

        // Add id of the entry for file download reference.
        if (Object.prototype.hasOwnProperty.call(obj, 'id')) {
          if (Object.prototype.hasOwnProperty.call(obj.id, 'value')) {
            row.push({ id: obj.id.value });
          }
        }
        Object.values(obj).forEach(td => {
          if (Object.prototype.hasOwnProperty.call(td, 'exportVal')) {
            row.push(td.exportVal);
          } else if (Object.prototype.hasOwnProperty.call(td, 'tags')) {
            let tagString = '';
            td.tags.forEach(tag => {
              tagString += tag.name + ',';
            });
            row.push(tagString);
          } else if (!Object.prototype.hasOwnProperty.call(td, 'classes')) {
            row.push(td.value);
          } else if (!td.classes.includes('hidden') && !td.classes.includes('exportHidden')) {
            row.push(td.value);
          }
        });
        return row;
      });
      return { head: header, body: body, filename: this.exportFilename };
    },
  },
  data() {
    return {
      searchedData: null,
      searchData: JSON.parse(JSON.stringify(this.tableData.body)),
      tableHeader: JSON.parse(JSON.stringify(this.tableData.head)),
      selectedEntries: [],
      showDeleteMultipleModal: false,
    };
  },
  methods: {
    updateSearch(updatedData) {
      this.searchedData = updatedData;
    },
    resetFilters() {
      this.$refs.filterComponent.resetAllFilters();
      this.$refs.search.resetSearch();
    },
    deleteMultiple() {
      this.showDeleteMultipleModal = false;
      this.$emit('deleteMultiple', this.selectedEntries);
    },
  },
  watch: {
    tableData: {
      handler() {
        this.searchData = JSON.parse(JSON.stringify(this.tableData.body));
        this.tableHeader = JSON.parse(JSON.stringify(this.tableData.head));
        this.selectedEntries = [];
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
.tableModule {
  position: relative;
}

.tableControl {
  .right {
    gap: 24px;
  }

  > div {
    margin-bottom: 24px;
  }

  #resetFilters {
    font-size: 14px;
    color: $black;

    svg {
      margin-right: 8px;
      transition: all 0.2s;
    }

    &:hover {
      color: $violet !important;

      svg {
        transform: rotate(125deg);
      }
    }
  }
}

.entryCount {
  font-size: 20px;
  font-weight: 300;
  padding-bottom: 8px;
  margin-bottom: 0px;
}

.selectedEntriesControl {
  position: fixed;
  bottom: 24px;
  left: calc(50% + 152.5px);
  transform: translateX(-50%);
  background: $violet;
  height: 60px;
  max-width: 800px;
  width: 100%;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 24px;
  box-sizing: border-box;
  color: white;
  z-index: -10;
  opacity: 0;
  transition: all 0.2s;

  &.show {
    z-index: 10000;
    opacity: 1;
  }

  .actions {
    gap: 24px;

    span {
      padding: 4px 12px;
      border-radius: 4px;
      transition: 0.15s all;
      font-size: 0.9rem;

      &:hover {
        background: white;
        color: $violet;
      }

      &.delete {
        color: white;
        background: $red;

        &:hover {
          color: $red;
          background: white;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .tableControl {
    .left {
      width: 100%;
    }
  }
}
</style>
