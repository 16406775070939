import axios from 'axios';
import router from '../router';

const API_URL = process.env.VUE_APP_BASE_URL + '/auth/';

class AuthService {
  login(user) {
    return axios
      .post(API_URL + 'login', {
        email: user.email,
        password: user.password,
        rememberMe: user.rememberMe,
        token: user.token,
        admin: true,
      })
      .then(response => {
        if (response.data.access_token) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }
        return response.data;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  logout() {
    localStorage.removeItem('user');
    router.push({ path: '/login' });
  }

  register(user) {
    return axios
      .post(API_URL + 'register', {
        name: user.name,
        email: user.email,
        password: user.password,
        companyName: user.companyName,
        street: user.street,
        city: user.city,
        zip: user.zip,
        token: user.token,
      })
      .then(res => {
        if (res.data.access_token) {
          localStorage.setItem('user', JSON.stringify(res.data));
        }
        return res;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }
}

export default new AuthService();
