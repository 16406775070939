<template>
  <div class="wrapper">
    <input
      class="input"
      type="text"
      ref="sum"
      placeholder="0.00"
      :value="value"
      @keypress="isNumber($event)"
      @input="replaceComma($event)" />
    <div class="behind">CHF</div>
  </div>
</template>

<script>
export default {
  name: 'CurrencyInputComponent',
  props: {
    value: {
      required: true,
      validator: v => typeof v === 'string' || v === null || 'number',
    },
  },
  methods: {
    isNumber(evt) {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', ','];
      const keyPressed = evt.key;

      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault();
      }
    },
    replaceComma(event) {
      this.$emit('input', event.target.value.replace(',', '.'));
    },
    focus: function () {
      this.$refs.sum.focus();
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  position: relative;
  margin-bottom: 12px;

  input {
    width: 100%;
    margin-bottom: 0;
  }
}
.behind {
  pointer-events: none;
  position: absolute;
  color: $black;
  width: 40px;
  height: 100%;
  top: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-left: 1px solid #e8e8e8;
  font-size: 0.8rem;
  font-weight: 500;
  svg {
    margin-top: 7px;

    &:hover {
      cursor: pointer;
      color: $violet;
    }
  }
}
</style>
