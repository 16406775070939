<template>
  <div class="adminUserDetailView flex-column" v-if="user">
    <div class="headline flex-row jst-spc-bt alg-it-c">
      <h1>{{ user.name }}</h1>
      <section class="flex-row gap8">
        <DecentButtonDropDownComponent text="Aktionen" :options="actions" @delete="showDeleteModal = true" @addSubscription="addSubscription"/>
      </section>
    </div>

    <div class="flex-row gap40">
      <div class="flex-column gap20 w75">
        <div class="flex-row gap20">
          <section class="w50">
            <h3>Infos</h3>
            <white-box class="flex-column gap8">
              <section class="flex-column noGap jst-fs">
                <span class="label">Name: </span>
                {{ user.name }}
              </section>
              <section class="flex-column noGap jst-fs">
                <span class="label">E-Mail: </span>
                {{ user.email }}
              </section>
              <section class="flex-column noGap jst-fs">
                <span class="label">Firmenname: </span>
                {{ user.companyName }}
              </section>
              <section class="flex-column noGap jst-fs">
                <span class="label">Adresse: </span>
                {{ user.street }}<br/>
                {{ user.zip }} {{ user.city }}
              </section>
            </white-box>
          </section>
        </div>
        <div>
          <h3>Firmen</h3>
          <div class="flex-column">
            <white-box v-for="company in companies" :key="company.id" class="flex-row jst-spc-bt alg-it-c">
              <span>{{company.name}}</span>
              <button class="primary tiny" v-if="company.plan.slug === 'free'" @click="addSubscription(company.id)">Abo erstellen</button>
              <div v-else>
                <TagComponent name="Abo: Business" color="lime" class="currency big" v-if="company.plan.slug === 'business'"/>
                <TagComponent name="Abo: Accounting" color="green" class="currency big" v-if="company.plan.slug === 'accounting'"/>
                <TagComponent name="Abo: Free" color="orange" class="currency big" v-if="company.plan.slug === 'free'"/>
              </div>
            </white-box>
          </div>
        </div>
      </div>
      <div class="flex-column w25">
        <div>
          <h3>Kontonutzung</h3>
          <white-box>
            <section class="flex-row jst-spc-bt">
              <span>Buchungen: </span>
              {{ user.entriesCount }}
            </section>
            <section class="flex-row jst-spc-bt">
              <span>Rechnungen: </span>
              {{ user.invoicesCount }}
            </section>
            <section class="flex-row jst-spc-bt">
              <span>Kund:innen</span>
              {{ user.customerCount }}
            </section>
            <section class="flex-row jst-spc-bt">
              <span>Dateien:</span>
              {{ user.filesCount }}
            </section>
            <section class="flex-row jst-spc-bt">
              <span>Projekte</span>
              {{ user.projectsCount }}
            </section>
            <section class="flex-row jst-spc-bt">
              <span>Zeiten</span>
              {{ user.timesCount }}
            </section>
            <section class="flex-row jst-spc-bt">
              <span>Produkte</span>
              {{ user.productsCount }}
            </section>

            <section class="flex-row jst-spc-bt">
              <span>Tags: </span>
              {{ user.tagCount }}
            </section>
          </white-box>
        </div>
        <div>
          <h3>Finanzen</h3>
          <section class="flex-column">
            <white-box class="tag green">
              {{ user.totalSpendingFormatted }} CHF
              <span>Bezahlt insgesamt</span>
            </white-box>
          </section>
        </div>
        <div>
          <h3>Datennutzung</h3>
          <section class="flex-column">
            <white-box class="tag">
              {{ user.totalFileSize }}
              <span
              >Daten ({{ ((user.unreadableFileSize / (user.plan.storageInMB * 1000000)) * 100).toFixed(2) }}%)</span
              >
            </white-box>
          </section>
        </div>
      </div>
    </div>
    <ModalModule v-if="showDeleteModal" @close="showDeleteModal = false">
      <template v-slot:header>User wirklich löschen?</template>
      <template v-slot:body>
        <div class="flex-column">
          <p>Dies kann nicht rückgängig gemacht werden.</p>
          <label class="flex-column ">
            <span class="label">User-Email</span>
            <input type="email" class="input" v-model="deleteEmail">
          </label>
          <label class="flex-column ">
            <span class="label">Dein Passwort</span>
            <input type="password" class="input" v-model="deletePassword">
          </label>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex-row jst-spc-bt w100">
          <button class="" @click="showDeleteModal = false">Abbrechen</button>
          <button class="red active" @click="deleteUser">Löschen</button>
        </div>
      </template>
    </ModalModule>
    <ModalModule v-if="showAddSubscriptionModal" @close="showAddSubscriptionModal = false">
      <template v-slot:header>Neues Abo hinzufügen</template>
      <template v-slot:body>
        <div class="flex-column gap8">
          <p>Dies kann nicht rückgängig gemacht werden.</p>
          <label class="flex-column ">
            <span class="label">Abo auswählen</span>
            <select class="input" v-model="planId">
              <option v-for="plan in plans" :value="plan.id" :key="plan.id">{{ plan.name }}</option>
            </select>
          </label>
          <label class="flex-column ">
            <span class="label">Abo startet ab:</span>
            <DateInputComponent v-model="subscriptionCreatedDate" />
          </label>
          <label>
            <checkbox-component class="alg-it-c">
              <input type="checkbox" class="small" v-model="hasCustomPrice">
              <span class="label">Preis überschreiben?</span>
            </checkbox-component>
          </label>
          <label class="flex-column" v-if="hasCustomPrice">
            <span class="label">Bezahlter Preis:</span>
            <CurrencyInputComponent v-model="price" />
          </label>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex-row jst-spc-bt w100">
          <button class="" @click="showAddSubscriptionModal = false">Abbrechen</button>
          <button class="primary active" @click="doAddSubscription" >Speichern</button>
        </div>
      </template>
    </ModalModule>
  </div>
</template>

<script>
import WhiteBox from '@/components/WhiteBox';
import axios from '@/axios';
import TagComponent from '../../components/TagComponent.vue';
import DecentButtonDropDownComponent from "@/components/DecentButtonDropDownComponent.vue";
import ModalModule from "@/modules/ModalModule.vue";
import DateInputComponent from "@/components/DateInputComponent.vue";
import CurrencyInputComponent from "@/components/CurrencyInputComponent.vue";
import CheckboxComponent from "@/components/CheckboxComponent.vue";

export default {
  name: 'AdminUserDetailView',
  components: {
    CheckboxComponent,
    CurrencyInputComponent,
    DateInputComponent, ModalModule, DecentButtonDropDownComponent, TagComponent, WhiteBox},
  data() {
    return {
      userId: this.$route.params.userId,
      showDeleteModal: false,
      showAddSubscriptionModal: false,
      deletePassword: '',
      deleteEmail: '',
      user: null,
      actions: [
        {title: 'Abo erstellen', callback: 'addSubscription', icon: 'plus'},
        {title: 'Löschen', callback: 'delete', icon: 'trash', classes: ['red']}
      ],
      plans: [],
      planId: null,
      subscriptionCreatedDate: new Date().toISOString().slice(0, 10),
      hasCustomPrice: false,
      price: null,
      companies: [],
      currentCompanyId: null,
    };
  },
  mounted() {
    this.getUser();
  },
  methods: {
    getPlans(){
      axios.get('admin/subscription/plans').then(res => {
        this.plans = res.data;
      });
    },
    getCompanies() {
      axios.get('admin/user/'+this.userId+'/companies').then(res => {
        this.companies = res.data;
      });
    },
    addSubscription(companyId){
      this.currentCompanyId = companyId;
      this.getPlans();
      this.showAddSubscriptionModal = true;
    },
    doAddSubscription() {
      let data = {
        planId: this.planId,
        startDate: this.subscriptionCreatedDate,
      };
      if (this.hasCustomPrice) {
        data.price = this.price;
      }

      axios.post('admin/companies/'+this.currentCompanyId+'/add-manual-subscription', data).then(() => {
        this.$toast.success('Abo hinzugefügt');
        this.getUser();
        this.showAddSubscriptionModal = false;
      })
    },
    getUser() {
      axios.get('admin/user/' + this.userId).then(res => {
        this.user = res.data;
        this.getCompanies();
      });
    },
    deleteUser() {
      if (this.user.email !== this.deleteEmail) {
        this.$toast.error('E-Mail stimmt nicht überein');
        return;
      }
      axios.delete('admin/user/' + this.userId, {params: {password: this.deletePassword}}).then(() => {
        this.$toast.success('Nutzerdaten gelöscht')
        this.getUser();
        this.showDeleteModal = false;
      }).catch(e => {
        this.$toast.error(e.response.data.message || 'Fehler beim Löschen der Nutzerdaten')
      });
    },
  },
};
</script>

<style scoped lang="scss">
.flex-row,
.flex-column {
  gap: 24px;
}
label {
  &.flex-column
  {
    gap: 4px;
  }
}
h3 {
  margin-bottom: 4px;
}

.noGap {
  gap: 0;
}
</style>
