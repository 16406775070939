<template>
  <div class="wrapper flex-row alg-it-c relative statusComponent" :class="[align]">
    <div class="tooltip">{{ tooltip }}</div>
    <div class="circle" :class="[status]"></div>
  </div>
</template>

<script>
export default {
  name: "StatusDotComponent",
  props: {
    tooltip: {
      type: String,
      default: 'offen',
    },
    status: {
      type: String,
      default: 'open'
    },
    align: {
      type: String,
      default: 'jst-c'
    }
  }
}
</script>

<style lang="scss" scoped>
.statusComponent {
  min-width: 12px;
}
.circle {
  height: 10px;
  width: 10px;
  max-height: 10px;
  border-radius: 50%;
  border: 1px solid #E8E8E8;

}
.open, .gray {
  background: #F0F0F0;
}
.paid, .green {
  background: #5FA052;
  border-color: #E3F1D5;
}
.invoiced, .orange, .sent {
  background: #EB9C5C;
  border-color: #FBDD9F;
}
.red, .overdue {
  border-color: $lightRed;
  background: $red;
}
.jst-fs {
  >.tooltip {
    left: 6px;
  }
}
.jst-c {
  >.tooltip {
    left: 50%;
  }
}

.tooltip {
  position: absolute;
  top: -185%;
  transform: translateX(-50%);
  max-width: 130px;
  text-align: center;
  background: $black;
  white-space: nowrap;
  color: white;
  padding: 2px 12px;
  border-radius: 2px;
  visibility: hidden;
  transition: 0.2s all;
  opacity: 0;
  font-size: 0.7rem;
  font-weight: bolder;
  text-transform: lowercase;

  &:after {
    content: "";
    position: absolute;
    top: calc(100% - 2px);
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid $black;
    border-radius: 2px;
    right: 50%;
    -webkit-transform: translateX(50%) rotate(-180deg);
    transform: translateX(50%) rotate(-180deg);
  }
}

td {
  height: 100%;
  width: 100%;
  min-width: 15px;
  &:hover {
    .tooltip {
      visibility: visible;
      opacity: 1;
    }
  }
}
</style>
