<template>
  <div class="wrapper flex-row jst-fe alg-it-c relative" v-if="!locked">
    <section class="icon" @click="showPopup = true" >
      <font-awesome-icon icon="ellipsis-h" />
    </section>
    <section class="click" v-if="showPopup">
      <span v-for="(option, index) in options" @click="$emit(option.callback, identifier); showPopup = false" :key="index" :class="option.classes">
        <font-awesome-icon v-if="Object.prototype.hasOwnProperty.call(option, 'icon')" :icon="option.icon"/>
        {{option.title}}
      </span>
    </section>
<!--    <div class="tooltip" :class="{locked: locked}"> Jahr ist abgeschlossen</div>
    <button class="icon pointer" @click="$emit('upload')" v-if="upload" :disabled="locked"><font-awesome-icon icon="upload" /></button>
    <button class="icon pointer" @click="$emit('edit')" :disabled="locked"><font-awesome-icon icon="edit"/></button>
    <button class="icon pointer delete" @click="$emit('delete')" :disabled="locked"><font-awesome-icon icon="trash" /></button>-->
    <div class="backdrop" @click="showPopup = false" v-if="showPopup"></div>
  </div>
  <div v-else class="wrapper flex-row jst-fe alg-it-c relative locked">
    <div class="tooltip" :class="{locked: locked}"> {{lockedToolTip}}</div>
    <font-awesome-icon icon="lock" />
  </div>
</template>

<script>
export default {
  name: "ControlButton",
  props: {
    locked:{
      type: Boolean,
      default: false,
    },
    upload: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      required: true
    },
    identifier: {
      type: [String, Number, Object]
    },
    lockedToolTip: {
      type: String,
      default: 'Jahr ist abgeschlossen'
    }
  },
  data(){
    return {
      showPopup: false
    }
  }
}
</script>

<style scoped lang="scss">
.icon {
  height: 100%;
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
}
button {
  border: none;
  font-size: 15px;
  color: $black;
  background: transparent;
  &:hover {
    color: $violet;
  }
}
.icon {
  box-sizing: border-box;
  padding: 4px 8px;
  border-left: none;
  &:hover {
    color: $violet;
  }
  &:first-of-type {
    margin-left: -8px;
  }
}
.delete:hover {
  color: $red;
}

button:disabled {
  color: $labelGray;
  border: none;
  &:first-of-type {
    border-right: none;
  }
  &:hover {
    cursor: not-allowed;
    background: transparent;
    color: $grayText;
  }
}
.wrapper.locked:hover > .tooltip{
  visibility: visible;
  opacity: 1;
}

.click {
  display: flex;
  flex-direction: column;
  gap: 2px;
  position: absolute;
  top: calc(100% + 4px);
  right: -24px;
  width: fit-content;
  min-width: 100%;
  background: white;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 12px 12px;
  box-sizing: border-box;
  z-index: 1000;

  &:after {
    content: "";
    position: absolute;
    top: -8px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
    border-radius: 2px;
    right: 28px;
  }
  span {
    padding: 4px 8px;
    transition: all 0.2s;
    border-radius: 4px;
    white-space: nowrap;
    font-weight: bolder;
    font-size: 0.85rem;
    svg {
      padding-right: 2px;
      font-size: 0.8rem;
      width: 12px;
    }
    &:hover {
      background: $lightViolet;
      cursor: pointer;
    }
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.0);
}
.tooltip {
  position: absolute;
  top: -155%;
  transform: translate(40%, -50%);
  width: 130px;
  height: fit-content;
  max-width: 130px;
  text-align: center;
  background: $black;
  color: white;
  padding: 2px;
  border-radius: 2px;
  visibility: hidden;
  transition: 0.2s all;
  opacity: 0;
  font-size: 0.7rem;
}

.locked {
  &:hover {
    .tooltip {
      visibility: visible;
      opacity: 1;
    }
  }
}
</style>
