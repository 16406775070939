<template>
  <div class="profileView">
    <h1>
      Einstellungen -
      <span v-if="selected === 'tags'"> Tags</span>
      <span v-if="selected === 'expenseType'"> Aufwandskonten</span>
      <span v-if="selected === 'subscription'"> Abonnement</span>
      <span v-if="selected === 'invoices'"> Rechnungen & MWST</span>
      <span v-if="selected === 'company'"> Mein Unternehmen</span>
      <span v-if="selected === 'profile'"> Profil</span>
      <span v-if="selected === 'reminder'"> Erinnerungen</span>
      <span v-if="selected === 'import'"> Daten importieren</span>
      <span v-if="selected === 'addons'"> Erweiterungen</span>
    </h1>
    <section>
      <section class="leftMenu">
        <router-link :class="{ active: selected === 'profile' }" to="/profile/profile"><font-awesome-icon icon="user" class="icon" /> Mein Profil</router-link>
        <router-link :class="{ active: selected === 'company' }" to="/profile/company"><font-awesome-icon icon="building" class="icon" /> Mein Unternehmen</router-link>
        <router-link :class="{ active: selected === 'invoices' }" to="/profile/invoices"><font-awesome-icon icon="file-alt" class="icon" /> Rechnungen & MWST</router-link>
        <router-link :class="{ active: selected === 'subscription' }" to="/profile/subscription" id="subscriptionLink">
          <font-awesome-icon icon="credit-card" class="icon" /> Abonnement
        </router-link>
        <router-link :class="{ active: selected === 'tags' }" to="/profile/tags"><font-awesome-icon icon="tag" class="icon" /> Tags</router-link>
        <router-link :class="{ active: selected === 'expenseType' }" to="/profile/expenseType"
          >
          <font-awesome-icon icon="calculator" class="icon" /> Aufwandskonten</router-link
        >
        <router-link :class="{ active: selected === 'reminder' }" to="/profile/reminder">
          <font-awesome-icon icon="bell" class="icon" /> Erinnerungen</router-link>
        <router-link v-if="userCanImport" :class="{ active: selected === 'import' }" to="/profile/import"
          > Daten importieren</router-link
        >
      </section>
      <section class="contents">
        <ProfileSettingsModule v-if="selected === 'profile'" />
        <CompanySettingsModule v-if="selected === 'company'" />
        <InvoicesSettingsModule v-if="selected === 'invoices'" />
        <SubscriptionModule v-if="selected === 'subscription'" />
        <ImportModule v-if="selected === 'import'" />
        <ExpenseTypeSettingsModule v-if="selected === 'expenseType'" />
        <div v-if="selected === 'subscriptions'">
          <white-box>
            <h2>Dein Abonnement</h2>
            <p v-if="user.subscription === 'trial'">
              Deine kostenlose Testphase endet am {{ user.subscriptionUntil }}.
            </p>
            <p v-if="!user.stripeId || user.subscription === 'trial'">
              Du kannst dein
              <span class="link" @click="getStripeUrl(true)">Abonnement hier verlängern.</span>
            </p>
            <p v-if="user.stripeId && user.subscription !== 'trial'">
              Du kannsft dein
              <span class="link" v-if="!stripeLoading" @click="getStripeUrl">Abonnement hier verwalten.</span>
              <span class="lds-dual-ring" v-if="stripeLoading"></span>
            </p>
          </white-box>
        </div>
        <TagsSettingsModule v-if="selected === 'tags'" />
        <div v-if="selected === 'reminder'" class="reminderWrapper">
          <white-box class="reminder">
            <h2>Erinnerung einrichten</h2>
            <p>
              Richte eine Erinnerung per SMS oder E-Mail ein. <br />Wir erinnern dich dann im gewünschten Abstand daran,
              deine Buchhaltung nachzuführen.
            </p>
            <p v-if="reminder.active">
              Wir senden dir
              <span v-if="reminder.interval === 'weekly'">
                <b> jeden {{ weekdays[reminder.ruleSecond] }}</b>
                eine Erinnerung zum Nachführen deiner Buchhaltung.
              </span>
              <span v-if="reminder.interval === 'monthly'">
                immer
                <b v-if="reminder.ruleFirst === 'first'"> am ersten</b>
                <b v-if="reminder.ruleFirst === 'last'"> am letzten</b>
                <b v-if="reminder.ruleFirst !== 'fixed'"> {{ weekdays[reminder.ruleSecond] }}</b>
                <b v-if="reminder.ruleFirst === 'fixed'"> am {{ reminder.ruleSecond }}.</b>
                <b> im Monat</b> um 7:00 Uhr eine Erinnerung per
                <span v-if="reminder.method === 'phone'">
                  SMS an <b>{{ reminder.phone }}</b></span
                >
                <span v-else> E-Mail</span>.
              </span>
            </p>
            <p v-else>Wir senden dir keine Erinnerung.</p>

            <white-box class="reminderWhiteBox" v-if="hideReminderSettings">
              <h3 @click="hideReminderSettings = !hideReminderSettings" class="pointer">
                Einstellungen
                <font-awesome-icon icon="chevron-down" />
              </h3>
            </white-box>

            <white-box class="reminderWhiteBox" v-if="!hideReminderSettings">
              <h3 @click="hideReminderSettings = !hideReminderSettings" class="pointer">
                Einstellungen
                <font-awesome-icon icon="chevron-up" />
              </h3>
              <checkbox
                ><label><input type="checkbox" v-model="reminder.active" /> Erinnerung aktivieren</label>
              </checkbox>
              <div v-if="reminder.active" class="reminderSetup">
                <h4>Kontaktmethode wählen</h4>
                <section>
                  <label>
                    Wie willst du erinnert werden?
                    <select class="input" v-model="reminder.method">
                      <option value="email">Per E-Mail</option>
                      <option value="phone">Per SMS</option>
                    </select>
                  </label>
                  <label v-if="reminder.method === 'phone'">
                    Wie lautet deine Telefonnummer:
                    <input type="text" placeholder="079 952 07 98" class="input" v-model="reminder.phone" />
                  </label>
                </section>
                <h4>Intervall</h4>
                <section>
                  <label>
                    Wie oft sollen wir dich erinnern?
                    <select class="input" v-model="reminder.interval">
                      <option value="monthly">Monatlich</option>
                      <option value="weekly">Wöchentlich</option>
                    </select>
                  </label>
                  <label v-if="reminder.interval === 'monthly'">
                    Immer am
                    <select class="input" v-model="reminder.ruleFirst">
                      <option value="first">Am ersten...</option>
                      <option value="last">Am letzten...</option>
                      <option value="fixed">An fixem Tag...</option>
                    </select>
                  </label>
                  <label v-if="reminder.interval === 'monthly' && reminder.ruleFirst !== 'fixed'">
                    Wochentag auswählen
                    <select class="input" v-model="reminder.ruleSecond">
                      <option v-for="(day, index) in weekdays" :key="index" :value="index">
                        {{ day }}
                      </option>
                    </select>
                  </label>
                  <label v-if="reminder.interval === 'monthly' && reminder.ruleFirst === 'fixed'">
                    Tag auswählen
                    <select class="input" v-model="reminder.ruleSecond">
                      <option v-for="x in 31" :key="x" :value="x">{{ x }}.</option>
                    </select>
                  </label>
                  <label v-if="reminder.interval === 'weekly'">
                    Wochentag auswählen
                    <select class="input" v-model="reminder.ruleSecond">
                      <option v-for="(day, index) in weekdays" :key="index" :value="index">
                        {{ day }}
                      </option>
                    </select>
                  </label>
                </section>
              </div>
              <div class="submits">
                <span></span>
                <button class="primary" @click="saveReminder">Speichern</button>
              </div>
            </white-box>
          </white-box>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import axios from '../../axios';
import WhiteBox from '../../components/WhiteBox';
import Checkbox from '../../components/CheckboxComponent';
import SubscriptionModule from '../../modules/SubscriptionModule';
import ImportModule from '../../modules/ImportModule';
import ExpenseTypeSettingsModule from '../../modules/ExpenseTypeSettingsModule';
import TagsSettingsModule from '../../modules/TagsSettingsModule';
import ProfileSettingsModule from '../../modules/ProfileSettingsModule';
import CompanySettingsModule from "../../modules/CompanySettingsModule.vue";
import InvoicesSettingsModule from "../../modules/InovicesSettingsModule.vue";

export default {
  name: 'ProfileView',
  components: {
    InvoicesSettingsModule,
    CompanySettingsModule,
    ProfileSettingsModule,
    TagsSettingsModule,
    ExpenseTypeSettingsModule,
    ImportModule,
    SubscriptionModule,
    Checkbox,
    WhiteBox,
  },
  data() {
    return {
      category: 'choose',
      tags: [],
      updated: null,
      user: null,
      stripeLoading: false,
      oldPass: null,
      newPass: null,
      newRep: null,
      reminder: {
        active: false,
        method: 'email',
        phone: null,
        interval: 'monthly',
        ruleFirst: 'first',
        ruleSecond: 'monday',
      },
      weekdays: {
        monday: 'Montag',
        tuesday: 'Dienstag',
        wednesday: 'Mittwoch',
        thursday: 'Donnerstag',
        friday: 'Freitag',
        saturday: 'Samstag',
        sunday: 'Sonntag',
      },
      hideReminderSettings: true,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    selected: function () {
      return this.$route.params.setting === undefined ? 'profile' : this.$route.params.setting;
    },
    userCanImport() {
      return this.$store.getters['auth/userCanImport'];
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login');
    }
    this.getUser();
    this.$gtag.pageview({ page_path: '/profile' });
    this.getReminder();
  },
  methods: {
    resetPass() {
      if (this.newPass === this.newRep) {
        axios
          .post('/auth/update', {
            oldPassword: this.oldPass,
            newPassword: this.newPass,
          })
          .then(res => {
            this.user = res.data;
            this.$toast.success('Dein Passwort wurde aktualisiert.');
          })
          .catch(e => {
            this.$store.dispatch('notices/addNotice', {
              type: 'error',
              notice: e.response.data.message,
            });
          });
      } else {
        this.$toast.error('Passwörter stimmen nicht überein.');
      }
    },
    getStripeUrl(init = false) {
      this.stripeLoading = true;
      let apiPath = init === true ? 'billingInit' : 'billingPortal';
      axios
        .get(apiPath)
        .then(res => {
          let form = document.createElement('form');
          form.method = 'get';
          form.action = res.data.url;
          document.body.appendChild(form);
          form.submit();
          this.stripeLoading = false;
        })
        .catch(() => {
          this.stripeLoading = false;
        });
    },
    getUser() {
      axios
        .post('auth/me')
        .then(res => {
          this.user = res.data;
        })
        .catch(() => {});
    },
    active() {
      return this.tags.filter(cat => {
        return cat.archived === 0;
      });
    },
    archived() {
      return this.tags.filter(cat => {
        return cat.archived === 1;
      });
    },
    select() {
      let cats = [...this.tags];
      this.updated = cats.filter(cat => {
        return cat.id === this.category;
      })[0];
    },
    unarchive() {
      this.updated.archived = 0;
      this.save();
    },
    save() {
      axios
        .post('entry/tags/' + this.updated.id, this.updated)
        .then(() => {
          this.settags();
          this.reset();
          this.$store.dispatch('notices/addNotice', {
            type: 'success',
            notice: 'Kategorie aktualisiert.',
          });
        })
        .catch(() => {
          this.$store.dispatch('notices/addNotice', {
            type: 'error',
            notice: 'Kategorie konnte nicht aktualisiert werden.',
          });
        });
    },
    del() {
      axios
        .delete('entry/tags/' + this.updated.id)
        .then(() => {
          this.settags();
          this.$store.dispatch('notices/addNotice', {
            type: 'success',
            notice: 'Kategorie wurde archiviert / gelöscht.',
          });
          this.reset();
        })
        .catch(() => {});
    },
    saveUser() {
      axios
        .post('/auth/update', this.user)
        .then(res => {
          this.user = res.data;
          this.$store.dispatch('auth/updateUser', res.data);
          this.$toast.success('Deine Daten wurden aktualisiert.');
        })
        .catch(() => {});
    },
    reset() {
      this.category = 'choose';
    },
    saveReminder() {
      if (!this.reminder.active) {
        axios
          .delete('reminder')
          .then(() => {
            this.setReminder(null);
            this.$toast.success('Erinnerung wurde deaktiviert.');
            this.$store.dispatch('notices/addNotice', {
              type: 'success',
              notice: 'Erinnerung wurde deaktiviert.',
            });
          })
          .catch(() => {});
      } else {
        let rule =
          this.reminder.interval === 'monthly'
            ? this.reminder.ruleFirst + ':' + this.reminder.ruleSecond
            : 'weekly:' + this.reminder.ruleSecond;
        let reminder = {
          type: this.reminder.method,
          interval: this.reminder.interval,
          rule: rule,
          phoneNumber: this.reminder.phone,
        };
        axios
          .post('reminder', reminder)
          .then(res => {
            this.setReminder(res.data);
            this.$toast.success('Erinnerung wurde gespeichert.');
          })
          .catch(() => {});
      }
      this.hideReminderSettings = true;
    },
    setReminder(reminder) {
      if (reminder === null) {
        this.reminder.active = false;
      } else {
        this.reminder.active = true;
        this.reminder.method = reminder.type;
        this.reminder.interval = reminder.interval;
        this.reminder.phone = reminder.type === 'phone' ? reminder.phoneNumber : null;
        let rule = reminder.rule.split(':');
        if (reminder.interval === 'monthly') {
          this.reminder.ruleFirst = rule[0];
          this.reminder.ruleSecond = rule[1];
        } else if (reminder.interval === 'weekly') {
          this.reminder.ruleFirst = 'weekly';
          this.reminder.ruleSecond = rule[1];
        }
      }
    },
    getReminder() {
      axios
        .get('reminder')
        .then(res => {
          this.setReminder(res.data);
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped lang="scss">
h1 {
  margin-bottom: 24px;
}

select {
  padding-right: 32px;
}

section {
  display: flex;
  gap: 100px;

  .leftMenu {
    flex-basis: 20%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: #6b7280;

    a {
      color: #2c3e50;
      padding: 4px 8px;
      border-radius: 5px;
      font-size: 0.9rem;
      transition: all 0.2s;
      cursor: pointer;

      &.active,
      &:hover {
        color: #6556eb;
        background: rgba(77, 54, 227, 0.1);
      }
    }
  }
}

.profileSet {
  display: flex;
  flex-direction: column;
  gap: 16px;

  section {
    gap: 4px;
    display: flex;
    flex-direction: column;

    &.line {
      display: flex;
      flex-direction: row;
      gap: 24px;
    }
  }

  .submits {
    margin-top: 15px;
    display: flex;
    align-items: flex-end;
  }
}

.tags {
  section {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &:first-of-type {
      flex-direction: row;
      align-items: center;
      margin-bottom: 16px;
    }

    &:last-of-type {
      flex-direction: row;
      justify-content: space-between;
      margin-top: 24px;
    }
  }
}

.submits {
  span {
    cursor: pointer;
  }
}

.contents {
  flex-basis: 85%;
}

.reminderWrapper {
  width: 100%;

  h4 {
    margin-bottom: 0px;
  }

  .checkboxContainer {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .reminder {
    width: 100%;
  }

  .reminderSetup {
    display: flex;
    flex-direction: column;

    label {
      display: flex;
      flex-direction: column;
      margin-top: 8px;
      margin-bottom: 16px;
    }
  }
}

.reminderWhiteBox {
  box-shadow: 0 0 4px rgba(51, 51, 51, 0.13);
}

@media screen and (max-width: 1024px) {
  .profile > section {
    flex-direction: column;
    gap: 24px;

    .leftMenu {
      flex-direction: row;
      overflow: scroll;

      a {
        white-space: nowrap;
      }
    }

    .profileBox {
      width: 100%;

      .line {
        flex-direction: column;
      }
    }

    .contents {
      width: 100%;
      align-items: stretch;
    }
  }
  .reminderWrapper {
    .reminder {
      .reminderSetup {
        section {
          flex-direction: column;
          gap: 0;
        }
      }
    }
  }
}
.icon {
  width: 14px;
  margin-right: 8px;
}
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
  #subscriptionLink {
    display: none;
  }
}
@media screen and (max-device-width: 1024px) {
  #subscriptionLink {
    display: none;
  }
}
</style>
