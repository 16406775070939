<template>
  <div v-if="isAdmin" class="adminView">
    <h1>Admin Dashboard</h1>
    <section v-if="averages" class="bilanz">
      <white-box class="tag">{{ averages.users }}<span>Nutzende</span></white-box>
      <white-box class="tag green">{{ averages.usersToday }}<span>Heute registriert</span></white-box>
      <white-box class="tag">{{ averages.userTrial }}<span>Aktive Trials</span></white-box>
      <white-box class="tag">{{ averages.userPaying }}<span>Zahlende User</span></white-box>
    </section>
    <h3>Nutzungsmetriken</h3>
    <div class="choose flex-row jst-spc-bt">
      <label :class="{ active: filter === 'free' }">
        <input type="radio" value="free" v-model="filter" />💭 Trials
      </label>
      <label :class="{ active: filter === 'paid' }"
        ><input type="radio" value="paid" v-model="filter" />💸 Zahlend</label
      >
      <label :class="{ active: filter === 'inactive' }"
        ><input type="radio" value="inactive" v-model="filter" />💤 Inaktiv</label
      >
    </div>
    <section class="bilanz" v-if="averages">
      <white-box class="tag">{{ averages.entriesTotal }}<span>Buchungen total</span></white-box>
      <white-box class="tag">{{ averages.entriesPerUser }}<span>Buchungen / User</span></white-box>
      <white-box class="tag">{{ averages.filesTotal }}<span>Dateien total</span></white-box>
      <white-box class="tag">{{ averages.filesPerUser }}<span>Dateien / User</span></white-box>
      <white-box class="tag">{{ averages.categoriesTotal }}<span>Tags Total</span></white-box>
      <white-box class="tag">{{ averages.catsPerUser }}<span>Tags / User</span></white-box>
      <white-box class="tag">{{ averages.fileSizeSum }}<span>Total</span></white-box>
    </section>
    <h3>
      <span v-if="filter === 'paid'">Zahlende Nutzer:innen</span>
      <span v-else-if="filter === 'inactive'">Inaktive Nutzer:innen</span>
      <span v-else-if="filter === 'free'">Aktive Trials</span>
      ({{ users.length }})
    </h3>
    <table-module
      :table-data="tableData"
      :search="true"
      :tr-clickable="true"
      @trClicked="openUser"
      secondary-sort-default="date"
      @updateFilters="updateFilter" />
  </div>
  <div v-else></div>
</template>

<script>
import axios from '@/axios';
import WhiteBox from '@/components/WhiteBox';
import TableModule from '@/modules/TableModule';

export default {
  name: 'AdminView',
  components: { TableModule, WhiteBox },
  data() {
    return {
      users: [],
      activeUsers: [],
      activeTrials: [],
      inactiveUsers: [],
      averages: null,
      filter: this.$route.params.filter,
      filters: {
        lastActive: {
          title: 'Zuletzt aktiv',
          type: 'date',
          default: 'all',
          icon: 'clock',
          addDefaultToRoute: true,
          selected: this.$route.query.lastActive ? this.$route.query.lastActive : '30days',
          options: [
            { label: 'Gesamter Zeitraum', value: 'all' },
            { label: 'Letzte 30 Tage', value: '30days' },
            { label: 'Letzte 90 Tage', value: 'last90days' },
            { label: 'Mehr als 90 Tage', value: 'more90days' },
          ],
        },
      },
      tableData: {
        head: [
          { value: 'date', label: 'Datum', sortable: true, style: { width: '10%', maxWidth: '100px' } },
          { value: 'email', label: 'E-Mail', sortable: true, style: { width: '30%' } },
          { value: 'companies', label: 'Firmen', sortable: true, style: { width: '10%' } },
          { value: 'entries', label: 'Buchungen', sortable: true, style: { width: '10%' } },
          { value: 'files', label: 'Dateien', sortable: true, style: { width: '10%' } },
          { value: 'fileSize', label: 'Dateigrösse', sortable: true, style: { width: '10%' } },
          { value: 'recentActivity', label: 'Aktivität', sortable: true, style: { width: '10%' } },
        ],
        body: [],
      },
    };
  },
  computed: {
    isAdmin: function () {
      console.log(this.$store.getters['auth/user'].user)
      return this.$store.getters['auth/user'].user.isAdmin === 1;
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    openUser(user) {
      this.$router.push('/user/' + user.id.value);
    },
    getActiveFilterQuery() {
      let params = { ...this.$route.query };
      return params;
    },
    updateFilter(filters) {
      this.filters = filters;
      this.getData();
    },
    getData() {
      if (!this.isAdmin) {
        return false;
      }
      this.$store.commit('setLoadingSteps', {
        users: false,
      });

      let params = this.getActiveFilterQuery();
      axios
        .get('admin/statistics?filter=' + this.filter)
        .then(res => {
          this.averages = res.data;
        })
        .catch(() => {});

      axios
        .get('admin/users?filter=' + this.filter, { params: params })
        .then(res => {
          this.users = res.data;
          let users = res.data;
          let data = [];
          users.forEach(user => {
            data.push({
              date: { value: this.formatDate(user.created_at), sortable: user.created_at },
              id: { value: user.id, sortable: user.id, classes: ['hidden'] },
              email: { value: user.email, sortable: user.email },
              companies: { value: user.ownedCompanyCount, sortable: user.ownedCompanyCount },
              entries: { value: user.entriesCount, sortable: user.entriesCount },
              files: { value: user.filesCount, sortable: user.filesCount },
              fileSize: { value: user.totalFileSize, sortable: parseFloat(user.unreadableFileSize) },
              recentActivity: {
                value: user.recentActivity.readable,
                timeTag: true,
                sortable: user.recentActivity.sortable,
              },
            });
          });
          this.$store.commit('updateLoadingStepByKey', { key: 'users', status: true });
          this.tableData.body = data;
        })
        .catch(() => {
          this.$store.commit('updateLoadingStepByKey', { key: 'users', status: true });
        });
    },
    formatDate(date) {
      let dateParts = new Date(date).toISOString().slice(0, 10).split('-');
      return dateParts[2] + '.' + dateParts[1] + '.' + dateParts[0];
    },
  },
  watch: {
    filter() {
      this.$router.push('/users/' + this.filter);
      this.getData();
    },
  },
};
</script>

<style scoped lang="scss">
.adminView {
  padding: 0 20px;
}

h3 {
  margin-bottom: 12px;
  margin-top: 24px;
}

.choose {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  background-color: white;
  padding: 4px 4px;
  border-radius: 5px;

  label {
    cursor: pointer;
    width: 33%;
    text-align: center;
    font-size: 0.85rem;
    transition: all 0.3s;

    &.active,
    &:hover {
      background: rgba(77, 54, 227, 0.1);
      color: $violet;
      border-radius: 4px;
    }
  }

  input {
    display: none;
  }
}

.bilanz {
  flex-basis: 20%;
  display: flex;
  justify-content: space-between;
  gap: 24px;
  margin-top: 24px;
  margin-bottom: 32px;

  .tag {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;

    span {
      font-size: 0.9rem;
      font-weight: normal;
      margin-top: 4px;
    }
  }
}

.tag.gray {
  background-color: $darkGray;
  font-size: 0.9rem;
  border-radius: 50px;
  color: $grayText;
  font-weight: bold;
}

@media screen and (max-width: 900px) {
  .whiteBox {
    flex-basis: 50%;
  }
  .bilanz {
    flex-wrap: wrap;
    justify-content: space-between;

    & > div {
      flex: 1 0 40%;
    }
  }
}
</style>
