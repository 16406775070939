<template>
  <div class="inputMobile flex-row jst-spc-bt alg-it-c">
    <label class="flex-row jst-spc-bt alg-it-c w100" v-if="!showColumn">
      <span class="bolder">{{ label }}</span>
      <input v-if="type==='text'" type="text" v-bind:value="value" @input="$emit('input', $event.target.value)" :placeholder="placeholder">
      <input v-if="type==='number'" type="number" v-bind:value="value" @input="$emit('input', $event.target.value)">
      <input v-if="type==='date'" type="date" v-bind:value="value" @input="$emit('input', $event.target.value)"
             ref="date" @focus="onFocusDate($event.target)" @blur="$emit('blur')">

      <select v-if="type==='select' && !withGroups" @input="$emit('input', $event.target.value)" v-bind:value="value">
        <option v-for="option in options" :key="option.value" v-bind:value="option.value">{{ option.label }}</option>
      </select>
      <select v-if="type==='select' && withGroups" @input="$emit('input', $event.target.value)" v-bind:value="value">
        <optgroup v-for="group in options" :label="group.label" :key="group.label">
          <option v-for="option in group.options" :key="option.value" v-bind:value="option.value">{{ option.label }}
          </option>
        </optgroup>
      </select>
      <slot></slot>
    </label>
    <label v-else class="flex-column jst-spc-bt alg-it-s w100">
      <span class="bolder">{{ label }}</span>
      <textarea v-if="type==='textarea'" @input="$emit('input', $event.target.value)" v-bind:value="value"
                :placeholder="placeholder"></textarea>
    </label>
  </div>
</template>

<script>
export default {
  name: "InputMobile",
  props: {
    value: [String, Number],
    label: String,
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    options: Array,
    withGroups: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    showColumn() {
      let types = ['textarea']
      return (types.includes(this.type));
    }
  },
  methods: {
    onFocusDate(target) {
      target.showPicker()
    }
  }
}
</script>

<style scoped lang="scss">
.inputMobile {
  background: white;
  width: 100%;
  min-height: 50px;
  padding: 4px 20px;
  box-sizing: border-box;
  height: 100%;
  input, textarea, select {
    font-size: 0.92em;
  }
  span {
    width: auto;
    padding-right: 12px;
    white-space: nowrap;
  }

  input {
    border: none;
    text-align: right;
    width: 50%;
    &:focus {
      box-shadow: none;
    }
  }

  textarea {
    width: 100%;
    border: none;
    min-height: 3em;
    resize: none;
    font-family: Poppins;
    margin: 4px 0;
    box-sizing: border-box;

  }

  select {
    border: none;
    appearance: none;
    text-align: right;
    -webkit-appearance: none;
    -moz-appearance: none;
    direction: rtl;
    overflow: hidden;
    color: $grayText;
    background: transparent;
  }
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
</style>
