<template>
  <div class="whiteBox">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "WhiteBox"
}
</script>

<style scoped lang="scss">
.whiteBox {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 0 10px -8px rgba(59, 130, 246, .5);
  background-color: white;
  padding: 16px 24px;
  box-sizing: border-box;
  &.tag {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;

    span {
      font-size: 0.9rem;
      font-weight: normal;
      margin-top: 4px;
    }
  }

  &.tag {
    &.green {
      color: $green;
      background: $lightGreen;
    }
    &.red {
      color: $red;
      background: $lightRed;
    }
    &.violet {
      color: $violet;
      background: $lightViolet;
    }
  }
}
</style>
