<template>
  <ModalMobile v-on="$listeners" :title="title" @close="cleanUp()">
    <div class="flex-row alg-it-c jst-spc-bt toggle group">
      <div class="pointer" :class="{ active: isIncome }" @click="setTypeToIncome">Einnahme</div>
      <div class="pointer" :class="{ active: !isIncome }" @click="setTypeToExpense">Ausgabe</div>
    </div>
    <div class="flex-row alg-it-c jst-c group sumInputWrapper">
      <input
        type="number"
        id="entrySumInput"
        placeholder="0.00"
        class="entrySumInput"
        step="0.01"
        :class="{ red: !isIncome, green: isIncome }"
        v-model="editEntry.sum"
        ref="sum" />
      <span>CHF</span>
    </div>
    <div class="group">
      <InputMobile label="Einnahmenummer" v-model="editEntry.number" type="number" />
      <InputMobile
        label="Aufwandskonto"
        type="select"
        :options="expenseTypes"
        v-model="editEntry.expenseType"
        v-if="!isIncome" />
      <InputMobile label="Datum" type="date" v-model="editEntry.date" @blur="getCount" />
    </div>
    <div class="group tags">
      <TagInputComponent :options="tagOptions" v-model="editEntry.selectedTags" />
    </div>
    <div class="group">
      <InputMobile :label="fileLabel" type="file">
        <input type="file" @change="onFileSelected" ref="fileInputMobile" />
      </InputMobile>
    </div>
    <div class="group">
      <InputMobile label="Beschreibung" type="textarea" placeholder="Memo hinzufügen" v-model="editEntry.text" />
    </div>

    <button class="primary" @click="saveEntry" :disabled="loading">
      <span v-if="!loading">Speichern und schliessen</span>
      <loading v-else class="small white" />
    </button>

    <button class="red" @click="deleteEntry" v-if="id != null">Buchung löschen</button>
    <button class="hidden" @click="$refs.sum.focus()" id="clickButton"></button>
  </ModalMobile>
</template>

<script>
import ModalMobile from '../../components/mobile/ModalMobile';
import InputMobile from '../../components/mobile/InputMobileComponent';
import axios from '../../axios';
import Loading from '../../components/LoadingSpinnerComponent';
import TagInputComponent from '../../components/TagInputComponent.vue';

export default {
  name: 'AddEntryModalMobile',
  components: { TagInputComponent, Loading, InputMobile, ModalMobile },
  props: {
    id: {
      type: [Number, String],
      required: false,
      default: null,
    },
    show: Boolean,
  },
  data() {
    return {
      title: this.id !== null ? 'Buchung bearbeiten' : 'Buchung hinzufügen',
      loading: false,
      ogEntry: null,
      editEntry: {
        type: 'expense',
        number: null,
        sum: null,
        date: new Date().toISOString().slice(0, 10),
        expenseType: null,
        text: '',
        categoryId: null,
        newCategory: null,
        customer: null,
        file: null,
        selectedTags: [],
        billable: false,
        project: null,
      },
      tagOptions: [],
      categories: [],
      expenseTypes: [],
    };
  },
  mounted() {
    this.getExpenseTypes();
    this.getTags();

    if (this.id !== null) {
      this.getPreselectedEntry();
    } else {
      this.getCount();
    }
  },
  computed: {
    isIncome() {
      return this.editEntry.type === 'income';
    },
    fileLabel() {
      if (this.editEntry.file !== null && Object.prototype.hasOwnProperty.call(this.editEntry.file, 'name')) {
        return 'Neuen Beleg hochladen';
      }
      return 'Beleg hochladen';
    },
  },
  methods: {
    getTags() {
      axios.get('tags').then(res => {
        this.tagOptions = res.data;
      });
    },
    setTypeToIncome() {
      this.editEntry.type = 'income';
      this.editEntry.expenseTypeId = null;
    },
    setTypeToExpense() {
      this.editEntry.type = 'expense';
      this.editEntry.expenseTypeId = 1;
    },
    getPreselectedEntry() {
      axios
        .get('/entry/' + this.id)
        .then(res => {
          this.ogEntry = res.data;
          this.editEntry.number = res.data.number;

          // Set values accordingly.
          this.editEntry.sum = res.data.sum;
          this.count = res.data.number;
          this.editEntry.type = res.data.type;
          this.editEntry.expenseType = res.data.expenseTypeId;
          this.editEntry.date = res.data.date;
          this.editEntry.text = res.data.entryText;
          this.editEntry.selectedTags = res.data.tags;

          // Check if entry has category and set according value.
          this.editEntry.categoryId = res.data.categoryId === null ? 'choose' : res.data.categoryId;

          // Check if entry has file and set the name of the file.
          if (res.data.fileName !== null) {
            this.editEntry.file = {};
            this.editEntry.file.name = res.data.fileName;
          }
        })
        .catch(() => {});
    },
    getYearFromDate(date) {
      return date.split('-')[0];
    },
    getCount(year) {
      this.loading = true;
      // Check if the number needs to be refreshed
      if (this.id) {
        let selectedYear = this.getYearFromDate(this.editEntry.date);
        let ogDate = this.getYearFromDate(this.ogEntry.date);

        // Check if year and type is like the original.
        if (ogDate === selectedYear && this.ogEntry.type === this.editEntry.type) {
          this.editEntry.number = this.ogEntry.number;
          this.loading = false;
          return;
        }
        year = selectedYear;
      }
      let params = year === false ? {} : { params: { year: year } };

      // Get the number.
      axios
        .get('/entry/count/' + this.editEntry.type, params)
        .then(res => {
          this.editEntry.number = res.data[0].count;
          this.loading = false;
        })
        .catch(() => {});
    },
    getExpenseTypes() {
      axios
        .get('expenseTypes')
        .then(res => {
          let expenseTypes = [];

          // Create array for InputMobile select.
          res.data.forEach(expenseType => {
            expenseTypes.push({ label: expenseType.name, value: expenseType.id });
          });

          this.expenseTypes = expenseTypes;
        })
        .catch(() => {});
    },
    saveEntry() {
      // Set loading to true to disable buttons.
      this.loading = true;

      // Creat formData object.
      const formData = new FormData();

      // Append basic data to form.
      formData.append('type', this.editEntry.type);
      formData.append('number', this.editEntry.number);
      formData.append('date', this.editEntry.date);
      formData.append('entryText', this.editEntry.text);
      formData.append('sum', this.editEntry.sum);

      let oldTagIds = [];
      let newTags = [];
      this.editEntry.selectedTags.forEach(tag => {
        if (tag.new) {
          newTags.push(tag);
        } else {
          oldTagIds.push(tag.id);
        }
      });

      if (newTags.length > 0) {
        formData.append('newTags', JSON.stringify(newTags));
      }
      formData.append('tagIds', JSON.stringify(oldTagIds));

      // Append number and entry type specific data.
      if (!this.isIncome) {
        formData.append('expenseTypeId', this.editEntry.expenseType);
      }

      // Handle files.
      if (!this.id) {
        // Creating new entry. Check whether a file has been uploaded or not and append to form.
        if (this.editEntry.file !== null) {
          formData.append('file', this.editEntry.file);
        }
      } else {
        // Updating existing entry.. Check if new file has been uploaded and append to file.
        if (this.editEntry.file !== null && this.editEntry.file !== undefined && 'size' in this.editEntry.file) {
          formData.append('file', this.editEntry.file);
        }
      }

      // Submit data.
      let url = !this.id ? '/entry' : '/entry/' + this.id;
      axios
        .post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(() => {
          this.cleanUp();
          if (!this.id) {
            this.$refs.sum.focus();
            this.loading = false;
          } else {
            this.$toast.success('Buchung wurde aktualisiert.');
            this.loading = false;
          }
          this.$emit('success');
          this.$emit('close');
        })
        .catch(e => {
          this.loading = false;
          alert(e.response.data.message);
          this.$toast.error(e.response.data.message);
        });
    },
    cleanUp() {
      this.getCount();
      this.editEntry.sum = null;
      this.editEntry.date = new Date().toISOString().slice(0, 10);
      this.editEntry.expenseType = 1;
      this.editEntry.text = '';
      this.editEntry.file = null;
      this.editEntry.selectedTags = [];
      this.$refs.fileInputMobile.value = '';
    },
    deleteEntry() {
      if (confirm('Willst du diese Buchung endgültig löschen? Dies kann nicht rückängig gemacht werden!')) {
        axios
          .delete('entry/' + this.id)
          .then(() => {
            this.$emit('success');
            this.$emit('close');
          })
          .catch(() => {});
      }
    },
    onFileSelected: function (event) {
      this.editEntry.file = event.target.files[0];
    },
  },
  watch: {
    isIncome: function () {
      this.$refs.sum.focus();
      this.getCount();
    },
    show: function (newVal) {
      let wrapper = document.getElementById('mobileWrapper');
      if (newVal === true) {
        wrapper.classList.add('nosroll');
        setTimeout(function () {
          document.getElementById('clickButton').click();
        }, 500);
      } else {
        wrapper.classList.remove('nosroll');
        document.activeElement.blur();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.modalMobile {
  position: fixed;
}

.toggle {
  background: $darkGray;
  border-radius: 4px;
  padding: 4px;
  margin: 0 20px;

  & > div {
    width: 100%;
    text-align: center;

    &.active {
      background: white;
      border-radius: 4px;
    }
  }
}

.sumInputWrapper {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .entrySumInput {
    background: transparent;
    text-align: right;
    border: none;
    font-size: 2rem;
    width: 60%;

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &:after {
      content: 'chf';
      color: $lightGrayText;
      opacity: 0.5;
    }

    &::placeholder {
      color: $lightGrayText;
      opacity: 0.5;
    }
  }

  span {
    width: 40%;
    margin-left: 12px;
    font-size: 1.2rem;
    color: $lightGrayText;
    opacity: 0.8;
  }
}

.group {
  margin-bottom: 18px;

  &.tags {
    padding: 12px 20px;
    background: white;
  }
}

button {
  &:last-of-type {
    margin-bottom: 24px;
  }
}
</style>
