export default class User {
  constructor(
    username,
    email,
    password,
    companyName = '',
    street = '',
    zip = '',
    city = '',
    rememberMe = true,
    token = 'false'
  ) {
    this.username = username;
    this.email = email;
    this.password = password;
    this.companyName = companyName;
    this.street = street;
    this.zip = zip;
    this.city = city;
    this.rememberMe = rememberMe;
    this.token = token;
  }
}
