<template>
  <transition name="fade">
    <div class="notice flex-row alg-it-c" :class="type">
      <font-awesome-icon v-if="type==='success'" icon="check-circle" />
      <font-awesome-icon v-if="type==='error'" icon="times-circle" />
      <font-awesome-icon v-if="type==='warning'" icon="exclamation-circle" />
      <span>{{ message }}</span>
    </div>
  </transition>
</template>

<script>
export default {
  name: "NoticeComponent",
  props: {
    message: String,
    type: String
  }
}
</script>

<style scoped lang="scss">
.notice {
  background: rgba(81, 142, 206, 0.40);
  border-radius: 5px;
  padding: 16px 24px;
  margin-bottom: 12px;
  color: $black;
  gap: 14px;
  font-size: 14px;
  z-index: 100000000;
  box-shadow: 0 0 10px -2px rgba(0,0,0,0.2);
  svg {
    font-size: 22px;
  }
  &.error {
    background: rgb(240,180, 184);
    svg {
      color: $red;
    }
  }
  &.warning {
    background: rgb(252, 239, 182);
    svg {
      color: $yellow;
    }
  }
  &.success {
    background: rgb(213, 227, 200);
    svg {
      color: $green;
    }
  }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
