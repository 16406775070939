<template>
  <span class="lds-dual-ring"></span>
</template>

<script>
export default {
  name: "LoadingSpinnerComponent"
}
</script>

<style scoped lang="scss">
.lds-dual-ring {
  display: inline;
}
.lds-dual-ring:after {
  content: " ";
  display: inline-flex;
  width: 16px;
  height: 16px;
  margin: 8px;
  border-radius: 50%;
  border: 1px solid #6556eb;
  border-color: #6556eb transparent #6556eb transparent;
  animation: lds-dual-ring 1.2s linear infinite;

  &.small {
   margin: 0;
  }
}
.lds-dual-ring.white:after {
  content: " ";
  display: inline-flex;
  width: 16px;
  height: 16px;
  margin: 8px;
  border-radius: 50%;
  border: 1px solid white;
  border-color: white transparent white transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
.lds-dual-ring.white.small:after {
  margin: 0;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
