<template>
  <section>
    <input type="text" class="input hideOnDesktop" v-model="search" placeholder="Suchen...">
    <div class="search-box hideOnTablet">
      <label class="pointer">
        <font-awesome-icon icon="search"/>

        <input type="text" class="input-search pointer" placeholder="Suchbegriff"
               ref="search" v-model="searchTerm" @keyup="search" @blur="doLog('search_exit', $route.path)">
      </label>
    </div>
  </section>
</template>

<script>
import {Escapable} from "../mixins/escapable";
import {log} from "../mixins/log";

export default {
  name: "SearchComponent",
  mixins: [Escapable, log],
  props: {
    searchData: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      showSearch: false,
      searchTerm: '',
      usable: JSON.parse(JSON.stringify(this.searchData))
    }
  },
  methods: {
    toggleSearch() {
      if (this.showSearch === false) {
        this.$refs.search.focus()
      } else {
        this.searchTerm = ''
        this.search()
      }
      this.showSearch = !this.showSearch
    },
    search() {
      let objs = this.usable;
      if (this.searchTerm !== '') {
        objs = objs.filter((obj) =>
            JSON.stringify(Object.values(obj)).toLowerCase().includes(this.searchTerm.toLowerCase())
        )
      }
      this.$emit('updateSearch', objs)
    },
    resetSearch(){
      this.searchTerm = ''
    },
    escapeHandler() {
      this.$refs.search.blur()
    },
    log(){
      this.$gtag.event('search_exit', {page_path: this.$route.path})
    }
  },
  watch: {
    searchData: {
      handler(val) {
        this.usable = JSON.parse(JSON.stringify(val))
        this.search()
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
// Search toggle
.search-box {
  width: fit-content;
  height: fit-content;
  position: relative;
}

svg {
  color: $black;
  font-size: 14px;
  transition: all 0.2s;
}

label{
  &:hover {
    svg {
      transform: rotate(23deg);
      color: $violet ;
    }
    .input-search {
      color: $violet;

      &::placeholder {
        color: $violet;
      }
    }
  }
}
.input-search {
  height: 30px;
  background: transparent;
  border: none;
  border-radius: 0;
  padding: 0 0 0 8px;
  transition: all 0.15s;
  border-bottom: 2px solid transparent;
  min-width: 0 !important;
  width: 90px;

  &:focus {
    outline: none;
    box-shadow: none;
    width: 200px;
  }

  &::placeholder {
    color: $black;
    font-size: 14px;
  }
}

</style>
