<template>
  <div id="app">
    <div v-if="isMobile" id="mobileWrapper">
      <HeaderTitleMobile :title="mobileHeaderTitle" :noMargin="true" />
      <router-view name="mobile" class="mobileView" />
      <NavigationMobile v-if="loggedIn" />
    </div>
    <div v-else class="flex-row">
      <div class="nav">
        <Navigation v-if="loggedIn" />
      </div>
      <div class="contentSide w100">
        <div class="topContent">
          <Bars />
        </div>
        <div class="bottomContent">
          <!--          <Paywall v-if="loggedIn && subscriptionEnded"/>-->
          <section id="content">
            <div class="notices">
              <Notice
                v-for="(notice, index) in $store.state.notices.notices"
                :message="notice.notice"
                :key="index"
                :type="notice.type" />
            </div>
            <PaywallView v-if="subscriptionEnded" />
            <router-view name="desktop" v-else />
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from './axios';
import Notice from './components/NoticeComponent';
import Navigation from './modules/NavigationModule';
import Bars from './modules/BarsModule';
import NavigationMobile from './modules/mobile/NavigationMobile';
import HeaderTitleMobile from './components/mobile/HeaderTitleMobileComponent';
import PaywallView from './views/auth/PaywallView.vue';

export default {
  components: { PaywallView, HeaderTitleMobile, NavigationMobile, Bars, Navigation, Notice },
  data() {
    return {
      isTrial: false,
      emailVerified: true,
      stripeLoading: false,
      showNav: true,
      currentRoute: this.$route.path,
      mobileHeaderTitle: 'MILKEE',
    };
  },
  computed: {
    loggedIn: function () {
      return this.$store.getters['auth/loggedIn'];
    },
    subscriptionEnded: function () {
      if (!this.loggedIn) {
        return false;
      }
      let user = this.$store.getters['auth/user'].user;
      let today = new Date();
      let until = new Date(user.subscriptionUntil);
      return today > until;
    },
    isMobile: function () {
      return window.innerWidth <= 700;
    },
  },
  created() {
    window.$crisp.push(['safe', true]);
    if (this.$workbox) {
      this.$workbox.addEventListener('waiting', () => {
        this.refreshPage();
      });
    }
    if (this.$store.state.auth.user) {
      axios
        .get('/auth/me')
        .then(res => {
          this.$store.dispatch('auth/updateUser', res.data);
        })
        .catch(res => {
          if (res.response.status === 401) {
            this.$store.dispatch('auth/logout');
            this.$router.push('/login');
          }
        });
    }
    this.getMobileHeaderTitle();
  },
  methods: {
    getMobileHeaderTitle() {
      let path = this.$route.path;
      if (path === '/') {
        this.mobileHeaderTitle = 'MILKEE';
      } else if (path === '/buchungen') {
        this.mobileHeaderTitle = 'Deine Buchungen (' + new Date().toISOString().split('-')[0] + ')';
      } else if (path === '/annual') {
        this.mobileHeaderTitle = 'Jahresabschlüsse';
      } else if (path === '/profile') {
        this.mobileHeaderTitle = 'Einstellungen';
      }
    },
    async refreshPage() {
      await this.$workbox.messageSW({ type: 'SKIP_WAITING' });
    },
  },
  watch: {
    $route() {
      this.getMobileHeaderTitle();
    },
  },
};
</script>
<style lang="scss">
@import '@/assets/scss/_base.scss';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

html {
  overflow-y: auto;
}

html,
body {
  margin: 0;
  padding: 0;
  background-color: rgb(244, 245, 247);
}

#app {
  font-family: Poppins, sans-serif;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: rgb(244, 245, 247);

  .contentSide {
    margin-bottom: 24px;

    .topContent {
      position: sticky;
      top: 0;
      z-index: 99;
      margin-left: 305px;
    }
  }
}

.nav {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
}

.bottomContent {
  margin-left: 305px;
}

#content {
  height: 100%;
  max-width: 1280px;
  width: 100%;
  padding-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
  box-sizing: border-box;
}

.tag {
  padding: 3px 7px;
  border-radius: 5px;
  font-size: 0.8rem;
  font-weight: 500;

  &.green {
    background: rgba(102, 179, 23, 0.18);
    color: #609e31;
  }

  &.yellow {
    background-color: $backgroundYellow;
    color: $yellow;
  }

  &.red {
    background-color: rgba(235, 49, 72, 0.18);
    color: #eb3148;
  }

  &.gray {
    background: #e8e8e8;
    color: $black;
  }
}

.redText {
  color: #eb3148;
}

.greyText {
  color: rgba(107, 114, 128, 1);
}

.link,
a {
  color: #6556eb;
  text-decoration: none;
  cursor: pointer;
  background: linear-gradient(currentColor, currentColor) left bottom / 0 0.1em no-repeat;

  &:hover {
    background-size: 100% 0.1em;
    transition: 0.1s background-size;
  }
}
.topNav {
  width: 100%;
  height: 60px;
  background: white;
  border-bottom: 1px solid #e8e8e8;
}
.centerText {
  text-align: center;
}

#mobileWrapper {
  padding-bottom: 80px;

  .mobileView {
    padding-top: 50px;
  }

  &.noscroll {
    overflow: hidden;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

@media screen and (max-width: 1625px) {
  #app {
    .contentSide {
      .topContent {
        margin-left: 80px;
      }
    }
  }
}

@media screen and (min-width: 1025px) {
  .hideOnDesktop {
    display: none !important;
  }

  .notices {
    position: fixed;
    right: 24px;
    max-width: 300px;
    z-index: 1000;
  }
}

@media screen and (max-width: 1625px) {
  .bottomContent {
    margin-left: 80px;
  }
}

@media screen and (max-width: 1024px) {
  .hideOnTablet {
    display: none !important;
  }
}

@media screen and (max-width: 700px) {
  html {
    height: 100%;
    scroll-behavior: smooth;
  }

  body.disable-scroll {
    position: fixed;
    height: 100%;
    overflow: hidden;
  }

  #app {
    background: #fafafc;

    &.modalActive {
      background: black;
    }
  }
  .hideOnMobile {
    display: none !important;
  }
  .crisp-client {
    display: none;
  }
}
</style>
