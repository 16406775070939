import DashboardView from './DashboardView';

const routes = [
  {
    path: '/',
    component: DashboardView,
    components: {
      desktop: DashboardView,
      mobile: DashboardView,
    },
    meta: {
      title: 'Dashboard',
    },
  },
];
export default routes;
