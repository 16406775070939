<template>
  <div class="barsWrapper">
    <div v-if="loggedIn && trial && verified" class="trialBar bar">
      <span v-if="trialEnd > 0">🕒 Deine Testversion läuft in <b>{{ trialEnd }} Tagen</b> ab. </span>
      <span v-else>🕒 Deine Testversion <b>ist abgelaufen</b>. </span>
      <router-link to="/profile/subscription" class="link">Jetzt Abo auswählen!</router-link>
    </div>
<!--    <div v-if="loggedIn && !setupFinished && verified" class="emailBar bar">
      ⚠️ Dein Account ist noch nicht fertig eingerichtet.
      <router-link class="link" to="/profile">Jetzt erledigen</router-link>
    </div>-->
    <div v-if="loggedIn && !verified" class="emailBar bar" ref="emailBar">
      ⚠️ Deine E-Mail wurde noch nicht verifiziert. Überprüfe deinen Spam-Ordner.
      <span class="link" @click="resendEmail">Erneut senden</span>
    </div>
  </div>
</template>

<script>
import axios from '../axios';

export default {
  name: 'BarsModule',
  data() {
    return {
      stripeLoading: false,
    };
  },
  computed: {
    loggedIn: function () {
      return this.$store.getters['auth/loggedIn'];
    },
    verified: function () {
      return this.$store.getters['auth/user'].user.email_verified_at !== null;
    },
    setupFinished: function () {
      let user = this.$store.getters['auth/user'].user;
      if (user.name === '' || user.companyName === '' || user.street === '' || user.city === '' || user.zip === '') {
        return false;
      }
      return true;
    },
    trialEnd: function () {
      let user = this.$store.getters['auth/user'].user
      let date1 = new Date()
      let date2 = new Date(user.subscriptionUntil)
      let diff = date2.getTime() - date1.getTime()
      return Math.ceil(diff / (1000 * 3600 * 24))
    },
    trial: function () {
      return this.$store.getters['auth/user'].user.current_company.plan.slug === 'free';
    },
  },
  methods: {
    getStripeUrl() {
      this.stripeLoading = true;
      let apiPath = 'billingInit';
      axios
        .get(apiPath)
        .then(res => {
          let form = document.createElement('form');
          form.method = 'get';
          form.action = res.data.url;
          document.body.appendChild(form);
          form.submit();
          this.stripeLoading = false;
        })
        .catch(() => {
          this.stripeLoading = false;
        });
    },
    resendEmail() {
      axios
        .get('auth/resendVerificationEmail')
        .then(() => {
          this.$refs.emailBar.innerText = 'E-Mail wude erneut gesendet. Überprüfe deinen Spam-Ordner.';
        })
        .catch(() => {
          this.$refs.emailBar.innerText = 'Da ging was schief! Kontaktiere den Support!.';
        });
    },
  },
};
</script>

<style scoped lang="scss">
.barsWrapper {
  position: relative;
}
.bar {
  width: 100%;
  box-sizing: border-box;
  background-color: #6556eb;
  color: white;
  text-align: center;
  padding: 8px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1000;
  .link {
    background-size: 100% 0.1em;
    color: white;
  }
}

.emailBar {
  background-color: #6b7280;
}
</style>
