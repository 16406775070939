<template>
  <div class="flex-column w100 gap40">
    <white-box>
      <h2 v-if="!expired">Mein Abonnement</h2>
      <section class="flex-row jst-spc-bt usage" v-if="!expired">
        <section>
          <b>Status:</b>
          <span class="tag yellow" v-if="trial">kostenloses Testabo</span>
          <span class="tag green" v-else-if="active">aktiv</span>
          <p v-if="userPlan">
            <b>Abonnement: </b> {{ userPlan.name }}
            <br />
            <span v-if="subscriptionEnd & !cancelled && !trial">
              Dein Abonnement wird in <b>{{ subscriptionEnd }} Tagen</b> automatisch erneuert.
            </span>
            <span v-if="trial">Dein Testabo läuft in <b>{{ subscriptionEnd }} Tagen</b> aus.</span>
            <span v-if="cancelled">Dein Abonnement wird am {{ user.cancel_at }} storniert.<br /></span>
            <span v-if="!subscriptionEnd && subscriptionSlug === 'free'">
              Wechsle jetzt auf ein bezahltes Abo, um MILKEE ohne Einschränkungen zu nutzen.
            </span>
          </p>
        </section>
        <section>
          <b>Datennutzung:</b>
          <span
            class="tag"
            :class="{ green: usagePercentage < 75, yellow: usagePercentage >= 75, red: usagePercentage > 95 }"
            >{{ storageUsage }} / {{ storageMax }}</span
          >
          <p v-if="usagePercentage < 75">Du hast noch genügend Speicherplatz zur Verfügung.</p>
          <p v-if="usagePercentage >= 75">
            Dein Speicherplatz wird knapp. Wechsle auf ein Abonnement mit mehr Speicherplatz oder kontaktiere den
            Support.
          </p>
        </section>
      </section>
      <h2 v-if="trial && !expired">Wähle dein Abonnement!</h2>
      <h2 v-if="expired">Dein Abonnement ist abgelaufen.</h2>
      <p v-if="trial && expired">Wähle einen beliebigen Tarif um weiterhin auf deine Daten zuzugreifen.</p>
    </white-box>
    <white-box v-if="subscriptionSlug !== 'free'">
      <section class="flex-column subscriptionSettings">
        <h3>Abonnement verwalten</h3>
        <p class="smallText">
          In der Aboverwaltung kannst du dein Abonnement kündigen, dein Abo wechseln und deine Rechnungen einsehen.
        </p>
        <button class="small smallText primary" @click="getStripeUrlForActive">Abonnement verwalten</button>
      </section>
    </white-box>
    <white-box v-if="subscriptionSlug === 'free'">
      <h2>Jetzt auf ein bezahltes Abo wechseln</h2>
      <p v-if="expired">
        Dein Testabonnement ist abgelaufen.<br />
        Wenn du MILKEE weiterhin benutzen willst, kannst du hier auf ein bezahltes Abonnement wechseln.<br /><br />
      </p>
      <p v-else>Wechsle jetzt auf ein bezahltes Abonnement um MILKEE vollumfänglich nutzen zu können.</p>
      <br />
      <section class="flex-row jst-c yearlyToggleSwitch">
        <button @click="interval = 'monthly'" :class="{ active: interval === 'monthly' }">Monatlich</button>
        <button @click="interval = 'yearly'" :class="{ active: interval === 'yearly' }">Jährlich</button>
      </section>
      <section class="flex-row jst-fs pricing">
        <section class="flex-column jst-fs popularLight">
          <section class="title">
            <h3>Accounting</h3>
          </section>
          <section class="details flex-column jst-spc-bt h100">
            <section class="flex-column">
              <section class="flex-row jst-spc-bt feature">
                <span>Speicherplatz</span>
                <span><b>2GB</b></span>
              </section>
              <section class="flex-row jst-spc-bt feature">
                <span>Unlimitierte Buchungen</span>
                <span class="icon"><font-awesome-icon icon="check" /></span>
              </section>
              <section class="flex-row jst-spc-bt feature">
                <span>Auswertung</span>
                <span class="icon"><font-awesome-icon icon="check" /></span>
              </section>
              <section class="flex-row jst-spc-bt feature">
                <span>Belegverwaltung</span>
                <span class="icon"><font-awesome-icon icon="check" /></span>
              </section>
              <section class="flex-row jst-spc-bt feature">
                <span>Jahresabschluss</span>
                <span class="icon"><font-awesome-icon icon="check" /></span>
              </section>
              <section class="flex-row jst-spc-bt feature">
                <span>Importfunktion</span>
                <span class="icon"><font-awesome-icon icon="check" /></span>
              </section>
              <section class="flex-row jst-spc-bt feature">
                <span>Wiederkehrende Buchungen</span>
                <span class="icon"><font-awesome-icon icon="check" /></span>
              </section>
            </section>
            <section class="price" v-if="interval === 'monthly'">
              <span class="monthly">14.90 CHF / Monat</span><br />
              <span class="yearly">monatliche Abrechnung</span>
            </section>
            <section class="price" v-else>
              <span class="monthly">12.40 CHF / Monat</span><br />
              <span class="yearly"><b>149.-</b> CHF im <b>Jahr</b></span
              ><br />
            </section>
            <button class="primary" @click="initiateCheckout(13)" v-if="interval === 'yearly'">
              Abonnement auswählen
            </button>
            <button class="primary" @click="initiateCheckout(14)" v-else>Abonnement auswählen</button>
          </section>
        </section>
        <section class="flex-column popular">
          <section class="title">
            <h3>Business</h3>
          </section>
          <section class="details flex-column jst-spc-bt">
            <section class="flex-column">
              <section class="flex-row jst-spc-bt feature">
                <span>Speicherplatz</span>
                <span><b>2GB</b></span>
              </section>
              <section class="flex-row jst-spc-bt feature">
                <span>Alles aus Accounting</span>
                <span class="icon"><font-awesome-icon icon="check" /></span>
              </section>
              <section class="flex-row jst-spc-bt feature">
                <span class="featureSpan"><font-awesome-icon icon="infinity" /> Kund:innen</span>
                <span class="icon"><font-awesome-icon icon="check" /></span>
              </section>
              <section class="flex-row jst-spc-bt feature">
                <span class="featureSpan"><font-awesome-icon icon="infinity" /> Projekte</span>
                <span class="icon"><font-awesome-icon icon="check" /></span>
              </section>
              <section class="flex-row jst-spc-bt feature">
                <span class="featureSpan"><font-awesome-icon icon="infinity" /> Rechnungen</span>
                <span class="icon"><font-awesome-icon icon="check" /></span>
              </section>
              <section class="flex-row jst-spc-bt feature">
                <span class="featureSpan"><font-awesome-icon icon="infinity" /> Produkte</span>
                <span class="icon"><font-awesome-icon icon="check" /></span>
              </section>
              <section class="flex-row jst-spc-bt feature">
                <span class="featureSpan"><font-awesome-icon icon="stopwatch" /> Zeiterfassung</span>
                <span class="icon"><font-awesome-icon icon="check" /></span>
              </section>
            </section>
            <section class="price" v-if="interval === 'monthly'">
              <span class="monthly">29.90 CHF / Monat</span><br />
              <span class="yearly">monatliche Abrechnung</span>
            </section>
            <section class="price" v-else>
              <span class="monthly">24.90 CHF / Monat</span><br />
              <span class="yearly"><b>299.-</b> CHF im <b>Jahr</b></span>
            </section>
            <button class="primary" @click="initiateCheckout(10)" v-if="interval === 'yearly'">
              Abonnement auswählen
            </button>
            <button class="primary" @click="initiateCheckout(11)" v-else>Abonnement auswählen</button>
          </section>
        </section>
      </section>
      <p>
        <b>Bezahlung per Rechnung</b><br />
        Jahresabos kannst du auch per Rechnung bezahlen. <br />
        Schreibe und hierzu eine E-Mail an <a href="mailto:nicolas@milkee.ch">nicolas@milkee.ch</a>.
      </p>
      <p>
        <b>Fragen?</b><br />
        Melde dich bei uns! Am besten per E-Mail oder per Live-Chat.
      </p>
    </white-box>
  </div>
</template>

<script>
import WhiteBox from '../components/WhiteBox';
import axios from '../axios';

export default {
  name: 'SubscriptionModule',
  components: { WhiteBox },
  props: {
    expired: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      storageMax: '0MB',
      storageUsage: '0MB',
      usagePercentage: 0,
      userPlan: null,
      interval: 'yearly',
    };
  },
  created() {
    this.getStorageUsage();
    this.getUserPlan();
  },
  computed: {
    trial: function () {
      return this.$store.getters['auth/user'].user.plan.slug === 'free';
    },
    active: function () {
      return this.$store.getters['auth/user'].user.plan.slug !== 'free';
    },
    user() {
      return this.$store.getters['auth/user'].user;
    },
    cancelled() {
      return this.user.cancel_at != null;
    },
    subscriptionSlug() {
      return this.$store.getters['auth/user'].user.plan.slug;
    },
    showBusinessFunctions() {
      return this.$store.getters['auth/showBusinessFunctions'];
    },
    subscriptionEnd: function () {
      let user = this.$store.getters['auth/user'].user;
      if (user.subscriptionUntil == null) {
        return false;
      }
      let date1 = new Date();
      let date2 = new Date(user.subscriptionUntil);
      let diff = date2.getTime() - date1.getTime();
      return Math.ceil(diff / (1000 * 3600 * 24));
    },
  },
  methods: {
    getStripeUrlForActive() {
      axios
        .get('billingPortal')
        .then(res => {
          let form = document.createElement('form');
          form.method = 'get';
          form.action = res.data.url;
          document.body.appendChild(form);
          form.submit();
        })
        .catch(() => {});
    },
    initiateCheckout(planId) {
      axios
        .get('billingInit/' + planId)
        .then(res => {
          let form = document.createElement('form');
          form.method = 'get';
          form.action = res.data.url;
          document.body.appendChild(form);
          form.submit();
        })
        .catch(() => {});
    },
    getStorageUsage() {
      axios
        .get('auth/storageUsage')
        .then(res => {
          this.storageMax = res.data.planMax + 'MB';
          this.storageUsage = res.data.usageReadable;
          this.usagePercentage = res.data.percentage;
        })
        .catch(() => {});
    },
    getUserPlan() {
      axios
        .get('auth/plan')
        .then(res => {
          this.userPlan = res.data;
        })
        .catch(() => {});
    },
    toggleBusinessFunctions(status) {
      axios
        .post('auth/userSettings', { key: 'showBusinessFunctions', value: status })
        .then(() => {
          axios.post('auth/me').then(res => {
            this.$store.dispatch('auth/updateUser', res.data);
            this.$router.go(0);
          });
        })
        .catch(e => {
          this.$toast.error(e.response.data.message);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.usage {
  gap: 50px;
  margin-bottom: 24px;

  section {
    background-color: $gray;
    width: 100%;
    border-radius: 5px;
    border: 1px solid $darkGray;
    padding: 12px;
  }

  .tag {
    font-weight: bold;
    font-size: 0.9rem;
    margin-left: 8px;
  }
}

h2 {
  margin-bottom: 16px;
}

h3 {
  margin-bottom: 12px;
}

.pricing {
  gap: 24px;
  max-width: 800px;
  margin: 24px auto;

  > section {
    width: 100%;
    background: $gray;
    border: 1px solid $darkGray;
    border-radius: 5px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

    .title {
      background-color: white;
      width: 100%;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      padding: 12px 20px;
      box-sizing: border-box;
      border-bottom: 1px solid $darkGray;
    }

    .details {
      padding: 20px;

      .price {
        font-family: Poppins;
        padding: 20px;
        text-align: center;

        .monthly {
          font-size: 1.3rem;
          font-weight: 500;
        }

        .yearly {
          font-size: 0.9rem;

          b {
            font-weight: 600;
          }
        }
      }

      button {
        margin-top: 12px;
        width: 100%;
        font-weight: 500;
      }
    }
  }

  .feature {
    padding-bottom: 6px;
    padding-top: 6px;

    .featureSpan {
      svg {
        font-size: 0.75rem;
        width: 12px;
        margin-right: 4px;
        color: $black;
      }
    }

    .icon {
      color: $violet;
    }

    &:last-of-type {
      padding-bottom: 12px;
    }
  }
}

h3 {
  margin-bottom: 4px;
}

button {
  font-size: 1rem;
}

.subscriptionSettings {
  button {
    width: 250px;
  }

  .smallText {
    margin-top: 8px;
  }
}

.popular {
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.2) !important;
  margin-top: -5px;
  border: none !important;
}

.popular .title {
  background: #6556eb !important;
  position: relative;
  overflow: hidden;
  color: white !important;

  > h3 {
    color: white;
  }
}

.popularLight {
  .title {
    background-color: $lightViolet !important;

    > h3 {
      color: $violet !important;
    }
  }
}

.popular .ribbon {
  position: absolute;
  top: 25px;
  right: -25px;
  background-color: white;
  transform: rotate(45deg);
  width: 150px;
  text-align: center;
  color: #6556eb;
}

.popular a.button {
  background-color: #6556eb;
  color: white;
}

.popular a.button:hover {
  background-color: #4d36e3;
}

.yearlyToggleSwitch {
  width: 100%;
  margin: 0 auto 18px;

  button {
    background: #e8e8e8;
    border: none;
    font-size: 0.8rem;
    padding: 8px 16px;
    transition: all 0.2s;
    font-weight: bolder;

    &:first-of-type {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-of-type {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:hover {
      background-color: $lightViolet;
      color: $violet;
    }

    &:focus {
      box-shadow: none;
    }

    &.active {
      background: $violet;
      color: white;
    }
  }
}
</style>
