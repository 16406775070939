<template>
  <div class="goBackComponent pointer" @click="$router.go(-1)">
    <font-awesome-icon icon="chevron-left"/>
    {{ title }}
  </div>
</template>

<script>
export default {
  name: "GoBackComponent",
  props: {
    title: {
      type: String,
      default: 'Zurück zur Übersicht',
      required: false
    }
  }
}
</script>

<style scoped lang="scss">
.goBackComponent {
  width: fit-content;
  color: $labelGray;
  margin-bottom: 24px;
  font-size: 0.9rem;
  transition: all 0.1s;

  svg {
    margin-right: 12px;
  }

  &:hover {
    color: $black;
  }
}

</style>
