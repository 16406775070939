import { render, staticRenderFns } from "./ProfileView.vue?vue&type=template&id=63114ef4&scoped=true&"
import script from "./ProfileView.vue?vue&type=script&lang=js&"
export * from "./ProfileView.vue?vue&type=script&lang=js&"
import style0 from "./ProfileView.vue?vue&type=style&index=0&id=63114ef4&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63114ef4",
  null
  
)

export default component.exports