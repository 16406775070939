<template>
  <div>
    <div class="headline flex-row jst-spc-bt alg-it-c">
      <h1>Benachrichtigungen</h1>
      <section class="flex-row gap8">
        <button class="primary bold" @click="$refs.modal.show()">+ Nachricht erstellen</button>
      </section>
    </div>
    <TableModule
        :table-data="{ head: head, body: body }"
        :tr-clickable="true"
        primary-sort-default="name"
        secondary-sort-default="name"
        @trClicked="trClicked"/>
    <NotificationModalModule ref="modal"/>
  </div>
</template>

<script>

import NotificationModalModule from "@/modules/NotificationModalModule.vue";
import TableModule from "@/modules/TableModule.vue";
import axios from "@/axios";

export default {
  name: "AdminNotificationsView",
  components: {TableModule, NotificationModalModule},
  data() {
    return {
      notifications: [],
      head: [
        {value: 'title', label: 'Titel', sortable: true, style: {width: '80%'}},
        {value: 'status', label: 'Status', sortable: true, style: {width: '80%'}},
      ],
      body: [
        {
          name: {value: 'Test', sortable: 'Test'},
          id: {value: 'aktiv', sortable: 'aktiv', classes: ['hidden']},
          status: {value: 'versendet', sortable: 'versendet'},
        },
      ],
    }
  },
  created() {
    this.getNotifications('active');
  },
  methods: {
    trClicked(row) {
      this.$router.push('/notifications/' + row.id.value);
    },
    getNotifications(filter) {
      axios.get('admin/notifications', {params: {status: filter}}).then(res => {
        let notifications = res.data;
        let body = [];
        notifications.forEach(notification => {
          body.push({
            name: {value: notification.title, sortable: notification.title},
            id: {value: notification.id, sortable: notification.id, classes: ['hidden']},
            status: {value: notification.status, sortable: notification.status},
          });
        });
        this.body = body;
      });
    }
  },
}
</script>

<style scoped>

</style>