<template>
  <section class="flex-row alg-it-c decentButtonDropDownComponent">
    <decent-button-component :text="text" :color="color" icon-after="chevron-down" @click="clicked"/>
    <section class="click" v-if="showPopUp">
      <span v-for="(option, index) in options" @click="$emit(option.callback); showPopUp = false" :key="index" :class="option.classes">
        <font-awesome-icon v-if="Object.prototype.hasOwnProperty.call(option, 'icon')" :icon="option.icon"/>
        {{option.title}}
      </span>
    </section>
    <div class="backdrop" @click="showPopUp = false" v-if="showPopUp"></div>
  </section>
</template>

<script>
import DecentButtonComponent from "./DecentButtonComponent";

export default {
  name: "DecentButtonDropDownComponent",
  components: {DecentButtonComponent},
  props: {
    text: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'default'
    },
    options: {
      type: Array,
      default() {return []}
    },
  },
  data() {
    return {
      showPopUp: false,
    }
  },
  methods: {
    clicked() {
      this.showPopUp = true
    }
  }
}
</script>

<style scoped lang="scss">
.decentButtonDropDownComponent {
  position: relative;
}
svg {
  font-size: 0.9rem;
  color: $grayText;
}
.click {
  display: flex;
  flex-direction: column;
  gap: 2px;
  position: absolute;
  right: -12px;
  top: calc(100% + 4px);
  width: fit-content;
  min-width: 100%;
  background: white;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 8px 8px;
  box-sizing: border-box;
  z-index: 1000;

  &:after {
    content: "";
    position: absolute;
    top: -8px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
    border-radius: 2px;
    right: 12px;
  }
  span {
    padding: 4px 12px;
    transition: all 0.2s;
    border-radius: 4px;
    white-space: nowrap;
    font-weight: 400;
    font-size: 0.80rem;
    color: $black !important;

    svg {
      padding-right: 2px;
      font-size: 0.8rem;
      width: 12px;
      margin-right: 4px;
      color: $black;
      transition: all 0.2s;
    }

    &:hover {
      background: $lightViolet;
      cursor: pointer;
    }
    &.red {
      &:hover {
        color: $red !important;
        background: $lightRed;
        svg {
          color: $red;
        }
      }
    }
  }
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.0);
}
</style>
