<template>
  <div>
    <span class="hideOnTablet smallText pointer" @click="doDownload">
      <font-awesome-icon icon="download"/>
      Daten herunterladen
    </span>
  </div>
</template>

<script>

export default {
  name: "CsvExport",
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  methods: {
    doDownload() {
      const csv = []
      let ids = []
      csv.push(this.data.head)
      this.data.body.forEach(line => {
        let row = [];
        line.forEach(td => {
          // Remove the ID from the data entry from the actual downloadable table.
          if (typeof td === 'object' && !Array.isArray(td) && td !== null) {
            if (Object.prototype.hasOwnProperty.call(td, 'id')) {
              ids.push(td.id)
            }
          } else {
            row.push(td);
          }
        })

        csv.push(row)
      })
      let blob = new Blob([this.arrayToCsv(csv)], {type: 'data:text/csv;charset=utf-8;'});
      let url = URL.createObjectURL(blob);

      // Create a link to download it
      let pom = document.createElement('a');
      pom.href = url;
      pom.setAttribute('download', this.data.filename);
      pom.click();
      this.$emit('csvDownloaded', ids)
    },
    arrayToCsv(data) {
      return data.map(row =>
          row
              .map(String)  // every value to String
              .map(v => v.replaceAll('"', '""'))  // escape double colons
              .map(v => `"${v}"`)  // quote it
              .join(';')  // comma-separated
      ).join('\r\n');  // rows starting on new lines
    }
  }
}
</script>

<style scoped lang="scss">
.smallText {
  font-size: 14px;
  color: $black;

  &:hover {
    color: $violet;

    svg {
      transform: rotate(23deg);
    }
  }

  svg {
    transition: all 0.2s;
  }
}
</style>
