<template>
  <white-box class="expenseTypeSettingsModule flex-column">
    <div class="heading flex-row  jst-spc-bt alg-it-c">
      <h2>Aufwandskonten verwalten</h2>
      <a href="https://support.milkee.ch/de/article/aufwandskonten-erklart-1fck40k/" class="smallText" target="_blank">
        <font-awesome-icon icon="question-circle"/>
        Hilfe zu Aufwandskonten</a>
    </div>
    <p>Hier kannst du auswählen, welche Aufwandskonten du verwenden möchtest und welche nicht. Du kannst sie auch sortieren. </p>
    <div class="expenseTypes flex-column">
      <div class="header flex-row jst-spc-bt">
        <div class="flex-row name">
          <span class="position"></span>
          <span>Name</span>
        </div>
        <div>
          <span>Anzeigen</span>
        </div>
      </div>
      <draggable v-model="expenseTypes" ghost-class="ghost">
        <transition-group>
          <div class="flex-row jst-spc-bt expenseTypeItem" v-for="expenseType in expenseTypes" :key="expenseType.id">
            <div class="name flex-row">
              <span class="position"> <font-awesome-icon icon="grip-vertical"/></span>
              <span>{{ expenseType.name }}</span>
            </div>
            <div>
              <checkbox-component>
                <label>
                  <span v-if="expenseType.isActive">Wird angezeigt</span>
                  <span v-else>Wird <b>nicht</b> angezeigt</span>
                  <input type="checkbox" v-model="expenseType.isActive" class="small">
                </label>
              </checkbox-component>
            </div>
          </div>
        </transition-group>
      </draggable>
    </div>
    <section class="flex-row jst-spc-bt submit">
      <span></span>
      <button class="primary" @click="updateExpenseTypesSettings">Speichern</button>
    </section>
  </white-box>
</template>

<script>
import WhiteBox from "../components/WhiteBox";
import CheckboxComponent from "../components/CheckboxComponent";
import axios from "../axios";
import draggable from 'vuedraggable'

export default {
  name: "ExpenseTypeSettingsModule",
  components: {CheckboxComponent, WhiteBox, draggable},
  data() {
    return {
      expenseTypes: null,
    }
  },
  created() {
    this.getExpenseTypes();
  },
  methods: {
    getExpenseTypes() {
      axios.get('expenseTypes/all').then(res => {
        this.expenseTypes = res.data;
      })
    },
    updateExpenseTypesSettings() {
      let disabled = []
      let order = {}
      this.expenseTypes.forEach((expenseType, key) => {
        order[expenseType.id] = key;
        if (!expenseType.isActive) {
          disabled.push(expenseType.id)
        }
      })
      // First set the order, then set the new visibility.
      axios.post('auth/userSettings', {key: 'expenseTypesOrder', value: order}).then(() => {
        axios.post('auth/userSettings', {key: 'disabledExpenseTypes', value: disabled}).then(() => {
          this.getExpenseTypes()
          this.$toast.success('Aufwandskonten wurden aktualisiert.')
        }).catch(() => {
          this.$toast.error('Aufwandskonten konnten nicht aktualisiert werden.')
        })
      }).catch(() => {
        this.$toast.error('Reihenfolge konnte nicht aktualisiert werden.')
      })
    }
  }
}
</script>

<style scoped lang="scss">
.heading {
  svg {
    margin-right: 4px;
  }
}

.header {
  padding: 8px 4px;
  border-bottom: 1px solid $tableGray;

  span {
    color: $lightGrayText;
  }
}

span {
  color: $grayText;
  font-size: 14px;
}

.position {
  width: 40px;

  svg {
    color: $labelGray;
    cursor: move;
  }
}

.name {
  text-align: left;
}

.expenseTypeItem {
  padding: 8px 4px;
  margin: 2px 0;
  border-radius: 4px;

  &:hover {
    background-color: $darkGray;
  }

  input {
    &.small {
      margin-left: 8px;
      margin-right: 0;
    }
  }

  label {
    cursor: pointer;
  }
}

.submit {
  margin-top: 24px;
}
.ghost {
  opacity: 0.5;
  background: $violet;
}
</style>
