import AuthService from '../services/auth.service';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { status: { loggedIn: true }, user } : { status: { loggedIn: false }, user: null };

export const auth = {
  namespaced: true,
  state: initialState,
  getters: {
    loggedIn: state => state.status.loggedIn,
    user: state => state.user,
    userCanImport(state) {
      if (!Object.prototype.hasOwnProperty.call(state, 'user')) {
        return false;
      }
      let user = state.user.user;
      return [0, 3, 4, 7, 8].includes(user.planId);
    },
    userCanRecurringPayments() {
      return true;
    },
    userCanInvoices(state) {
      if (!state.status.loggedIn) {
        return false;
      }
      return false;
      // let user = state.user.user;
      // return [0,3,4,7,8].includes(user.planId)
    },
    userCanDeprecations(state) {
      if (!state.status.loggedIn) {
        return false;
      }
      return false;
      // let user = state.user.user;
      // return [0,4,8].includes(user.planId)
    },
    userCanMWST(state) {
      if (!state.status.loggedIn) {
        return false;
      }
      return false;
      // let user = state.user.user;
      // return [0,4,8].includes(user.planId)
    },
    showBusinessFunctions(state) {
      let user = state.user.user;
      if (user.plan.slug === 'business' || user.plan.slug === 'free') {
        return true;
      }
      if (Object.prototype.hasOwnProperty.call(user, 'userSettings') && user.userSettings !== null) {
        if (
          Object.hasOwnProperty.call(user.userSettings, 'showBusinessFunctions') &&
          user.userSettings.showBusinessFunctions === true
        ) {
          return true;
        }
      }
      return false;
    },
  },
  actions: {
    login({ commit }, user) {
      return AuthService.login(user)
        .then(
          user => {
            commit('loginSuccess', user);
            return Promise.resolve(user);
          },
          error => {
            commit('loginFailure');
            return Promise.reject(error);
          }
        )
        .catch(error => {
          return Promise.reject(error);
        });
    },
    logout({ commit }) {
      AuthService.logout();
      commit('logout');
    },
    updateUser({ commit }, user) {
      // Get whole user object with token from localstorage as String.
      let localUser = JSON.parse(localStorage.getItem('user'));

      // Replace old user data with new user data.
      localUser.user = user;
      // Write new user object to localstorage.
      localStorage.setItem('user', JSON.stringify(localUser));

      // Update the state.
      commit('updateUser', user);
    },
    register({ commit }, user) {
      return AuthService.register(user)
        .then(
          response => {
            commit('registerSuccess', response.data);
            return Promise.resolve(response.data);
          },
          error => {
            commit('registerFailure');
            return Promise.reject(error);
          }
        )
        .catch(error => {
          return Promise.reject(error);
        });
    },
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
      window.$crisp.push(['set', 'user:email', user.user.email]);
      window.$crisp.push(['set', 'user:nickname', user.user.name]);
    },
    updateUser(state, user) {
      state.user.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
      window.$crisp.push(['set', 'user:email', '']);
      window.$crisp.push(['set', 'user:nickname', '']);
    },
    registerSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
      window.$crisp.push(['set', 'user:email', user.user.email]);
      window.$crisp.push(['set', 'user:nickname', user.user.name]);
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    },
  },
};
