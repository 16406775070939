<template>
  <button :class="['decentButton', color]" v-on="$listeners">
    <font-awesome-icon v-if="iconBefore" :icon="iconBefore" class="iconBefore"/>
    {{text}}
    <font-awesome-icon v-if="iconAfter" :icon="iconAfter" class="iconAfter" />
  </button>
</template>

<script>
export default {
  name: "DecentButtonComponent",
  props: {
    text: {
      type: String,
      default: 'Text'
    },
    color: {
      type: String,
      default: 'default'
    },
    iconBefore: {
      type: String,
    },
    iconAfter: {
      type: String,
    },
  }
}
</script>

<style scoped lang="scss">
button.decentButton {
  background: transparent;
  padding: 4px 8px;
  border: none;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 600;
  color: $grayText;

  &:hover {
    background: $lightViolet;
    color: $violet;
  }
  &.red {
    &:hover {
      background: $lightRed;
      color: $red;
    }
  }
}

.iconBefore {
  margin-right: 4px;
}

.iconAfter {
  margin-left: 4px;
}
</style>
