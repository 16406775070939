<template>
  <div class="dashboardView gap20 flex-column">
    <section class="headline flex-row jst-spc-bt alg-it-c">
      <h1>{{ greetingByTime }}{{ firstName }}</h1>
      <SubMenuComponent :options="['mtd', '30d', '90d', '12m','ytd', 'all']" class="bold" v-model="subMenuSelected"/>
    </section>
    <section class="flex-row gap20" v-if="statistics.subsInTimeRange">
      <white-box class="tag">
        <div>{{ statistics.subsInTimeRange.absolute }}
          <span class="comparison green">
            <font-awesome-icon icon="arrow-up"/>
            {{ statistics.subsInTimeRange.relative }}%
          </span>
        </div>
        <span>Neue Abos ({{ statistics.totalSubs.absolute }} total)</span>
      </white-box>
      <white-box class="tag">
        <div>+{{ statistics.registersInTimeRange.absolute }}
          <span class="comparison green" v-if="statistics.registersInTimeRange.relative > 0">
            <font-awesome-icon icon="arrow-up"/>
            {{ statistics.registersInTimeRange.relative }}%
          </span>
          <span v-else class="comparison red">
            <font-awesome-icon icon="arrow-down"/>
            {{ statistics.registersInTimeRange.relative }}%
          </span>
        </div>
        <span>Registrierungen</span>
      </white-box>
      <white-box class="tag">
        <div>{{ statistics.conversionRate.absolute }}%
          <span class="comparison green">
            <font-awesome-icon icon="arrow-up"/>
            {{ statistics.conversionRate.relative }}%
          </span>
        </div>
        <span>Trial-to-Paid-CR</span>
      </white-box>
      <white-box class="tag">
        <div>{{ timeToCustomer.timeToCustomer }}d
          <span class="comparison green">
            <font-awesome-icon icon="arrow-down"/>
            {{ timeToCustomer.timeToCustomerRel }}%
          </span>
        </div>
        <span>Time-to-Paid</span>
      </white-box>
    </section>
    <section class="contents flex-row alg-it-s gap8">
      <section class="left flex-column">
        <section class="flex-column">
          <h3>Anzahl Trials & Conversionrate</h3>
          <white-box>
            <VueApexCharts type="area" height="290" :options="trialChart" :series="series" ref="trials"/>
          </white-box>
        </section>
        <section class="flex-column">
          <h3>Abo-Verteilung</h3>
          <white-box>
            <VueApexCharts type="bar" height="290" :options="subscriptionChartOptions"
                           :series="subscriptionChartOptions.series" ref="subscriptions"/>
          </white-box><br>
          <h3>Abo-Verteilung (monatlich vs. jährlich)</h3>
          <white-box>
            {{statistics.subscriptionRatio.yearly.toFixed(2)*100}}% der User haben ein Jahresabo.
            <progress :value="statistics.subscriptionRatio.yearly" max="1" />
          </white-box>
        </section>
      </section>
      <section class="right flex-column">
        <section>
          <h3>
            <font-awesome-icon icon="shield-alt"/>
            Sicherheit
          </h3>
          <WhiteBox>
            <h4>
              <font-awesome-icon icon="shield-alt"/>
              Login
            </h4>
            <div>
              <b>{{this.security.failed_logins}} failed logins</b> in the last 24hours.
            </div>
          </WhiteBox>
        </section>
        <section>
          <WhiteBox>
            <h4>
              <font-awesome-icon icon="shield-alt"/>
              Failed Jobs
            </h4>
            <div>
              <b>{{ this.security.failed_jobs.count }} total failed jobs</b>. The last one was {{security.failed_jobs.most_recent}} ago.
            </div>
          </WhiteBox>
        </section>
        <section>
          <WhiteBox :class="{red: security.recurring_payments.risk}">
            <h4>
              <font-awesome-icon icon="redo"/>
              Recurring Payments
            </h4>
            <div>
              Last successful cronjob <b>{{this.security.recurring_payments.relative}} ago</b>&nbsp;
              <tooltip-component>{{this.security.recurring_payments.absolute}}</tooltip-component>
            </div>
          </WhiteBox>
        </section>
      </section>
    </section>
  </div>
</template>

<script>
import WhiteBox from '../../components/WhiteBox';

import axios from '@/axios';
import SubMenuComponent from '../../components/SubMenuComponent.vue';
import TooltipComponent from "@/components/TooltipComponent.vue";

export default {
  name: 'DashboardView',
  components: {
    TooltipComponent,
    SubMenuComponent,
    WhiteBox,
  },
  data() {
    return {
      statistics: {},
      timeToCustomer: {},
      subMenuSelected:
          localStorage.getItem('dashboardTimeRange') === null ? '30d' : localStorage.getItem('dashboardTimeRange'),
      series: [
        {
          name: 'series1',
          data: [],
        },
        {
          name: 'series2',
          data: [],
        },
      ],
      trialChart: {
        series: [{
          data: [0, 0, 0, 0, 0, 0, 0, 0]
        }],
        colors: ['#609E31', '#6556eb'],
        grid: {
          show: true, // you can either change hear to disable all grids
        },
        chart: {
          height: 350,
          type: 'area',
          toolbar: {
            show: false,
          },
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
        },
        fill: {
          type: 'solid',
          opacity: [0.2, 0.2, 0.2],
        },
        yaxis: {
          opposite: true,
          tickAmount: 4,
        },
        dataLabels: {
          enabled: false,
        },
        labels: [],
        stroke: {
          curve: 'smooth',
          width: 2,
        },
        xaxis: {
          tickAmount: 6,
          tickPlacement: 'between',
          labels: {
            show: true,
            rotate: 0,
          },
        },
        noData: {
          text: 'Lädt...',
        },
      },
      chartOptions: {
        colors: ['#609E31', '#EB3148', '#6556eb'],
        grid: {
          show: true, // you can either change hear to disable all grids
        },
        chart: {
          height: 350,
          type: 'area',
          toolbar: {
            show: false,
          },
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
        },
        fill: {
          type: 'solid',
          opacity: [0.2, 0.2, 0.2],
        },
        yaxis: {
          opposite: true,
          labels: {
            formatter: function (val) {
              return Number(val).toLocaleString('de-ch') + ' CHF';
            },
          },
          tickAmount: 4,
        },
        dataLabels: {
          enabled: false,
        },
        labels: [],
        stroke: {
          curve: 'smooth',
          width: 2,
        },
        xaxis: {
          tickAmount: 6,
          tickPlacement: 'between',
          labels: {
            show: true,
            rotate: 0,
          },
        },
        noData: {
          text: 'Lädt...',
        },
      },
      subscriptionChartOptions: {
        colors: ['#609E31', '#6556eb'],
        grid: {
          show: true, // you can either change hear to disable all grids
        },
        chart: {
          height: 350,
          type: 'area',
          toolbar: {
            show: false,
          },
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
        },
        fill: {
          type: 'solid',
          opacity: [0.8, 0.8, 0.2],
        },
        yaxis: [{
          opposite: true,
          labels: {
            formatter: function (val) {
              return Number(val).toLocaleString('de-ch') + ' CHF';
            },
          },
          tickAmount: 4,
        },
          {
            opposite: false,
            tickAmount: 4,
          },
        ],
        dataLabels: {
          enabled: false,
        },
        labels: [],
        stroke: {
          curve: 'smooth',
          colors: ['transparent'],
          width: 5,
        },
        xaxis: {
          tickAmount: 6,
          tickPlacement: 'between',
          labels: {
            show: true,
            rotate: 0,
          },
        },
        noData: {
          text: 'Lädt...',
        },
        series: [{
          data: [0, 0, 0, 0, 0, 0, 0, 0]
        }],
      },
      monthlyMrr: 0,
      yearlyMrr: 0,
      security: {
        failed_logins: 0,
        failed_jobs: {
          count: 0,
          most_recent: '1d'
        },
        recurring_payments: {
          relative: '',
          absolute: '',
          risk: true,
        },
        backup: {
          relative: '',
          absolute: '',
          last_received: '',
          last_received_relative: '',
          risk: true
        }
      },
    };
  },
  computed: {
    greetingByTime() {
      let date = new Date();
      let hour = date.getHours();
      if (hour >= 0 && hour <= 5) {
        return 'Ganz schön spät';
      } else if (hour > 5 && hour <= 12) {
        return 'Guten Morgen';
      } else if (hour > 12 && hour < 17) {
        return 'Tag wohl';
      } else {
        return 'Guten Abend';
      }
    },
    firstName() {
      let firstName = this.$store.getters['auth/user'].user.name.split(' ')[0];
      if (firstName === '') {
        return '';
      }
      return ', ' + this.$store.getters['auth/user'].user.name.split(' ')[0];
    },
    showBusinessFunctions() {
      return this.$store.getters['auth/showBusinessFunctions'] === true;
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.getStatistics();
      this.getTimeToCustomer()
      this.getSubscriptionStatistics()
      this.getTrialStatistics()
      this.getSecurity();
    },
    getSecurity() {
      axios.get('admin/security').then(res => {
        this.security = res.data;
      })
    },
    getTrialStatistics() {
      let timeRange = this.getTimeRange();
      axios.get('admin/subscription/statistics/trials', {params: {timeRange: timeRange}}).then(res => {
        let trialsLabels = Object.keys(res.data.trials)
        let trialsData = Object.values(res.data.trials)

        // let conversionLabels = Object.keys(res.data.conversionRate)
        let conversionData = Object.values(res.data.conversionRate)

        this.$refs.trials.updateOptions({
          series: [
            {
              name: "Conversion Rate",
              data: conversionData
            },
            {
              name: "Neue Trials",
              data: trialsData
            },
          ],
          labels: trialsLabels
        });

      })
    },
    getSubscriptionStatistics() {
      // let timeRange = this.getTimeRange();
      axios.get('admin/subscription/statistics').then(res => {
        this.monthlyMrr = res.data.monthlyRecurring;
        this.yearlyMrr = res.data.yearlyRecurring;
        let groupedPlans = res.data.groupedByPlans;
        let userCountByPlan = res.data.plansCountByUsers;


        let labels = []
        let mrr = []
        groupedPlans.forEach(plan => {
          labels.push(plan.name)
          mrr.push(plan.mrr)
        })

        this.$refs.subscriptions.updateOptions({
          series: [
            {
              name: "MRR",
              data: mrr
            },
            {
              name: "Anzahl Kunden",
              data: Object.values(userCountByPlan)
            }],
          labels: labels
        });
      })
    },
    getTimeToCustomer() {
      let timeRange = this.getTimeRange();
      axios.get('admin/timeToCustomer', {params: {timeRange: timeRange}}).then(res => {
        this.timeToCustomer = res.data;
      })
    },
    getStatistics() {
      let timeRange = this.getTimeRange();
      axios.get('admin/dashboard', {params: {timeRange: timeRange}}).then(res => {
        this.statistics = res.data;
      })
    },
    getTimeRange() {
      let timeRange = '12months';
      if (this.subMenuSelected === 'mtd') {
        timeRange = 'currentMonth'
      } else if (this.subMenuSelected === 'ytd') {
        timeRange = 'currentYear'
      } else if (this.subMenuSelected === '30d') {
        timeRange = '30days'
      } else if (this.subMenuSelected === '90d') {
        timeRange = '90days'
      } else if (this.subMenuSelected === 'all') {
        timeRange = 'all'
      }
      return timeRange;
    }

  },
  watch: {
    subMenuSelected() {
      localStorage.dashboardTimeRange = this.subMenuSelected;
      this.getData();
    },
  },
};
</script>

<style scoped lang="scss">
.subMenu {
  margin-bottom: 0;
}

h3,
h4 {
  margin: 0 0 18px;
}


.firstRow {
  margin-bottom: 24px;
}

.timeAndInvoices {
  gap: 0 !important;

  .forecastTable {
    gap: 6px;

    > div:last-of-type {
      margin-top: 8px;
    }
  }

  section {
    padding: 12px 0;
    border-bottom: 1px solid #e8e8e8;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: none;
    }

    span {
      font-size: 0.85rem;
    }
  }
}

.boldSpan {
  font-weight: 500;
  margin-bottom: 4px;
  font-size: 0.9rem;
}

.contents {
  gap: 40px;
  flex-wrap: wrap;

  > section {
    flex: 0 calc(50% - 20px);
  }

  .whiteBox {
    box-shadow: 0 0 10px -5px rgba(0, 0, 0, 0.16);
    gap: 8px;
    display: flex;
    flex-direction: column;

    svg {
      margin-right: 6px;
    }

    h4 {
      margin: 0;
      font-weight: 600;
      font-size: 16px;
    }
  }

  .left {
    flex: 4;
    gap: 32px;

    .numbers {
      gap: 20px;
    }
  }

  .right {
    flex: 2;
    gap: 32px;

    .create {
      padding: 0;
      margin-top: 3px;
      gap: 0;

      > section {
        display: flex;
        align-items: center;
        padding: 10px 24px;
        transition: all 0.2s;
        font-weight: 500;
        border-bottom: 1px solid #e8e8e8;

        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 25px;
          width: 25px;
          background: $lightViolet;
          padding: 8px;
          border-radius: 50px;
          margin-right: 16px;

          svg {
            height: 16px;
            width: 16px;
            color: $violet;
          }
        }

        &:hover {
          cursor: pointer;
          background-color: $lightViolet;
        }

        &:first-of-type {
          border-top-right-radius: 10px;
          border-top-left-radius: 10px;
        }

        &:last-of-type {
          border-bottom-right-radius: 10px;
          border-bottom-left-radius: 10px;
        }
      }
    }
  }
}

.tag {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 600;

  span {
    font-size: 0.9rem;
    font-weight: normal;
    margin-top: 4px;
  }

  &.violet {
    color: $violet;
    background: $lightViolet;
  }

  > div {
    position: relative;
  }
}

.comparison {
  position: absolute;
  right: 0;
  transform: translateX(calc(100% + 6px));
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  font-size: 12px !important;

  svg {
    font-size: 0.7rem;
  }
}

@media screen and (max-width: 1280px) {
  .hideOnTablet {
    display: none;
  }
  .contents {
    flex-direction: column;
    align-items: flex-start;
  }
  .left,
  .right {
    width: 100%;
  }
}
</style>
