<template>
  <white-box>
    <div class="flex-row jst-spc-bt alg-it-c">
      <h2>Buchungen importieren</h2>
      <a href="https://support.milkee.ch/de/article/buchungen-importieren-ir9wjc/" class="smallText" target="_blank">
        <font-awesome-icon icon="question-circle" />
        Hilfe zum Datenimport</a
      >
    </div>
    <div class="flex-column jst-spc-bt" v-if="step === 0">
      <p>Du kannst hier deine Buchungen von anderen Softwareanbietern importieren.</p>
      <input type="file" accept=".csv,.txt" @change="onFileSelected" />
      <div>
        <button class="primary" v-show="file !== null" @click="step++">Weiter</button>
      </div>
    </div>
    <div class="flex-column jst-spc-bt" v-if="step === 1 && !success">
      <h3>Daten zuweisen</h3>
      <span
        >Deine Datei wurde eingelesen. Weise nun die Spalten deiner Datei den entsprechenden Feldern in MILKEE zu.</span
      >
      <span class="tinyText">Einnahme / Ausgabe</span>
      <select v-model="headersMapping.type" class="input">
        <option value="none" disabled>Auswählen</option>
        <option v-for="(header, index) in headers" :key="index" :value="header">{{ header }}</option>
      </select>

      <span class="tinyText">Betrag</span>
      <select v-model="headersMapping.sum" class="input">
        <option value="none" disabled>Auswählen</option>
        <option v-for="(header, index) in headers" :key="index" :value="header">{{ header }}</option>
      </select>

      <span class="tinyText">Datum</span>
      <select v-model="headersMapping.date" class="input">
        <option value="none" disabled>Auswählen</option>
        <option v-for="(header, index) in headers" :key="index" :value="header">{{ header }}</option>
      </select>

      <span class="tinyText">Aufwandskonto</span>
      <select v-model="headersMapping.expenseType" class="input">
        <option value="none" disabled>Auswählen</option>
        <option value="skip">Nicht importieren</option>
        <option v-for="(header, index) in headers" :key="index" :value="header">{{ header }}</option>
      </select>

      <span class="tinyText">Buchungstext</span>
      <select v-model="headersMapping.entryText" class="input">
        <option value="none" disabled>Auswählen</option>
        <option value="skip">Nicht importieren</option>
        <option v-for="(header, index) in headers" :key="index" :value="header">{{ header }}</option>
      </select>

      <span class="tinyText">Kategorie</span>
      <select v-model="headersMapping.category" class="input">
        <option value="none" disabled>Auswählen</option>
        <option value="skip">Nicht importieren</option>
        <option v-for="(header, index) in headers" :key="index" :value="header">{{ header }}</option>
      </select>
      <div>
        <p class="red" v-if="error">{{ errorMessage }}</p>
        <button class="primary" @click="startImport">Import starten</button>
      </div>
    </div>
    <p class="green" v-if="success">Der Import wurde erfolgreich abgeschlossen.</p>
  </white-box>
</template>

<script>
import WhiteBox from '../components/WhiteBox';
import axios from '../axios';

export default {
  name: 'ImportModule',
  components: { WhiteBox },
  data() {
    return {
      file: null,
      step: 0,
      headers: [],
      headersMapping: {
        type: 'none',
        sum: 'none',
        date: 'none',
        expenseType: 'none',
        entryText: 'none',
        category: 'none',
      },
      commonHeaders: {
        type: ['Typ'],
        sum: ['Betrag', 'Summe', 'Amount'],
        date: ['Date', 'Datum', 'Transaktionsdatum'],
        expenseType: ['Ausgabeart', 'Art der Ausgabe', 'Aufwandskonto', 'Aufwandkonto'],
        entryText: ['Beschreibung', 'Description'],
        category: ['Kategorie', 'Category'],
      },
      content: null,
      success: false,
      error: false,
      errorMessage: '',
    };
  },
  methods: {
    onFileSelected(e) {
      this.file = e.target.files[0];
      this.$papa.parse(this.file, {
        header: true,
        complete: function (results) {
          this.content = results.data;
          this.headers = results.meta.fields;
          this.preselectCommonHeaders();
        }.bind(this),
      });
    },
    startImport() {
      for (const [, value] of Object.entries(this.headersMapping)) {
        if (value === 'none') {
          this.errorMessage = 'Bitte alle felder ausfüllen.';
          this.error = true;
          return false;
        }
      }
      this.error = false;
      let entries = [];
      this.content.forEach(entry => {
        let newEntry = {
          type: entry[this.headersMapping.type],
          sum: entry[this.headersMapping.sum],
          date: entry[this.headersMapping.date],
        };
        if (this.headersMapping.expenseType !== 'skip') {
          newEntry.expenseType = entry[this.headersMapping.expenseType];
        }
        if (this.headersMapping.entryText !== 'skip') {
          newEntry.entryText = entry[this.headersMapping.entryText];
        }
        if (this.headersMapping.category !== 'skip') {
          newEntry.category = entry[this.headersMapping.category];
        }
        entries.push(newEntry);
      });

      axios
        .post('/entry/import', { entries: entries })
        .then(() => {
          this.success = true;
        })
        .catch(e => {
          this.errorMessage = e.response.data.message;
          this.error = true;
        });
    },
    preselectCommonHeaders() {
      Object.keys(this.commonHeaders).forEach(key => {
        let headers = [...this.headers];
        let duplicates = this.commonHeaders[key].filter(function (val) {
          return headers.indexOf(val) !== -1;
        });
        if (duplicates.length !== 0) {
          this.headersMapping[key] = duplicates[0];
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
span.tinyText {
  font-weight: bold;
  margin-top: 24px;
  color: $grayText;
}

button {
  margin-top: 24px;
}

.red {
  color: $red;
}

.green {
  color: $green;
}
</style>
