<template>
  <white-box class="expenseTypeSettingsModule flex-column">
    <div class="heading flex-row jst-spc-bt alg-it-c">
      <h2>Tags verwalten</h2>
      <a href="https://support.milkee.ch/de/article/was-sind-tags-qo7t7n/" class="smallText" target="_blank">
        <font-awesome-icon icon="question-circle" />
        Hilfe zu Tags</a
      >
    </div>
    <div class="flex-row jst-spc-bt createTag">
      <p>Hier kannst du deine Tags verwalten.</p>
      <button class="button" @click="newTag = { name: '', color: '' }">Tag erstellen</button>
    </div>
    <div class="expenseTypes flex-column">
      <div class="header flex-row jst-spc-bt"></div>
      <div class="center" v-show="loading">
        <loading />
      </div>
      <div class="flex-row jst-spc-bt tagItem" v-for="tag in tags" :key="tag.id">
        <div class="name flex-row" @click="selectedTag = tag">
          <tag-component :name="tag.name" :color="tag.color" />
        </div>
        <div class="actions flex-row alg-it-c">
          <span class="pointer" @click="selectedTag = { ...tag }">
            <font-awesome-icon icon="pen" />
            Bearbeiten
          </span>
          <span class="pointer" @click="selectedTagToDelete = { ...tag }">
            <font-awesome-icon icon="trash" />
            Löschen
          </span>
        </div>
      </div>
    </div>
    <!--Create Tag Modal-->
    <modal-module v-if="newTag" @close="newTag = null">
      <div slot="header"><h2>Tag Erstellen</h2></div>
      <div slot="body" class="flex-column">
        <label class="label">Name</label>
        <input class="input" v-model="newTag.name" />
        <label class="label">Farbe</label>
        <div class="tagcoloroptions">
          <tag-component
            v-for="(color, index) in tagColors"
            :key="index"
            :name="color"
            :color="color"
            :isSelected="newTag.color === color"
            v-on:click.native="newTag.color = color" />
        </div>
      </div>
      <div slot="footer" class="flex-row jst-spc-bt">
        <button class="button" @click="newTag = null">Abbrechen</button>
        <button
          :disabled="newTag.name.length < 1 || newTag.color.length < 1"
          class="primary"
          @click="
            createTag(newTag);
            newTag = null;
          ">
          Erstellen
        </button>
      </div>
    </modal-module>
    <!--Edit Tag Modal-->
    <modal-module v-if="selectedTag" @close="selectedTag = null">
      <div slot="header">Tag bearbeiten</div>
      <div slot="body" class="flex-column">
        <label class="label">Name</label>
        <input class="input" v-model="selectedTag.name" />
        <label class="label">Farbe</label>
        <div class="tagcoloroptions">
          <tag-component
            v-for="(color, index) in tagColors"
            :key="index"
            :name="color"
            :color="color"
            :isSelected="selectedTag.color === color"
            v-on:click.native="selectedTag.color = color" />
        </div>
      </div>
      <div slot="footer" class="flex-row jst-spc-bt">
        <button class="button" @click="selectedTag = null">Abbrechen</button>
        <button
          :disabled="selectedTag.name.length < 1"
          class="primary"
          @click="
            updateTag(selectedTag.id, selectedTag.name, selectedTag.color);
            selectedTag = null;
          ">
          Speichern
        </button>
      </div>
    </modal-module>
    <!--Delete Tag Modal-->
    <modal-module v-if="selectedTagToDelete" @close="selectedTagToDelete = null">
      <div slot="header">
        Bist du sicher, dass du den Tag
        <tag-component :name="selectedTagToDelete.name" :color="selectedTagToDelete.color" class="default-cursor" />
        löschen möchtest?
      </div>
      <div slot="body" class="flex-column">
        Diese Aktion kann nicht Rückgängig gemacht werden und der Tag wird aus allen verknüpften Buchungen entfernt.
      </div>
      <div slot="footer" class="flex-row jst-spc-bt">
        <button class="button" @click="selectedTagToDelete = null">Abbrechen</button>
        <button
          class="primary red"
          @click="
            deleteTag(selectedTagToDelete.id);
            selectedTagToDelete = null;
          ">
          Löschen
        </button>
      </div>
    </modal-module>
  </white-box>
</template>

<script>
import WhiteBox from '../components/WhiteBox';
import axios from '../axios';
import Loading from '@/components/LoadingSpinnerComponent';
import TagComponent from '@/components/TagComponent';
import ModalModule from './ModalModule';

export default {
  name: 'TagsSettingsModule',
  components: {
    ModalModule,
    WhiteBox,
    Loading,
    TagComponent,
  },
  data() {
    return {
      loading: true,
      tags: null,
      newTag: null,
      selectedTag: null,
      selectedTagToDelete: null,
      tagColors: null,
    };
  },
  created() {
    this.getTags();
    this.getTagColors();
  },
  methods: {
    getTagColors() {
      axios.get('/tags/colors').then(res => {
        this.tagColors = res.data;
      });
    },
    getTags() {
      axios.get('/tags').then(res => {
        this.tags = res.data;
        this.loading = false;
      });
    },
    updateTag(tagId, name, color) {
      axios
        .post('/tags/' + tagId, { name: name, color: color })
        .then(() => {
          this.getTags();
        })
        .catch(e => {
          this.$toast.error(e.response.data.message);
        });
    },
    createTag(tag) {
      axios
        .post('/tags', { name: tag.name, color: tag.color })
        .then(() => {
          this.getTags();
        })
        .catch(e => {
          this.$toast.error(e.response.data.message);
        });
    },
    deleteTag(tagId) {
      axios.delete('/tags/' + tagId).then(() => {
        this.getTags();
      });
    },
  },
};
</script>

<style scoped lang="scss">
.heading {
  svg {
    margin-right: 4px;
  }
}

.createTag {
  margin-top: 12px;
  button {
    margin: 12px 0px;
  }
}

button:disabled {
  background: transparent;
  color: $grayText;
  &:hover {
    cursor: not-allowed;
  }
}

.tagcoloroptions {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.header {
  padding: 8px 4px;
  border-bottom: 1px solid $tableGray;

  span {
    color: $lightGrayText;
  }
}

span {
  color: $grayText;
  font-size: 14px;
}

.tabs {
  width: 190px;
  gap: 24px;

  span {
    font-size: 16px;
    color: $labelGray;
    text-align: center;
    width: 50%;

    &.active {
      border-bottom: 2px solid $violet;
      color: $violet;

      &:hover {
        border-bottom: 2px solid $violet;
      }
    }

    &:hover {
      border-bottom: 2px solid $labelGray;
    }
  }
}

.name {
  text-align: left;
}

.tagItem {
  padding: 8px 4px;
  margin: 2px 0;
  border-radius: 4px;

  svg {
    transition: all 0.2ms;
    margin-left: 8px;
    font-size: 12px;
    color: $labelGray;
    margin-top: 3px;
  }

  .editIcon {
    opacity: 0;
  }

  &:hover {
    background-color: $darkGray;

    .editIcon {
      opacity: 1;
    }
  }

  .name {
    &:hover {
      cursor: pointer;
      .editIcon {
        opacity: 1;
        color: $violet;
      }
    }
  }

  input {
    &.small {
      margin-left: 8px;
      margin-right: 0;
    }
  }

  label {
    cursor: pointer;
  }

  .actions {
    font-size: 12px;

    span {
      margin-left: 8px;
      transition: all 0.2ms;

      &:hover {
        color: $violet;

        svg {
          color: $violet;
        }
      }
    }
  }
}

.center {
  display: flex;
  justify-content: center;
  margin-top: 48px;
}
</style>
