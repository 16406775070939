<template>
  <ModalModule v-show="showModal" @close="showModal = false">
    <template v-slot:header>
      <h2>Benachrichtigung erstellen</h2>
    </template>
    <template v-slot:body>
      <section class="flex-column gap8" v-show="step === 0">
        <label class="flex-column">
          <span class="label">Typ auswählen</span>
          <select v-model="notification.type" class="input">
            <option value="notification">Benachrichtigung</option>
            <option value="popup">Popup</option>
          </select>
        </label>
        <label class="flex-column">
          <span class="label">Titel</span>
          <input type="text" class="input" v-model="notification.title">
        </label>
      </section>
      <section class="flex-column gap8" v-show="step === 1">
        <draggable v-model="notification.content">
          <div v-for="(item, index) in notification.content" :key="index" class="flex-row alg-it-c gap8 contentItem">
            <font-awesome-icon icon="grip-vertical" />
            <div class="actualContentArea">
              <label class="flex-column" v-if="item.type === 'text'">
                <div class="label flex-row jst-spc-bt">
                  <span>Text</span>
                  <span class="pointer" @click="removeContentItem(index)"><font-awesome-icon icon="times"/></span>
                </div>
                <textarea class="input" v-model="item.content"></textarea>
              </label>
              <label class="flex-column" v-if="item.type === 'image'">
                <div class="label flex-row jst-spc-bt">
                  <span>URL zum Bild</span>
                  <span class="pointer" @click="removeContentItem(index)"><font-awesome-icon icon="times"/></span>
                </div>
                <input type="url" class="input" v-model="item.content">
              </label>
            </div>
          </div>
        </draggable>
        <div class="flex-row jst-fs">
          <decent-button-drop-down-component :options="contentOptions" @addText="addContentItem('text')" @addImage="addContentItem('image')" text="Element hinzufügen"/>
        </div>
      </section>
      <section class="flex-column gap8" v-if="step === 2">
        <div class="flex-column">
          <span class="label">Button links</span>
          <input type="text" class="input" placeholder="Button links Text" v-model="notification.buttons[0].text">
          <input type="text" class="input" placeholder="Callback" v-model="notification.buttons[0].callback">
        </div>
        <div class="flex-column">
          <span class="label">Button rechts (primary) </span>
          <input type="text" class="input" placeholder="Button rechts Text" v-model="notification.buttons[1].text">
          <input type="text" class="input" placeholder="Callback" v-model="notification.buttons[1].callback">
        </div>
      </section>
      <section class="flex-column gap8" v-if="step === 3">
        <div class="flex-column">
          <span class="label">User auswählen</span>
          <select v-model="notification.userQuery.query" class="input">
            <option value="all">Alle</option>
            <option value="allActive30d">Alle Aktiven (30d)</option>
            <option value="allActive90d">Alle Aktiven (90d)</option>
            <option value="paid">Paid User</option>
            <option value="paidActive30d">Paid Active Users (30days)</option>
            <option value="paidActive90d">Paid Active Users (90days)</option>
          </select>
        </div>
      </section>
    </template>
    <template v-slot:footer>
      <div class="flex-row jst-spc-bt w100">
        <button class="" @click="showModal = false" v-show="step === 0">Abbrechen</button>
        <button class="" @click="step--" v-show="step !== 0">Zurück</button>
        <button class="primary active" @click="step++" v-show="step !== 3">Weiter</button>
        <button class="primary active" @click="doAddNotification" v-show="step === 3">Speichern</button>
      </div>
    </template>
  </ModalModule>
</template>

<script>
import ModalModule from "@/modules/ModalModule.vue";
import draggable from 'vuedraggable';
import DecentButtonDropDownComponent from "@/components/DecentButtonDropDownComponent.vue";
import axios from "@/axios";

export default {
  name: "NotificationModalModule",
  components: {DecentButtonDropDownComponent, ModalModule, draggable},
  props: {
    id: {
      type: [Number, String],
      default: null,
    }
  },
  data() {
    return {
      showModal: false,
      step: 0,
      notification: {
        type: 'notification',
        title: '',
        content: [
          {type: 'text', content: 'da'},
          {type: 'text', content: 'dadasdfa'}
        ],
        buttons: [
          {text: '', callback: ''},
          {text: '', callback: '', type: 'submit'},
        ],
        userQuery: {
          query: 'all',
          userIds: null,
        },
      },
      contentOptions: [
        {title: 'Text', icon: 'font', callback: 'addText'},
        {title: 'Bild', icon: 'image', callback: 'addImage'},
      ]
    };
  },
  created() {
    if (this.id !== null) this.loadNotification();
  },
  methods: {
    show(){
      this.showModal = true;
    },
    loadNotification() {
      axios.get('/admin/notifications/' + this.id).then(response => {
        this.notification = response.data;
        this.notification.content = JSON.parse(this.notification.content);
        this.notification.buttons = JSON.parse(this.notification.buttons);
        if (this.notification.userQuery !== null) {
          this.notification.userQuery = JSON.parse(this.notification.userQuery);
        } else {
          this.notification.userQuery = {
            query: 'all',
            userIds: null,
          };
        }
      }).catch(e => {
        this.$toast.error(e.response.data.message);
      });
    },
    doAddNotification() {
      let url = '/admin/notifications';
      if (this.id !== null) url += '/' + this.id;

      axios.post(url, this.notification).then(() => {
        this.$toast.success('Benachrichtigung wurde erfolgreich erstellt');
        this.showModal = false;
        this.$emit('success');
      }).catch(e => {
        this.$toast.error('Benachrichtigung konnte nicht erstellt werden');
        console.log(e.response.data.message)
      });
    },
    addContentItem(item) {
      this.notification.content.push({type: item.toLowerCase(), content: ''});
    },
    removeContentItem(index) {
      this.notification.content.splice(index, 1);
    }
  },
}
</script>

<style scoped lang="scss">
.contentItem {
  svg {
    color: $labelGray;
  }

  .actualContentArea {
    width: 100%;
  }
}

button.gray {
  font-size: 0.7rem;

  svg {
    font-size: 0.6rem;
  }
}

</style>