<template>
  <span
    class="tagComponent pointer noselect"
    :class="[
      color,
      {
        withHoverX: withHoverX,
        inTable: inTable,
        isSelected: isSelected,
      },
    ]">
    <span class="name">{{ name }}</span>
    <span class="icon" v-if="withHoverX">×</span>
  </span>
</template>

<script>
export default {
  name: 'TagComponent',
  props: {
    color: {
      type: String,
      required: true,
      default: 'orange',
    },
    name: {
      type: [String, Number],
      required: true,
    },
    withHoverX: {
      type: Boolean,
      default: false,
    },
    inTable: {
      type: Boolean,
      default: false,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.tagComponent {
  width: fit-content;
  border-radius: 50px;
  padding: 2px 12px;
  font-size: 0.8rem;
  text-transform: lowercase;
  white-space: nowrap;
  transition: 0.2s all;
  position: relative;
  border: 1px solid transparent;
  &.inTable {
    font-size: 0.65rem;
    &:hover {
      cursor: default;
    }
  }
  .icon {
    padding-left: 4px;
    width: 8px;
    display: none;
  }
  &.withHoverX:hover {
    .icon {
      display: inline-block;
    }
  }
  &.bigTag {
    font-size: 0.85rem;
  }
  &.mediumTag {
    padding: 2px 8px;
    font-size: 0.75rem !important;
  }
  &.tinyTag {
    padding: 1px 8px;
    font-size: 0.75rem !important;
  }
  &.currency {
    text-transform: none;
    font-weight: bolder;
    cursor: default !important;

    &.notBold {
      font-weight: 500;
    }
  }
  &.noPointer {
    cursor: default !important;
  }
  &.rightAlign {
    justify-content: flex-end;
  }
  &.orange {
    background: #fbdd9f;
    color: #92381a;
    &.isSelected {
      border: 1px #92381a solid;
    }
  }

  &.yellow {
    background: #faf6cf;
    color: $black;
    &.isSelected {
      border: 1px $black solid;
    }
  }

  &.blue {
    background: #d4dafc;
    color: #1453a8;
    &.isSelected {
      border: 1px #1453a8 solid;
    }
  }
  &.lime {
    background: #d5f0b1;
    color: #336013;
    &.isSelected {
      border: 1px #336013 solid;
    }
  }
  &.turquoise {
    background: #c7e8ed;
    color: #0c5b6d;
    &.isSelected {
      border: 1px #0c5b6d solid;
    }
  }
  &.marine {
    background: #dee0fa;
    color: #444db4;
    &.isSelected {
      border: 1px #444db4 solid;
    }
  }
  &.purple {
    background: #eadcfc;
    color: $violet;
    &.isSelected {
      border: 1px $violet solid;
    }
  }
  &.pink {
    background: #f7daed;
    color: #9f2880;
    &.isSelected {
      border: 1px #9f2880 solid;
    }
  }
  &.green {
    background: #e3f1d5;
    color: #609e31;
    &.isSelected {
      border: 1px #609e31 solid;
    }
  }
  &.red {
    background: #fbdade;
    color: #eb3148;
    &.isSelected {
      border: 1px #eb3148 solid;
    }
  }
  &.gray {
    background: #e8e8e8;
    color: #56575b;
    &.isSelected {
      border: 1px #56575b solid;
    }
  }
}
</style>
