<template>
  <transition name="modalMobileTrans">
    <div class="modalMobile">
      <div class="modalMobileWrapper">
        <HeaderTitleMobile :title="title" :modal="true" @close="$emit('close')"/>
        <div class="modalContent flex-column jst-fs">
          <slot></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import HeaderTitleMobile from "./HeaderTitleMobileComponent";

export default {
  name: "ModalMobile",
  props: {
    title: {
      type: String,
      default: 'Buchung bearbeiten'
    }
  },
  components: {HeaderTitleMobile}
}
</script>

<style lang="scss">
.modalMobile {
  position: fixed;
  left: 0;
  right: 0;
  top: 14px;
  bottom: 0;
  background: #FAFAFC;
  z-index: 11;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
  overflow-y: scroll;
  overflow-x: hidden;

  button {
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-top: 20px;
  }

  .modalMobileWrapper {
    position: relative;
    height: 100%;
  }
}

.mobileModalListHeader {
  padding: 4px 20px;
  font-weight: 600;
  font-size: 0.9rem;
  margin-top: 18px;

  &:first-of-type {
    margin-top: 0;
  }
}

.modalMobileTrans-enter-active {
  transition: all .2s ease;
}

.modalMobileTrans-leave-active {
  transition: all .2s ease;
}

.modalMobileTrans-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */
{
  transform: translateY(1000px);
}

.modalMobileTrans-enter {
  transform: translateY(1000px);
}
</style>
