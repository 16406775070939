<template>
  <div class="filterWrapper" :class="{ hidden: filter.hidden }" v-if="allLoaded">
    <span class="label">{{ title }}</span>
    <section
      class="filter flex-row alg-it-c pointer"
      @click="showPopover = !showPopover"
      :class="{ active: showPopover || filterIsActive }">
      <font-awesome-icon :icon="icon" />
      <span class="title">{{ getTitleFromSelected() }}</span>
      <font-awesome-icon icon="caret-down" :class="{ turn180: showPopover }" class="caret" />
    </section>
    <transition name="filterFadeIn">
      <section class="popover" v-show="showPopover">
        <!-- Radio Buttons -->
        <div v-if="filter.type === 'radio'">
          <checkbox v-for="option in filter.options" :key="option.value" class="filterItem">
            <label>
              <input type="radio" class="small" v-model="selected" :value="option.value" />
              {{ option.label }}
            </label>
          </checkbox>
        </div>

        <!-- Tags -->
        <div v-if="filter.type === 'tags'">
          <checkbox v-for="option in filter.options" :key="option.value" class="filterItem">
            <label>
              <input type="checkbox" class="small" v-model="tagsSelected" :value="option.value" />
              <tag-component v-if="option.color" :name="option.label" :color="option.color" />
              <span v-else>{{ option.label }}</span>
            </label>
          </checkbox>
        </div>

        <!-- Time Range -->
        <div v-if="filter.type === 'date'">
          <checkbox v-for="option in filter.options" :key="option.value" class="filterItem">
            <label v-if="option.value !== 'custom'">
              <input type="radio" class="small" v-model="selected" :value="option.value" />
              {{ option.label }}
            </label>
            <section v-else class="pointer customDateSelection">
              <div class="border"></div>
              <label>
                <input type="radio" class="small" v-model="selected" :value="option.value" />
                {{ option.label }}
              </label>
            </section>
          </checkbox>
          <section class="flex-column customDateInputs" v-if="selected === 'custom'">
            <div class="flex-column">
              <label class="label">Startdatum</label>
              <DateInputComponent v-model="filter.startDate" />
            </div>
            <div class="flex-column">
              <label class="label">Enddatum</label>
              <DateInputComponent v-model="filter.endDate" />
            </div>
          </section>
        </div>
      </section>
    </transition>
    <div class="backdrop" @click="showPopover = !showPopover" v-if="showPopover"></div>
  </div>
  <div v-else :class="{ hidden: filter.hidden }">
    <span class="label">{{ title }}</span>
    <section class="placeholder filter">
      <div class="animated-background flex-row alg-it-c"></div>
      <font-awesome-icon :icon="icon" />
      <span class="title">{{ getTitleFromSelected() }}</span>
      <font-awesome-icon icon="caret-down" :class="{ turn180: showPopover }" class="caret" />
    </section>
  </div>
</template>

<script>
import Checkbox from './CheckboxComponent';
import DateInputComponent from './DateInputComponent';
import TagComponent from './TagComponent';

export default {
  name: 'FilterComponent',
  components: { TagComponent, Checkbox, DateInputComponent },
  props: {
    title: {
      type: String,
      required: true,
      default: 'Titel',
    },
    icon: {
      type: String,
      required: true,
      default: 'filter',
    },
    filter: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showPopover: false,
      default: this.filter.default,
      selected: this.filter.selected,
      tagsSelected: [this.filter.selected],
    };
  },
  computed: {
    filterIsActive() {
      let selected = this.selected;
      if (Array.isArray(this.selected) && this.selected.length === 1) {
        selected = selected[0];
      }
      return !(selected === this.default);
    },
    allLoaded() {
      return this.$store.getters['loadingIsFinished'];
    },
  },
  methods: {
    getTitleFromSelected() {
      let option = {};

      if (this.filter.type === 'date' && this.selected === 'custom') {
        option.label =
          this.getShorthandDateFormat(this.filter.startDate) + ' - ' + this.getShorthandDateFormat(this.filter.endDate);
      } else if (this.filter.type === 'tags' && Array.isArray(this.selected) && this.selected.length > 1) {
        option.label = 'Mehrere';
      } else {
        option = this.filter.options.find(option => String(option.value) === String(this.selected));
      }

      if (option == null || (!Object.prototype.hasOwnProperty.call(option, 'label') && this.selected !== 'custom')) {
        return 'Lädt...';
      } else {
        return option.label;
      }
    },
    getShorthandDateFormat(date) {
      // Date must be in the format Y.m.d
      let year = date.split('-')[0].slice(2);
      let month = date.split('-')[1];
      let day = date.split('-')[2];
      return day + '.' + month + '.' + year;
    },
  },
  watch: {
    selected(newVal) {
      if (this.filter.type !== 'date' || this.selected !== 'custom') {
        this.showPopover = false;
      }

      if (this.filter.type === 'tags') {
        if (this.selected !== 'all') {
          this.tagsSelected = newVal;
        }
      }

      this.$emit('input', newVal);
    },
    filter: {
      handler(newFilter) {
        this.selected = newFilter.selected;
        this.getTitleFromSelected();
      },
      deep: true,
    },
    tagsSelected: {
      handler(newTagsSelected) {
        // 'all' was set just now. Remove all other selections.
        if (!this.selected.includes('all') && newTagsSelected.includes('all')) {
          this.selected = ['all'];
          this.tagsSelected = 'all';
        } else if (this.selected.includes('all') && newTagsSelected.length > 1) {
          // 'all' was set before and now there are other selections. Remove 'all'.
          let filteredArray = newTagsSelected.filter(function (e) {
            return e !== 'all';
          });

          this.tagsSelected = filteredArray;
          this.selected = filteredArray;
        } else if (this.selected.includes('all') && newTagsSelected.length === 0) {
          // 'all' was set before and deselected now. reselect all.
          this.selected = ['all'];
          this.tagsSelected = 'all';
        } else {
          this.selected = newTagsSelected;
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
$filterHeight: 31.5px;
$filterBorderRadius: 8px;
$filterPadding: 4px 8px;

.filterWrapper {
  position: relative;
}

.turn180 {
  transform: rotate(-180deg);
}

.filter {
  position: relative;
  background-color: transparent;
  border-radius: $filterBorderRadius;
  border: 2px solid $filterBlack;
  padding: $filterPadding;
  min-width: 0;
  font-family: Poppins;
  font-size: 13px;
  color: $filterBlack;
  margin-right: 24px;
  transition: all 0.2s;
  max-width: 200px;
  white-space: nowrap;
  max-height: $filterHeight;

  svg {
    &:first-of-type {
      margin-right: 8px;
    }

    &:last-of-type {
      margin-left: 8px;
    }
  }

  .title {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .caret {
    transition: all 0.4s;
  }

  &.active {
    background: $filterBlack;
    color: white;

    &:hover {
      background: $filterBlack;
      color: white;
    }
  }

  &:hover {
    background: $filterBlackTransparent;
    color: $black;
  }

  &.hidden {
    display: none;
  }
}

.popover {
  position: absolute;
  top: calc(100% + 4px);
  width: fit-content;
  min-width: 100%;
  background: white;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 24px;
  box-sizing: border-box;
  opacity: 1;
  z-index: 1000;
  font-weight: 300;
  white-space: nowrap;
  font-size: 14px;

  .filterItem {
    margin-bottom: 12px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0);
}

.customDateSelection {
  position: relative;
  width: 100%;
  padding-top: 18px;
  margin-top: 18px;
  min-width: 200px;

  .border {
    position: absolute;
    left: -24px;
    top: -6px;
    height: 1px;
    background: $tableGray;
    width: calc(100% + 48px);
  }
}

.customDateInputs {
  margin-top: 12px;

  > div {
    margin-bottom: 8px;
  }
}

/* Filter placeholder for loading */
.placeholder {
  border: 2px solid transparent;
  border-radius: $filterBorderRadius;
  padding: $filterPadding;
  font-size: 13px;

  &:hover {
    background: transparent;
  }
}

.animated-background {
  border-radius: $filterBorderRadius;
  padding: $filterPadding;
  box-sizing: border-box;
  left: 0;
  top: 0;
  height: 100%;
}

/*--- Filter placeholder for loading end ---*/

.filterFadeIn-enter-active {
  transition: all 0.1s;
  animation: oversize 0.15s;
}

.filterFadeIn-enter /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  animation: oversize 0.15s;
}

.filterFadeIn-leave-to,
.filterFadeIn-leave-active {
  transition: all 0.1s;
  animation: oversize 0.15s reverse;
}

@keyframes oversize {
  0% {
    transform: scale(0);
  }
  85% {
    transform: scale(1.01);
  }
  100% {
    transform: scale(1);
  }
}
</style>
