<template>
  <div id="signUpView">
    <div id="signUpContainer">
      <div class="wrapper" id="signUpWrapper">
        <h1>Einloggen</h1>
        <div class="message failed" v-if="failed">
          {{ failedMessage }}
          <br />
        </div>
        <div class="message success" v-if="successMessage">{{ successMessage }}</div>
        <form @submit.prevent="handleLogin" v-if="!successful" class="loginForm">
          <input name="email" class="input" type="email" placeholder="E-Mail" v-model="user.email" required v-focus />
          <input
            name="password"
            class="input"
            type="password"
            placeholder="Passwort"
            v-model="user.password"
            required />
          <div class="flex-column jst-spc-bt">
            <button class="primary" :disabled="submitted">
              <span v-if="!loading">Login</span>
              <Loading class="white small" v-if="loading" />
            </button>
          </div>
        </form>
        <div class="othersLinks flex-column alg-it-s">
          <div class="loginLinks"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import User from '../../models/user';
import Loading from '../../components/LoadingSpinnerComponent';

export default {
  name: 'LoginView',
  components: { Loading },
  data() {
    return {
      user: new User('', '', ''),
      loading: false,
      message: '',
      submitted: false,
      successful: false,
      code: '',
      failed: false,
      failedMessage: '',
      successMessage: '',
      codeSuccess: false,
    };
  },
  mounted() {
    this.$gtag.pageview({ page_path: '/login' });
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/');
    }
  },
  methods: {
    handleLogin() {
      this.loading = true;
      if (this.user.email && this.user.password) {
        // Get reCAPTCHA v3 token.
        this.$recaptcha('login').then(token => {
          this.user.token = token;

          // Dispatch actual login action.
          this.$store
            .dispatch('auth/login', this.user)
            .then(() => {
              this.$gtag.event('login', { event_category: 'App', event_label: 'Login' });
              this.$router.push('/');
              this.loading = false;
            })
            .catch(e => {
              this.failed = true;
              this.loading = false;
              this.failedMessage = e.response.data.message;
              this.submitted = false;
            });
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
section#content {
  margin-left: 0;
  margin-right: 0;
  padding-top: 0 !important;
}

#signUpView {
  margin-top: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-image: url('https://milkee.ch/wp-content/uploads/2022/10/hintegrund-milkee-3.png');
  background-position: center;
  background-repeat: no-repeat;

  #signUpContainer {
    width: auto;
    min-width: 450px;
    display: flex;
    justify-content: center;
    background: white;
    padding: 32px 18px;
    border-radius: 5px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;

    .wrapper {
      width: 80%;
      position: relative;

      h1 {
        margin-bottom: 48px;
        font-size: 2rem;
        text-align: center;
      }

      .input,
      .checkboxContainer {
        width: 100%;
        margin-bottom: 24px;
      }

      .save {
        width: 150px;
        height: 40px;
      }

      .buttonPrimary {
        margin-bottom: 48px;
      }

      .loginLinks {
        margin-bottom: 10px;
      }

      .message {
        position: relative;
        margin-bottom: 32px;
      }

      .success {
        color: green;
      }

      .failed {
        color: red;
      }

      .inputHalf {
        display: flex;
        justify-content: space-between;

        input {
          flex-basis: 49%;
        }
      }
    }
    .loginForm {
      margin-top: 24px;
      margin-bottom: 24px;
    }
  }

  .recaptcha {
    margin-bottom: 32px;
  }

  input {
    display: block;
    font-size: 16px;
  }
}

button {
  margin-bottom: 24px;
}

@media (max-width: 1280px) {
  #signUpView {
    #signUpContainer {
      width: 55%;
    }

    #imageContainer {
      width: 45%;
    }
  }
}

@media (max-width: 980px) {
  #signUpView {
    flex-direction: column-reverse;
    justify-content: flex-end;
    background: white;
    #signUpContainer {
      width: 100%;
      margin-top: 48px;
      box-shadow: none;
      min-width: unset;

      #signUpWrapper {
        h1 {
          margin-bottom: 48px;
        }
      }
    }

    .loginLinks a {
      display: block;
    }

    .inputHalf {
      flex-direction: column;
    }
  }
}
</style>
