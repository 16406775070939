<template>
  <ModalModule v-on="$listeners">
    <template v-slot:header>
      <div id="steps">
        <span class="number" :class="{ active: step >= 1 }" v-if="step <= 1">1</span>
        <span class="number pointer" :class="{ active: step >= 1 }" v-if="step > 1" @click="step = 1">1</span>
        <span class="between"></span>
        <span class="number" :class="{ active: step >= 2 }" v-if="step <= 2">2</span>
        <span class="number pointer" :class="{ active: step >= 1 }" v-if="step > 2" @click="step = 2">2</span>
        <span class="between"></span>
        <span class="number" :class="{ active: step >= 3 }">3</span>
        <span class="between"></span>
        <span class="number" :class="{ active: step >= 4 }">4</span>
        <span class="between"></span>
        <span class="number" :class="{ active: step >= 5 }">5</span>
      </div>
      <h2>Splittest <span v-if="isEditModal">bearbeiten</span> <span v-else>hinzufügen</span></h2>
    </template>
    <template v-slot:body>
      <section v-if="step === 1">
        <label>
          <span class="label">Name</span>
          <input type="text" class="input" v-model="splitTest.name">
        </label>
        <label>
          <span class="label">Startdatum</span>
          <input type="datetime-local" v-model="splitTest.start" class="input">
        </label>
      </section>
      <!-- Conditions Group A -->
      <section v-if="step === 2" class="flex-column gap8">
        <b>Gruppe A</b>
        <div></div>
        <section class="flex-column">
          <span class="label">Bedingungen für Gruppe A</span>
          <select class="input w100" v-model="groupAConditions">
            <option value="allUsersGroupAFromStartDate">Alle Nutzenden ab Startdatum mit Gruppe A</option>
            <option value="custom">Eigene Bedingungen</option>
          </select>
        </section>
        <div></div>
        <b>Felder definieren</b>
        <section class="flex-column gap8" v-if="groupAConditions === 'custom'">
          <section class="flex-column">
            <span class="label">Tabelle auswählen</span>
            <select class="input" v-model="splitTest.groupATable">
              <option v-for="table in availableTables" :key="table" :value="table">{{ table }}</option>
            </select>
          </section>
          <section>
            <section v-for="(where, index) in splitTest.groupAWheres" :key="index" class="flex-row jst-spc-bt alg-it-c">
              <div class="flex-column w50">
                <span class="label">Feld</span>
                <select v-model="where.field" class="input 100">
                  <option v-for="option in availableColsGroupA" :value="option" :key="option">{{ option }}</option>
                </select>
              </div>
              <div class="flex-column">
                <span class="label">Operator</span>
                <select class="input" v-model="where.operator">
                  <option value="=">=</option>
                  <option value=">">></option>
                  <option value="<">&lt;</option>
                  <option value="<=">&lt;=</option>
                  <option value=">=">>=</option>
                </select>
              </div>
              <div class="flex-column">
                <span class="label">Wert</span>
                <input type="text" class="input" v-model="where.value">
              </div>
              <font-awesome-icon icon="trash" @click="removeNewWhere('a', where)"/>
            </section>
          </section>
          <button class="small primary" @click="addNewWhere('a')">+ Bedingung hinzufügen</button>
        </section>
      </section>
      <!-- Group B Conditions -->
      <section v-if="step === 3" class="flex-column gap8">
        <b>Gruppe B</b>
        <section class="flex-column">
          <span class="label">Bedingungen für Gruppe B</span>
          <select class="input w100" v-model="groupBConditions">
            <option value="allUsersGroupBFromStartDate">Alle Nutzenden ab Startdatum mit Gruppe B</option>
            <option value="custom">Eigene Bedingungen</option>
          </select>
        </section>
        <div></div>
        <b>Felder definieren</b>
        <section class="flex-column gap8" v-if="groupBConditions === 'custom'">
          <section class="flex-column">
            <span class="label">Tabelle auswählen</span>
            <select class="input" v-model="splitTest.groupBTable">
              <option v-for="table in availableTables" :key="table" :value="table">{{ table }}</option>
            </select>
          </section>
          <section>
            <section v-for="(where, index) in splitTest.groupBWheres" :key="index" class="flex-row jst-spc-bt alg-it-c">
              <div class="flex-column w50">
                <span class="label">Feld</span>
                <select v-model="where.field" class="input 100">
                  <option v-for="option in availableColsGroupB" :value="option" :key="option">{{ option }}</option>
                </select>
              </div>
              <div class="flex-column">
                <span class="label">Operator</span>
                <select class="input" v-model="where.operator">
                  <option value="=">=</option>
                  <option value=">">></option>
                  <option value="<">&lt;</option>
                  <option value="<=">&lt;=</option>
                  <option value=">=">>=</option>
                </select>
              </div>
              <div class="flex-column">
                <span class="label">Wert</span>
                <input type="text" class="input" v-model="where.value">
              </div>
              <font-awesome-icon icon="trash" @click="removeNewWhere('b', where)"/>
            </section>
          </section>
          <button class="small primary" @click="addNewWhere('b')">+ Bedingung hinzufügen</button>
        </section>
      </section>

      <!-- Conditions Conversion A -->
      <section v-if="step === 4" class="flex-column gap8">
        <section class="flex-column gap8">
          <b>User referenzieren?</b>
          <label class="flex-row referenceUsers">
            <CheckboxComponent class="flex-row">
              <input type="checkbox" v-model="splitTest.referenceUsers">
            </CheckboxComponent>
            <span>User referenzieren? <tooltip-component>
              Aktiviere diese Checkbox nur, wenn deine Gruppen User beinhalten.<br>
              Ist die Checkbox aktiviert, werden die User-IDs in der Conversion-Tabelle gefiltert.
          </tooltip-component></span>
          </label>
          <div></div>
          <b>Bedingungen für Conversion A</b>
          <section class="flex-column">
            <span class="label">Tabelle auswählen</span>
            <select class="input" v-model="splitTest.conversionATable">
              <option v-for="table in availableTables" :key="table" :value="table">{{ table }}</option>
            </select>
          </section>
          <section v-for="(where, index) in splitTest.conversionAWheres" :key="index" class="flex-row jst-spc-bt alg-it-c">
            <div class="flex-column w50">
              <span class="label">Feld</span>
              <select v-model="where.field" class="input 100">
                <option v-for="option in availableColsConA" :value="option" :key="option">{{ option }}</option>
              </select>
            </div>
            <div class="flex-column">
              <span class="label">Operator</span>
              <select class="input" v-model="where.operator">
                <option value="=">=</option>
                <option value=">">></option>
                <option value="<">&lt;</option>
                <option value="<=">&lt;=</option>
                <option value=">=">>=</option>
              </select>
            </div>
            <div class="flex-column">
              <span cl ass="label">Wert</span>
              <input type="text" class="input" v-model="where.value">
            </div>
            <font-awesome-icon icon="trash" @click="removeNewWhere('aConversion', where)"/>

          </section>
          <button class="small primary" @click="addNewWhere('aConversion')">+ Bedingung hinzufügen</button>
        </section>
      </section>

      <!-- Conditions Conversion B -->
      <section v-if="step === 5">
        <b>Bedingungen für Conversion B</b>
        <section class="flex-column">
          <span class="label">Tabelle auswählen</span>
          <select class="input" v-model="splitTest.conversionBTable">
            <option v-for="table in availableTables" :key="table" :value="table">{{ table }}</option>
          </select>
          <section v-for="(where, index) in splitTest.conversionBWheres" :key="index" class="flex-row jst-spc-bt alg-it-c">
            <div class="flex-column w50">
              <span class="label">Feld</span>
              <select v-model="where.field" class="input 100">
                <option v-for="option in availableColsConB" :value="option" :key="option">{{ option }}</option>
              </select>
            </div>
            <div class="flex-column">
              <span class="label">Operator</span>
              <select class="input" v-model="where.operator">
                <option value="=">=</option>
                <option value=">">></option>
                <option value="<">&lt;</option>
                <option value="<=">&lt;=</option>
                <option value=">=">>=</option>
              </select>
            </div>
            <div class="flex-column">
              <span class="label">Wert</span>
              <input type="text" class="input" v-model="where.value">
            </div>
            <font-awesome-icon icon="trash" @click="removeNewWhere('bConversion', where)"/>
          </section>
          <button class="small primary" @click="addNewWhere('bConversion')">+ Bedingung hinzufügen</button>
        </section>
      </section>
    </template>
    <template v-slot:footer>
      <button v-if="step ===1">Abbrechen</button>
      <button v-else @click="step--">Zurück</button>
      <button class="primary" v-if="step !== 5" @click="step++">Weiter</button>
      <button class="primary" v-else @click="saveSplitTest">Speichern</button>
    </template>
  </ModalModule>
</template>

<script>
import ModalModule from '../ModalModule';
import CheckboxComponent from "@/components/CheckboxComponent.vue";
import axios from '@/axios'
import TooltipComponent from "@/components/TooltipComponent.vue";

export default {
  name: 'SplitTestModal',
  components: {
    TooltipComponent,
    CheckboxComponent,
    ModalModule,
  },
  props: {
    splitTestId: {
      type: [Number, Boolean, String],
      required: false,
      default: false
    },
  },
  data() {
    return {
      availableTables: ['users', 'meta_activities'],
      groupAConditions: 'custom',
      groupBConditions: 'custom',
      availableColsGroupA: [],
      availableColsGroupB: [],
      availableColsConA: [],
      availableColsConB: [],

      splitTest: {
        start: new Date().toISOString().slice(0, 16),
        name: '',
        groupATable: '',
        groupAWheres: [
          {field: '', operator: '=', value: ''},
        ],
        groupBTable: '',
        groupBWheres: [
          {field: '', operator: '=', value: ''},
        ],
        conversionATable: '',
        conversionAWheres: [
          {field: '', operator: '=', value: ''},
        ],
        conversionBTable: null,
        conversionBWheres: [
          {field: '', operator: '=', value: ''},
        ],
        referenceUsers: false
      },
      step: 1,
    };
  },
  computed: {
    isEditModal() {
      return !(this.splitTestId === '' || this.splitTestId === false);
    },
    groupATable() {
      return this.splitTest.groupATable;
    },
    groupBTable() {
      return this.splitTest.groupBTable;
    },
    conversionATable() {
      return this.splitTest.conversionATable;
    },
    conversionBTable() {
      return this.splitTest.conversionBTable;
    },
  },
  mounted() {
    this.getTables()
    if (this.isEditModal) {
      this.getTest();
    }
    this.splitTest.groupATable = 'users'
    this.splitTest.groupBTable = 'users'
    this.splitTest.conversionATable = 'meta_activities'
  },
  methods: {
    getTest() {
      axios.get('admin/split/' + this.splitTestId).then(res => {
        this.splitTest.name = res.data.name;
        this.splitTest.start = res.data.start;
        this.splitTest.end = res.data.end;
        this.splitTest.groupATable = res.data.groupATable;
        this.splitTest.groupBTable = res.data.groupBTable;
        this.splitTest.conversionATable = res.data.conversionTableA;
        this.splitTest.conversionBTable = res.data.conversionTableB;
        this.splitTest.groupAWheres = this.queryToWheres(res.data.groupAWheres)
        this.splitTest.groupBWheres = this.queryToWheres(res.data.groupBWheres)
        this.splitTest.conversionAWheres = this.queryToWheres(res.data.conversionWheresA)
        this.splitTest.conversionBWheres = this.queryToWheres(res.data.conversionWheresB)
      })
    },
    getCols(table) {
      return axios.get('admin/split/tables/' + table + '/columns')
    },
    getTables() {
      axios.get('admin/split/tables').then(res => {
        this.availableTables = res.data;
      })
    },
    addNewWhere(group) {
      let newWhere = {field: '', operator: '=', value: ''};
      if (group === 'b') {
        this.splitTest.groupBWheres.push(newWhere);
      } else if (group === 'a') {
        this.splitTest.groupAWheres.push(newWhere)
      } else if (group === 'aConversion') {
        this.splitTest.conversionAWheres.push(newWhere)
      } else if (group === 'bConversion') {
        this.splitTest.conversionBWheres.push(newWhere)
      }
    },
    removeNewWhere(group, index) {
      if (group === 'b') {
        this.splitTest.groupBWheres.splice(index, 1);
      } else if (group === 'a') {
        this.splitTest.groupAWheres.splice(index, 1)
      } else if (group === 'aConversion') {
        this.splitTest.conversionAWheres.splice(index, 1)
      } else if (group === 'bConversion') {
        this.splitTest.conversionBWheres.splice(index, 1)
      }
    },
    saveSplitTest() {
      let splitTest = {
        name: this.splitTest.name,
        start: this.splitTest.start,
        groupATable: this.splitTest.groupATable,
        groupAWheres: this.wheresToQuery(this.splitTest.groupAWheres),
        groupBTable: this.splitTest.groupBTable,
        groupBWheres: this.wheresToQuery(this.splitTest.groupBWheres),
        conversionATable: this.splitTest.conversionATable,
        conversionAWheres: this.wheresToQuery(this.splitTest.conversionAWheres, this.splitTest.referenceUsers),
        conversionBTable: this.splitTest.conversionBTable,
        conversionBWheres: this.wheresToQuery(this.splitTest.conversionBWheres, this.splitTest.referenceUsers),
      }
      let url = this.isEditModal ? 'admin/split/'+this.splitTestId : 'admin/split'
      axios.post(url, splitTest).then(() => {
        if (this.isEditModal) {
          this.$toast.success('Test erfolgreich gespeichert');
        } else {
          this.$toast.success('Test erfolgreich erstellt');
        }
        this.$emit('success')
        this.$emit('close');
      }).catch(e => {
        this.$toast.success(e.response.data.message);
        this.$emit('close');
      })
    },
    wheresToQuery(wheres, referenceUsers = false) {
      let formatted = {};
      wheres.forEach(where => {
        if (where.field === '' || where.value === '') {
          return;
        }
        if (where.operator === '=') {
          formatted[where.field] = where.value;
        } else {
          formatted[where.field] = {
            value: where.value,
            operator: where.operator
          }
        }
      })
      if (referenceUsers) {
        formatted.referenceUsers = true;
      }
      return formatted;
    },
    queryToWheres(query) {
      query = JSON.parse(query);
      let keys = Object.keys(query)
      let rows = [];
      keys.forEach(key => {
        if (key === 'referenceUsers') {
          if (query[key] === true) {
            this.splitTest.referenceUsers = true;
          }
          return;
        }
        let row = {field: key};
        if (typeof query[key] === 'object' && query[key] !== null) {
          row.operator = query[key].operator
          row.value = query[key].value
        } else {
          row.operator = '='
          row.value = query[key]
        }
        rows.push(row)
      })
      return rows;
    }

  },
  watch: {
    groupAConditions(newVal) {
      if (newVal === 'allUsersGroupAFromStartDate') {
        this.splitTest.groupATable = 'users'
        this.splitTest.groupAWheres = [
          {field: 'group', operator: '=', value: 'a'},
          {field: 'created_at', operator: '>=', value: '$startDate'}
        ]
      }
    },
    groupBConditions(newVal) {
      if (newVal === 'allUsersGroupBFromStartDate') {
        this.splitTest.groupBTable = 'users'
        this.splitTest.groupBWheres = [
          {field: 'group', operator: '=', value: 'b'},
          {field: 'created_at', operator: '>=', value: '$startDate'}
        ]
      }
    },
    groupATable() {
      this.getCols(this.splitTest.groupATable).then(res => {
        this.availableColsGroupA = res.data
      })
    },
    groupBTable() {
      this.getCols(this.splitTest.groupBTable).then(res => {
        this.availableColsGroupB = res.data;
      })
    },
    conversionATable() {
      this.getCols(this.splitTest.conversionATable).then(res => {
        this.availableColsConA = res.data;
      })
    },
    conversionBTable() {
      this.getCols(this.splitTest.conversionBTable).then(res => {
        this.availableColsConB = res.data;
      })
    },

  }
};
</script>

<style scoped lang="scss">
.noProjectsModal {
  ::v-deep .modal-wrapper {
    .modal-container {
      min-height: fit-content;
    }
  }
}

::v-deep .modal-wrapper {
  max-width: 500px;
  width: 100%;


  .modal-body {
    padding-bottom: 0;
  }

  .modal-footer {
    padding-bottom: 24px;
  }
}

label {
  display: flex;
  flex-direction: column;
}
.referenceUsers {
  display: flex;
  flex-direction: row;
}

.flex-row {
  gap: 24px;
}

.project {
  width: 50%;
  max-width: 208px;
}

.hourlyRate {
  width: 25%;
}

.date {
  width: 30%;
}

.number {
  max-width: 15%;
}

.saveButtons {
  gap: 8px;
}

#steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 18px;

  .number {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #6556eb;
    color: #6556eb;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    font-weight: 500;
    transition: all 0.3s;

    &.active {
      color: white;
      background-color: #6556eb;
    }
  }

  .between {
    width: 15%;
    height: 2px;
    background-color: #6556eb;
  }
}
</style>
