<template>
  <div id="nav" v-if="userIsLoggedIn" class="flex-column alg-it-c jst-spc-bt">
    <section class="flex-column w100">
      <div id="navWrapper">
        <h2 class="logo-desktop">MILKEE</h2>
        <h2 class="logo-mobile">M</h2>
      </div>
      <section class="menu flex-column">
        <section v-for="(topic, index) in navigationStructure.firstLevel" :key="index" class="flex-column">
          <span class="nav-topic grayText" v-if="topic.topic">{{ topic.topic }}</span>
          <span class="nav-topic-divider-mobile grayText" />

          <router-link
            v-for="(link, index) in topic.links"
            :key="index"
            :to="link.path"
            class="flex-column topMenuWrapper"
            :class="{ first: link.path === '/' }">
            <section class="topMenu flex-row alg-it-c jst-spc-bt">
              <section>
                <font-awesome-icon :icon="link.icon" />
                <span class="menu-link-name">{{ link.name }}</span>
              </section>
              <section
                class="subMenuIndicator"
                v-if="Object.prototype.hasOwnProperty.call(link, 'subMenu') && link.subMenu.length">
                <font-awesome-icon icon="chevron-down" />
              </section>
            </section>
            <section
              class="subMenu flex-column jst-c"
              :class="{ active: isActive(link) }"
              v-if="Object.prototype.hasOwnProperty.call(link, 'subMenu') && link.subMenu.length">
              <router-link v-for="(subLink, index) in link.subMenu" :key="index" :to="subLink.path">
                <font-awesome-icon :icon="subLink.icon" />
                <span class="menu-link-name">
                  {{ subLink.name }}
                </span>
              </router-link>
            </section>
          </router-link>
        </section>
      </section>
    </section>
    <section class="menu flex-column w100 footer">
      <section class="flex-column alg-it-s w100">
        <span class="nav-topic grayText">Einstellungen</span>
        <router-link to="/profile" class="flex-column w100">
          <section class="topMenu flex-row alg-it-c w100">
            <font-awesome-icon icon="cog" />
            <span class="menu-link-name">Einstellungen</span>
          </section>
        </router-link>
        <a href="https://support.milkee.ch" target="_blank" class="flex-column w100">
          <section class="topMenu flex-row alg-it-c w100" @click="doLog('help_click', $route.path)">
            <font-awesome-icon icon="question-circle" />
            <span class="menu-link-name">Hilfe</span>
          </section>
        </a>
        <div class="flex-column w100 pointer" @click="$store.dispatch('auth/logout')">
          <section class="topMenu flex-row alg-it-c w100">
            <font-awesome-icon icon="sign-out-alt" />
            <span class="menu-link-name">Ausloggen</span>
          </section>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import { log } from '../mixins/log';

export default {
  name: 'NavigationModule',
  mixins: [log],
  data() {
    return {
      hide: true,
      hideContextMenu: true,
      navigationStructure: {
        firstLevel: [],
        secondLevel: [],
      },
      showMore: false,
    };
  },
  computed: {
    userIsLoggedIn() {
      return this.$store.getters['auth/loggedIn'];
    },
  },
  mounted() {
    this.getNavigationStructure();
  },
  methods: {
    getNavigationStructure() {
      let firstLevel = [];
      firstLevel.push({
        topic: null,
        links: [{ path: '/', name: 'Dashboard', icon: 'home' }],
      });

      firstLevel.push({
        topic: '',
        links: [
          {
            path: '/users/free',
            name: 'Nutzende',
            icon: 'users',
            subMenu: [
              { path: '/users/free', name: 'Aktive Trials', icon: 'users' },
              { path: '/users/paid', name: 'Zahlende User', icon: 'users' },
              { path: '/users/inactive', name: 'Inaktive User', icon: 'users' },
            ],
          },
        ],
      });

      firstLevel.push({
        topic: 'Sonstiges',
        links: [
          {
            path: '/splittest',
            name: 'Splittests',
            icon: 'vial',
          },
        ],
      });

      this.navigationStructure = {
        firstLevel: firstLevel,
      };
    },
    isActive(link) {
      if (link.path === this.$route.path) {
        return true;
      }
      if (link.subMenu) {
        return link.subMenu.some(subLink => subLink.path === this.$route.path);
      }
      return false;
    },
  },
  watch: {
    userIsLoggedIn: {
      handler: function () {
        this.getNavigationStructure();
      },
    },
  },
};
</script>

<style scoped lang="scss">
#nav {
  background-color: white;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  bottom: 0;
  z-index: 10;
  height: 100vh;
  border-right: 1px solid #e8e8e8;
  box-sizing: border-box;
  min-width: 305px;
  padding-left: 30px;
  padding-right: 30px;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  #navWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.95rem;
    padding: 4px 0;
    min-height: 38px;
    margin-top: 18px;
    margin-bottom: 24px;
  }

  .tooltip {
    pointer-events: none;
    z-index: 99999;
    transition: opacity 0.3s;
    position: fixed;
    opacity: 0;
    left: 90px;
    background-color: rgb(218, 216, 246);
    padding: 4px 12px;
    border-radius: 4px;
    box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.24);

    span {
      white-space: nowrap;
    }

    &:before {
      content: '';
      background-color: rgb(218, 216, 246);
      width: 8px;
      height: 8px;
      position: absolute;
      left: -4px;
      top: 11px;
      transform: rotate(45deg);
      box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.24);
    }
  }

  .subMenuIndicator {
    display: block;
  }

  .menu {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    max-width: 1280px;
    min-height: 42px;

    .menu-link-name {
      display: inline;
    }

    .nav-topic {
      font-size: 14px;
      margin-bottom: 8px;
      margin-top: 24px;
      display: block;
    }

    .nav-topic-divider-mobile:first-of-type {
      display: none;
    }

    .nav-topic-divider-mobile {
      display: none;
      margin-bottom: 6px;
      border-bottom: 1px solid #8f919667;
      width: 100%;
    }
  }

  .logo-desktop {
    display: block;
  }

  .logo-mobile {
    display: none;
    font-size: 32px;
  }

  .router-link-exact-active > .topMenu {
    color: $violet;
    background-color: $lightViolet;
  }

  a:not(.first:first-of-type).router-link-active > .topMenu {
    color: $violet;
    background-color: $lightViolet;
  }

  a:hover {
    background-size: 0;
  }

  .topMenu:hover {
    color: $darkViolet;
    background-color: $lightViolet;
  }

  .topMenu {
    position: relative;
    color: $black;
    text-decoration: none;
    padding: 4px 0;
    margin-bottom: 6px;
    font-weight: 500;
    font-size: 0.89em;
    border-radius: 4px;

    & svg {
      font-size: 0.7rem;
      width: 12px;
      margin-right: 12px;
      padding-left: 8px;
    }
  }

  .active {
    max-height: 500px !important;
    transition: max-height 0.4s ease-in-out, margin-bottom 0.4s ease-in-out;
    margin-bottom: 6px !important;
  }

  .subMenu {
    margin-left: 32px;
    border-left: 1px solid #e8e8e8;
    transform-origin: top;
    transition: max-height 0.4s ease-in-out, margin-bottom 0.4s ease-in-out;
    overflow: hidden;
    max-height: 0;
    margin-bottom: 0px;

    & .router-link-active {
      color: $violet;
    }

    & > a {
      background: transparent;
      font-size: 0.84rem;
      color: $black;
      margin-left: 8px;
      padding: 6px 0;

      &:hover {
        color: $violet;
      }

      & svg {
        display: none;
      }
    }
  }
}

.footer {
  margin-top: 24px;
  margin-bottom: 24px;
}

@media screen and (max-width: 1625px) {
  #nav {
    min-height: 60px;
    padding: 0 16px;
    max-width: 80px;
    min-width: 80px;
    width: 80px;
    transition: all 0.2s;

    &:hover {
      max-width: 300px;
      width: 300px;
      transition: all 0.2s;

      .menu {
        z-index: 100;
        flex-direction: column;

        .menu-link-name {
          transition-delay: 5s;
          transition: all 0.2s;
          opacity: 1;
          z-index: 1;
        }
      }

      .topMenu {
        justify-content: flex-start;

        & svg {
          font-size: 0.7rem;
          width: 12px;
          margin-right: 12px;
          padding-left: 8px;
        }
      }

      .subMenu {
        margin-left: 16px !important;

        & svg {
          font-size: 0.7rem;
          width: 12px;
          margin-right: 12px;
          padding-left: 8px;
        }
      }
    }

    #navWrapper {
      justify-content: center;
    }

    &.noOverlay {
      flex-direction: column;
      margin-top: 32px;
    }

    .topMenu {
      justify-content: flex-start;

      section {
        display: flex;
        align-items: center;
      }

      svg {
        margin: 8px;
        width: 16px;
        height: 16px;
        padding: 0px 0px 0px 8px;
        transition: all 0.2s;
      }
    }

    .active {
      svg {
        display: inline !important;
      }
    }

    .subMenu {
      border: none;
      color: $black;
      text-decoration: none;
      justify-content: flex-start;
      padding: 0;
      transition: 0.14s all;
      font-weight: 500;
      font-size: 0.84em;
      border-radius: 4px;
      margin-left: 0 !important;
      background-color: #dadada;

      & .tooltip {
        left: 90px;
        margin-top: -8px;
        font-size: 0.89rem;
        color: #4d36e3;
      }

      & a {
        display: flex;
        margin-left: 0;

        & svg {
          display: none;
          font-size: 0.7rem;
          width: 16px;
          height: 16px;
          margin-top: 2px;
          padding: 0px 0px 0px 16px;
          margin-right: 8px;
          transition: all 0.2s;
        }
      }

      &:hover {
        color: $darkViolet;
        background-color: $lightViolet;
      }
    }

    .logo-mobile {
      display: block;
    }

    .logo-desktop {
      display: none;
    }

    .topMenu:hover .tooltip {
      opacity: 1;
    }

    .subMenu:hover .tooltip {
      opacity: 1;
    }

    .menu {
      z-index: 100;
      flex-direction: column;

      .menu-link-name {
        opacity: 0;
        z-index: -1000;
        overflow: hidden;
        white-space: nowrap;
      }

      .nav-topic {
        display: none;
      }

      .subMenuIndicator {
        display: none;
      }

      .nav-topic-divider-mobile {
        display: block;
        width: 100%;
      }
    }
  }
}

@media screen and (max-height: 740px) {
  .tooltip {
    display: none;
  }
}
</style>
