<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="backdrop" @click="$emit('close')"></div>
      <div class="modal-wrapper">
        <div class="modal-container">

          <div class="modal-header">
            <slot name="header">
              default header
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              default body
            </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { Escapable } from "../mixins/escapable";

export default {
  name: "ModalModule",
  mixins: [Escapable],
  data() {
    return {
    }
  },
  methods: {
    escapeHandler() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
.modal-mask {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(93, 88, 88, 0.3);
}

.modal-wrapper {
  position: fixed;
  z-index: 100000;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: left;
  max-width: 500px;
  max-height: 100vh;
}

.modal-container {
  min-width: 500px;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 20px;
  transition: all 0.3s ease;
  z-index: 10000;
  position: relative;
}
.modal-header {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-top: 0;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  padding: 30px 30px 0 30px;
  box-sizing: border-box;
}
.modal-header {
  margin-top: 0;
  font-weight: 600;
  color: black;
  font-size: 1.2em;
}
.modal-body {
  margin: 0;
  padding: 20px 30px;
}

.modal-default-button {
  cursor: pointer;
  padding: 10px 15px;
  border: none;
  border-radius: 3px;
}

.modal-default-button:hover {
  background-color: rgba(75, 150, 75, 0.25);
}

.modal-footer {
  padding: 20px 30px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.file {
  width: 100vw;
  height: 50vh;
  max-width: 100%;
  max-height: 100%;
  .modal-wrapper{
    max-width: 100%;
  }
  .modal-header {
    display: none;
  }
  .modal-body {
    padding: 0;
    height: 100%;
    width: 100%;
  }
  .modal-footer {
    display: none;
  }
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.05s ease;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}

.cancel {
  font-weight: bold;
  background-color: transparent;
  border: none;
  padding: 0;
}
.cancel:hover {
  cursor: pointer;
  text-decoration: underline;
}

</style>
