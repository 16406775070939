<template>
  <div class="flex-column gap40 profileSettingsModule jst-spc-bt">
    <white-box>
      <h3>Mein Profil</h3>
      <label class="flex-column">
        <span class="label">Dein Name</span>
        <input class="input" type="text" v-model="user.name"/>
      </label>
      <label class="flex-column">
        <span class="label">E-Mail</span>
        <input class="input" type="text" disabled v-model="user.email"/>
      </label>
      <section class="flex-row jst-fe">
        <button class="small primary" @click="saveUser">Speichern</button>
      </section>
    </white-box>
    <white-box class="profileSet" v-if="user">
      <h3>Aktualisiere dein Passwort</h3>
      <section>
        <label class="label">Altes Passwort</label>
        <input type="password" class="input" v-model="oldPass" />
      </section>
      <section>
        <label class="label">Neues Passwort</label>
        <input type="password" class="input" v-model="newPass" />
      </section>
      <section>
        <label class="label">Neues Passwort wiederholen</label>
        <input type="password" class="input" v-model="newRep" />
      </section>
      <section class="submits flex-row jst-spc-bt">
        <span></span>
        <button class="small primary" @click="resetPass()">Passwort aktualisieren</button>
      </section>
    </white-box>
  </div>
</template>

<script>
import WhiteBox from '../components/WhiteBox';
import axios from '@/axios';

export default {
  name: 'ProfileSettingsModule',
  components: {WhiteBox},
  data() {
    return {
      logoURL: null,
      newLogo: null,
      logoLoading: false,
      user: null,
      oldPass: null,
      newPass: null,
      newRep: null,
    };
  },
  computed: {
    userComputed() {
      return this.$store.getters['auth/user'].user;
    },
  },
  created() {
    this.getLogoUrl();
    this.user = {...this.userComputed};
    this.formatIban();
  },
  methods: {
    getLogoUrl() {
      axios.get('auth/logoUrl').then(res => {
        this.logoURL = res.data.url;
      });
    },
    uploadNewLogo() {
      this.logoLoading = true;
      const formData = new FormData();
      formData.append('logo', this.newLogo);
      axios
          .post('auth/logo', formData)
          .then(res => {
            this.logoLoading = false;
            this.logoURL = res.data.url;
            this.newLogo = null;
            this.$toast.success('Logo wurde aktualisiert.');
          })
          .catch(e => {
            this.logoLoading = false;
            this.newLogo = null;
            this.$toast.error(e.response.data.message);
          });
    },
    saveUser() {
      let user = {...this.user};
      if (user.iban != null) {
        user.iban = user.iban.replaceAll(' ', '');
      } else {
        delete user.iban;
      }
      axios
          .post('auth/update', user)
          .then(res => {
            this.$store.dispatch('auth/updateUser', res.data);
            this.formatIban();
            this.$toast.success('Deine Daten wurden aktualisiert.');
          })
          .catch(e => {
            this.user = {...this.userComputed};
            this.$toast.error(e.response.data.message);
          });
    },
    formatIban() {
      if (Object.prototype.hasOwnProperty.call(this.user, 'iban') && this.user.iban != null) {
        let iban = this.user.iban.replaceAll(' ', '');
        iban = [...iban];
        this.user.iban = iban
            .map((d, i) => (i % 4 == 0 ? ' ' + d : d))
            .join('')
            .trim();
      }
    },
    resetPass() {
      if (this.newPass === this.newRep) {
        axios
            .post('/auth/update', {
              oldPassword: this.oldPass,
              newPassword: this.newPass,
            })
            .then(res => {
              this.user = res.data;
              this.$toast.success('Dein Passwort wurde aktualisiert.');
            })
            .catch(e => {
              this.$store.dispatch('notices/addNotice', {
                type: 'error',
                notice: e.response.data.message,
              });
            });
      } else {
        this.$toast.error('Passwörter stimmen nicht überein.');
      }
    },
  },
  watch: {
    newLogo: {
      deep: true,
      handler(newLogo) {
        if (newLogo != null) {
          this.uploadNewLogo();
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
h3 {
  margin-bottom: 12px;
}

.input {
  max-width: 400px;
  width: 100%;
}

.logo {
  max-height: 100px;

  img {
    height: 100%;
    max-height: 100px;
  }
}
section.checkbox {
  margin-bottom: 12px;
}
</style>
