<template>
  <div class="headerTitle flex-row alg-it-c jst-spc-bt modal" :class="{noMargin: noMargin}" v-if="modal">
    <h3>
      {{ title }}</h3> <span @click="$emit('close')" class="link">Abbrechen</span></div>
  <div class="headerTitle flex-row alg-it-c jst-c nonModal" :class="{noMargin: noMargin}" v-else><h3>{{ title }}</h3>
  </div>
</template>

<script>
export default {
  name: "HeaderTitleMobile",
  props: {
    title: {
      type: String
    },
    modal: {
      type: Boolean,
      default: false
    },
    noMargin: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style scoped lang="scss">
.headerTitle {
  width: 100%;
  max-height: 50px;
  height: 50px;
  min-height: 45px;
  background: white;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 18px;
  padding: 0 20px;
  box-sizing: border-box;

  &.nonModal {
    position: fixed;
    top: 0;
    z-index: 1;
  }
}

.link {
  background-size: 100% .1em;
}

.noMargin {
  margin-bottom: 0;
}

.modal {
  z-index: 10000;
}
</style>
