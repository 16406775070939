import SplitTestView from './SplitTestView.vue';
import SplitTestDetailView from './SplitTestDetailView.vue';

const routes = [
  {
    path: '/splittest',
    component: SplitTestView,
    components: {
      desktop: SplitTestView,
      mobile: SplitTestView,
    },
    meta: {
      title: 'Splittests',
    },
  },
  {
    path: '/splittest/:id',
    component: SplitTestDetailView,
    components: {
      desktop: SplitTestDetailView,
      mobile: SplitTestDetailView,
    },
    meta: {
      title: 'Splittests',
    },
  },
];
export default routes;
