import axios from 'axios'

const fetchClient = () => {
    const defaultOptions = {
        baseURL: process.env.VUE_APP_BASE_URL,
        headers: {
            'Content-Type': 'application/json',
        },
    };

    // Create instance
    let instance = axios.create(defaultOptions);

    // Set the AUTH token for any request
    instance.interceptors.request.use(function (config) {
        if (!config.url.startsWith('http')) {
            const token = JSON.parse(localStorage.getItem('user')).access_token;
            config.headers.Authorization = token ? `Bearer ${token}` : '';
        }
        return config;
    });

    return instance;
};

export default fetchClient();
