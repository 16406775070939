export const notices = {
    namespaced: true,
    state: {
        notices: []
    },
    mutations: {
        addNotice(state, notice){
            state.notices.push(notice);
        },
        removeNotice(state, message) {
            state.notices.forEach(function (el, index) {
                if (el.notice === message) {
                    state.notices.splice(index, 1)
                }
            })
        }
    },
    actions: {
        addNotice(context, notice) {
            context.commit('addNotice', notice)
            setTimeout(() => {
                context.commit('removeNotice', notice.notice)
            }, 3000)
        }
    }
}
