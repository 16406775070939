import Login from './LoginView';
import Profile from './ProfileView';
import Reset from './ResetView';
import DoReset from './DoResetView';
import Verify from './VerifyView';

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    components: {
      desktop: Login,
      mobile: Login,
    },
    meta: {
      title: 'Login',
    },
  },
  {
    path: '/profile/:setting?',
    name: 'Profile',
    components: {
      desktop: Profile,
    },
    meta: {
      title: 'Profil',
    },
  },
  {
    path: '/reset',
    name: 'Reset',
    components: {
      desktop: Reset,
      mobile: Reset,
    },
    meta: {
      title: 'Passwort zurücksetzen',
    },
  },
  {
    path: '/reset/:id/:code',
    component: DoReset,
    components: {
      desktop: DoReset,
      mobile: DoReset,
    },
    meta: {
      title: 'Passwort zurücksetzen',
    },
  },
  {
    path: '/verify/:id/:code',
    name: 'Verification',
    component: Verify,
    components: {
      desktop: Verify,
      mobile: Verify,
    },
    meta: {
      title: 'E-Mail verifizieren',
    },
  },
];
export default routes;
