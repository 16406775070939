<template>
  <div id="doResetView">
    <div id="signUpContainer">
      <div class="wrapper" id="signUpWrapper">
        <h1>Passwort zurücksetzen</h1>
        <div class="message failed" v-if="failed">{{ failedMessage }}</div>
        <div class="message success" v-if="successMessage">{{ successMessage }}</div>
        <form @submit.prevent="handleReset" v-if="!successful">
          <input name="email" class="input" type="password" placeholder="Neues Passwort" v-model="pass" required
                 v-focus>
          <input name="email" class="input" type="password" placeholder="Passwort wiederholen" v-model="repPass"
                 required>
          <button class="primary" :disabled="submitted">Passwort zurücksetzen</button>
          <div class="loginLinks"><a href="/login">Log dich ein!</a></div>
        </form>
        <div class="loginLinks">Du hast noch keinen Account? <a href="/register">Registrier dich!</a></div>
      </div>
    </div>
    <div id="imageContainer">

    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'DoResetView',
  data() {
    return {
      pass: null,
      repPass: null,
      loading: false,
      message: '',
      submitted: false,
      successful: false,
      failed: true,
      failedMessage: '',
      successMessage: '',
      userId: this.$route.params.id,
      token: this.$route.params.code
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/profile');
    }
  },
  mounted() {
    this.$gtag.pageview({page_path: '/doPasswordReset'})
  },
  methods: {
    handleReset() {
      this.loading = true;
      if (this.pass !== this.repPass) {
        this.failedMessage = 'Passwörter stimmen nicht überein.'
      }
      axios.post(process.env.VUE_APP_BASE_URL + '/auth/reset', {password: this.pass, userId: this.userId, token: this.token}).then(() => {
        this.loading = false;
        this.failedMessage = ''
        this.successMessage = 'Dein Passwort wurde zurückgesetzt.'
        this.$router.push({path: '/login'})
      }).catch(() => {
        this.loading = false;
        this.submitted = false;
        this.successMessage = ''
        this.failedMessage = 'Da ging was schief! Starte den Prozess erneut.'
      });
    }
  }
};

</script>

<style scoped lang="scss">
section#content {
  margin-left: 0;
  margin-right: 0;
  padding-top: 0 !important;
}

#doResetView {
  margin-top: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  #signUpContainer {
    width: 35%;
    display: flex;
    justify-content: center;

    .wrapper {
      width: 80%;
      position: relative;

      h1 {
        margin-bottom: 48px;
      }

      .input {
        width: 100%;
        margin-bottom: 32px;
      }

      .buttonPrimary {
        margin-bottom: 48px;
      }

      .loginLinks {
        margin-bottom: 10px;
      }

      .message {
        position: relative;
        margin-bottom: 32px;
      }

      .success {
        color: green;
      }

      .failed {
        color: red;
      }

      .inputHalf {
        display: flex;
        justify-content: space-between;

        input {
          flex-basis: 49%;
        }
      }
    }
  }

  .recaptcha {
    margin-bottom: 32px;
  }

  #imageContainer {
    width: 65%;
    background-image: url('https://images.pexels.com/photos/8872597/pexels-photo-8872597.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260');
    background-position: center center;
    background-size: cover;
    height: 100vh;
  }

  input {
    display: block;
  }
}

button {
  margin-bottom: 24px;
}

@media (max-width: 1280px) {
  #doResetView {
    #signUpContainer {
      width: 55%;
    }

    #imageContainer {
      width: 45%;
    }
  }
}

@media (max-width: 980px) {
  #doResetView {
    flex-direction: column-reverse;
    justify-content: flex-end;

    #imageContainer {
      width: 100%;
      max-height: 25%;
    }

    #signUpContainer {
      width: 100%;
      margin-top: 48px;

      #signUpWrapper {
        h1 {
          margin-bottom: 48px;
        }
      }
    }

    .loginLinks a {
      display: block;
    }

    .inputHalf {
      flex-direction: column;
    }
  }
}
</style>
