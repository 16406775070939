export const loadingModule = {
    state: {
        loadingSteps: {}
    },
    mutations: {
        setLoadingSteps(state, payload){
            state.loadingSteps = payload;
        },
        updateLoadingStepByKey(state, payload){
            if (Object.prototype.hasOwnProperty.call(state.loadingSteps, payload.key)){
                state.loadingSteps[payload.key] = payload.status;
            }
        }
    },
    getters: {
        loadingIsFinished: function (state) {
            for (const [, step] of Object.entries(state.loadingSteps)) {
                if (step === false) {
                    return false;
                }
            }
            return true;
        }
    },
}
