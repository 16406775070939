<template>
  <div class="adminDataUsageView flex-column" v-if="isAdmin">
    <h1>Datennutzung</h1>
    <FiltersModule :filters="filters" @updateFilters="getDataUsage" />
    <div class="flex-row">
      <white-box class="tag">
        {{ totalUsage }} MB
        <span>outbound traffic</span>
      </white-box>
      <white-box class="tag">
        {{ Math.round(outboundFree / 1000000) }} TB
        <span>gratis / monat</span>
      </white-box>
      <white-box class="tag">
        {{ usageInPercentage }} %
        <span>verwendet</span>
      </white-box>
    </div>
    <div class="flex-row">
      <white-box>
        <h3>Nutzung nach Tag</h3>
        <VueApexCharts
          type="area"
          height="250"
          :options="lineChartOptions.options"
          :series="lineChartOptions.series"
          ref="lineChart" />
      </white-box>
    </div>
    <TableModule :table-data="tableData" primarySortDefault="usage" secondary-sort-default="email" />
  </div>
</template>

<script>
import axios from '@/axios';
import WhiteBox from '@/components/WhiteBox';
import TableModule from '@/modules/TableModule';
import FiltersModule from '@/modules/FiltersModule';

export default {
  name: 'AdminDataUsageView',
  components: { FiltersModule, TableModule, WhiteBox },
  data() {
    return {
      totalUsage: 0,
      outboundFree: 20000000,
      pricePerGBOutbound: 0.02,
      lineChartOptions: {
        options: {
          colors: ['rgb(162,153,234)'],
          chart: {
            toolbar: {
              show: false,
            },
            type: 'area',
            zoom: {
              enabled: false,
            },
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left',
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: true,
            width: 4,
            colors: ['#6556eb'],
          },
          noData: {
            text: 'Lädt...',
          },
          xaxis: {
            type: 'category',
            tickAmount: 4,
            labels: {
              rotate: 0,
            },
          },
        },
        series: [
          {
            name: 'Nutzung in MB',
            data: [],
          },
        ],
      },
      tableData: {
        head: [
          { value: 'email', label: 'E-Mail', sortable: false, style: { width: '80%' } },
          { value: 'usage', label: 'Datennutzung', sortable: true },
        ],
        body: [],
      },
      filters: {
        timeRange: {
          title: 'Zeitraum',
          type: 'date',
          default: 'all',
          icon: 'clock',
          addDefaultToRoute: true,
          selected: this.$route.query.timeRange ? this.$route.query.timeRange : 'currentMonth',
          options: [
            { label: 'Gesamter Zeitraum', value: 'all' },
            { label: 'Letzte 30 Tage', value: '30days' },
            { label: 'Aktueller Monat', value: 'currentMonth' },
            { label: 'Aktuelles Jahr', value: 'currentYear' },
            { label: 'Letzer Monat', value: 'lastMonth' },
            { label: 'Letztes Jahr', value: 'lastYear' },
            { label: 'Eigener Zeitraum', value: 'custom' },
          ],
          endDate: this.$route.query.endDate ? this.$route.query.endDate : new Date().toISOString().slice(0, 10),
          startDate: this.$route.query.startDate ? this.$route.query.startDate : new Date().toISOString().slice(0, 10),
        },
      },
    };
  },
  mounted() {
    this.getDataUsage();
  },
  computed: {
    usageInPercentage() {
      let num = (this.totalUsage / this.outboundFree) * 100;
      let roundNum = 100;
      if (num < 0.001) {
        roundNum = 10000;
      } else if (num < 0.01) {
        roundNum = 1000;
      }
      return Math.round(num * roundNum) / roundNum;
    },
    isAdmin: function () {
      return this.$store.getters['auth/user'].user.email === 'nicolas@haemmerli.io';
    },
  },
  methods: {
    getActiveFilterQuery() {
      let params = { ...this.$route.query };

      if (!Object.prototype.hasOwnProperty.call(params, 'timeRange')) {
        params.timeRange = this.filters.timeRange.default;
      }
      return params;
    },
    getDataUsage() {
      let params = this.getActiveFilterQuery();
      axios.get('admin/dataUsage', { params: params }).then(res => {
        this.totalUsage = res.data.totalUsage;
        let data = res.data;

        // Update line chart.
        let days = [];
        data.byDay.forEach(day => {
          days.push({ x: day.day, y: day.bytesInMB });
        });

        this.$refs.lineChart.updateOptions({
          series: [
            {
              name: 'Nutzung in MB',
              data: days,
            },
          ],
        });

        // Update table.
        let body = [];
        data.byUser.forEach(user => {
          body.push({
            email: { value: user.email },
            usage: { value: user.readableUsage, sortable: parseFloat(user.bytes) },
          });
        });
        this.tableData.body = body;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.adminDataUsageView {
  gap: 24px;

  .flex-row {
    gap: 24px;
  }
}
</style>
