<template>
  <div>
    <div class="headline flex-row jst-spc-bt alg-it-c">
      <h1>A/B Tests</h1>
      <button class="primary" @click="showModal=true">+ Splittest hinzufügen</button>
    </div>
    <div>
      <SubMenuComponent :options="['aktiv', 'beendet']" v-model="subMenu"/>
    </div>
    <TableModule
      :table-data="{ head: head, body: body }"
      :tr-clickable="true"
      primary-sort-default="name"
      secondary-sort-default="name"
      @trClicked="trClicked" />
    <SplitTestModal v-if="showModal" @success="getTests()" @close="showModal=false;"/>
  </div>
</template>

<script>
import TableModule from '../../modules/TableModule.vue';
import axios from '@/axios';
import SplitTestModal from "@/modules/modals/SplitTestModal.vue";
import SubMenuComponent from "@/components/SubMenuComponent.vue";

export default {
  name: 'SplitTestView',
  components: {SubMenuComponent, SplitTestModal, TableModule },
  data() {
    return {
      showModal: false,
      head: [
        { value: 'name', label: 'Name', sortable: true, style: { width: '80%' } },
      ],
      body: [],
      subMenu: 'aktiv',
      filter: 'active'
    };
  },
  created() {
    this.getTests();
  },
  methods: {
    getTests() {
      axios.get('admin/split', {params: {status: this.filter}}).then(res => {
        let tests = res.data;
        let body = [];
        tests.forEach(test => {
          body.push({
            name: { value: test.name, sortable: test.name },
            id: { value: test.id, sortable: test.id, classes: ['hidden'] },
          });
        });
        this.body = body;
      });
    },
    trClicked(row) {
      this.$router.push('/splittest/' + row.id.value);
    },
  },
  watch: {
    subMenu(){
      if (this.subMenu === 'aktiv') {
        this.filter = 'active';
        this.getTests()
      } else if (this.subMenu === 'beendet') {
        this.filter = 'ended';
        this.getTests()
      }
    }
  }
};
</script>

<style scoped lang="scss">
.headline {
  margin-bottom: 16px;

  button {
    font-weight: bolder;
    font-size: 1rem;
  }
}
.subMenu {
  max-width: 300px;
}
</style>
