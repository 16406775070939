<template>
  <div class="subMenu flex-row jst-spc-bt alg-it-c">
    <span
      v-for="(option, index) in options"
      :key="index"
      :class="[{ active: selected === option }, 'pointer']"
      @click="selected = option">
      {{ option }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'SubMenuComponent',
  props: {
    options: {
      type: Array,
      default() {
        return ['test'];
      },
    },
    value: {
      type: String,
    },
  },
  data() {
    return {
      selected: this.value.slice(),
    };
  },
  watch: {
    selected() {
      this.$emit('input', this.selected);
    },
    value() {
      this.selected = this.value.slice();
    },
  },
};
</script>

<style scoped lang="scss">
.subMenu {
  background: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 4px 4px;
  border-radius: 4px;
  margin-bottom: 18px;
  gap: 4px;
  &.bold {
    font-weight: 600;
  }
  span {
    text-align: center;
    flex: 1;
    font-size: 0.8rem;
    border-radius: 4px;
    transition: all 0.2s;
    white-space: nowrap;
    color: $black;
    padding: 0 8px;

    &.active,
    &:hover {
      background: $lightViolet;
      color: $violet;
    }
  }
}
</style>
