import Admin from './AdminView';
import AdminDataUsageView from './AdminDataUsageView';
import AdminUserDetailView from './AdminUserDetailView';
import AdminNotificationsView from "@/views/admin/AdminNotificationsView.vue";
import AdminNotificationsDetailView from "@/views/admin/AdminNotificationsDetailView.vue";

const routes = [
  {
    path: '/users/:filter',
    component: Admin,
    components: {
      desktop: Admin,
      mobile: Admin,
    },
    meta: {
      title: 'Admin',
    },
  },
  {
    path: '/dataUsage',
    component: AdminDataUsageView,
    components: {
      desktop: AdminDataUsageView,
      mobile: AdminDataUsageView,
    },
    meta: {
      title: 'Admin Datennutzung',
    },
  },
  {
    path: '/user/:userId',
    component: AdminUserDetailView,
    components: {
      desktop: AdminUserDetailView,
      mobile: AdminUserDetailView,
    },
    meta: {
      title: 'Admin Useransicht',
    },
  },
  {
    path: '/notifications',
    component: AdminNotificationsView,
    components: {
      desktop: AdminNotificationsView,
      mobile: AdminNotificationsView,
    },
    meta: {
      title: 'Benachrichtigungen',
    }
  },{
    path: '/notifications/:id',
    component: AdminNotificationsDetailView,
    components: {
      desktop: AdminNotificationsDetailView,
      mobile: AdminNotificationsDetailView,
    },
    meta: {
      title: 'Benachrichtigungen - Detailansicht',
    }
  }
];

export default routes;
