<template>
  <div id="mobileNav" class="flex-row alg-it-c jst-spc-bt">
    <router-link to="/buchen" class="normal flex-column alg-it-c jst-c">
      <font-awesome-icon icon="chart-bar" class="icon"/>
      <span>Home</span>
    </router-link>
    <router-link to="/buchungen" class="normal flex-column alg-it-c jst-c">
      <font-awesome-icon icon="exchange-alt" class="icon"/>
      <span>Einträge</span>
    </router-link>
    <section class="middle flex-column alg-it-c jst-c pointer" v-if="subscriptionEnded">
      <font-awesome-icon icon="plus" class="icon"/>
    </section>
    <section class="middle flex-column alg-it-c jst-c pointer" @click="showModal" v-else>
      <font-awesome-icon icon="plus" class="icon"/>
    </section>
    <router-link to="annual" class="normal flex-column alg-it-c jst-c">
      <font-awesome-icon icon="lock" class="icon"/>
      <span>Jahre</span>
    </router-link>
    <router-link to="profile" class="normal flex-column alg-it-c jst-c">
      <font-awesome-icon icon="cog" class="icon" />
      <span>Optionen</span>
    </router-link>
    <AddEntryModalMobile v-show="showAddEntryModal" @close="hideModal" :show="showAddEntryModal"/>
  </div>
</template>

<script>
import AddEntryModalMobile from "./AddEntryModalMobile";
export default {
  name: "NavigationMobile",
  components: {AddEntryModalMobile},
  data() {
    return {
      showAddEntryModal: false,
    }
  },
  methods: {
    showModal(){
      this.showAddEntryModal = true;
      this.$store.commit('setModalActive')

    },
    hideModal(){
      this.$store.commit('setModalInactive')
      this.showAddEntryModal = false;
    }
  },
  computed: {
    subscriptionEnded: function () {
      let user = this.$store.getters['auth/user'].user;
      let today = new Date();
      let until = new Date(user.subscriptionUntil);
      return (today > until);
    },
  }
}
</script>

<style scoped lang="scss">
#mobileNav {
  height: 85px;
  width: 100%;
  background: white;
  position: fixed;
  bottom: 0;
  bottom: env(safe-area-inset-bottom);
  padding: 4px 20px 12px 20px;
  box-sizing: border-box;
  box-shadow: 0 -1px 6px rgba(0, 0, 0, 0.1);
  z-index: 1;
  .normal {
    width: 40px;
    height: 80%;
    color: $grayText;
    gap: 4px;
    span {
      font-size: 0.8rem;
    }
    .icon {
      font-size: 1.2rem;
    }
  }
  .middle {
    width: 50px;
    height: 50px;
    background: $violet;
    color: white;
    border-radius: 50%;
    margin-top: -5px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    .icon {
      font-size: 1.5rem;
    }
  }
}
a {
  color: #2c3e50;

  &:not(:first-of-type).router-link-active {
    color: $violet !important;
    background-size: 0;
  }

  &:first-of-type.router-link-exact-active {
    color: $violet !important;
    background-size: 0;
  }

  &:hover {
    color: $violet;
  }
}
</style>
