<template>
  <div class="receipt flex-column jst-fs alg-it-s">
    <input ref="fileInput" style="display: none" type="file" @change="onFileSelected" :accept="accept" />
    <button @click="$refs.fileInput.click()" class="input file">
      <span v-if="value" class="fileName">{{ value.name }}</span>
      <span v-else class="greyText">Datei auswählen</span>
      <span class="after withFile" v-if="!value"><font-awesome-icon icon="paperclip" /></span>
      <span class="after withFile greyText" v-else><font-awesome-icon icon="paperclip" /></span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'FileUploadComponent',
  props: {
    value: {
      required: false,
    },
    accept: {
      type: String,
      default: 'image/jpeg, .pdf, image/png',
    },
  },
  methods: {
    onFileSelected: function (event) {
      this.$emit('input', event.target.files[0]);
    },
  },
};
</script>

<style scoped lang="scss">
// File Upload
.receipt {
  width: 100%;
  font-size: 0.85rem;

  .fileName {
    margin-top: 4px;
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }
}

.input.file {
  text-align: left;
  height: 37px;
  position: relative;
  transition: color 0.2s;
  width: 100%;

  &:hover {
    background: white;
    color: $violet;
  }

  .after {
    content: '+';
    position: absolute;
    right: 0;
    top: 0;
    font-size: 24px;
    height: 24px;
    font-weight: lighter;
    transition: color 0.2s;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-left: 1px solid #e8e8e8;

    &.withFile {
      font-size: 15px;
      color: $black;
    }
  }
}
</style>
