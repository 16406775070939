import Vue from 'vue';
import VueRouter from 'vue-router';
import adminRoutes from '@/views/admin/admin.router';
import authRoutes from '@/views/auth/auth.router';
import dashboardRoutes from '@/views/dashboard/dashboard.router';
import splittestsRouter from '../views/splittests/splittests.router';
import store from '@/store';

Vue.use(VueRouter);

let allRoutes = [];
allRoutes = allRoutes.concat(adminRoutes, authRoutes, dashboardRoutes, splittestsRouter);

const routes = allRoutes;

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

const DEFAULT_TITLE = 'MILKEE';
router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/register', '/reset'];
  let authRequired = !publicPages.includes(to.path);
  if (to.path.startsWith('/verify/') || to.path.startsWith('/reset/')) {
    authRequired = false;
  }
  const loggedIn = store.state.auth.status.loggedIn;

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
  Vue.nextTick(() => {
    document.title = 'MILKEE - ' + to.meta.title || DEFAULT_TITLE;
  });
});

export default router;
