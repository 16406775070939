<template>
  <table id="tableComponent" v-if="allLoaded">
    <thead v-if="withHead">
    <tr>
      <checkbox-component
          class="checkbox"
          :class="{ showAll: selectedEntries.length }"
          v-if="tableBody.length > 0 && withSelect">
        <input class="input" type="checkbox" @click="checkAll" :checked="areAllEntriesSelected"/>
      </checkbox-component>
      <th
          v-for="(head, index) in header"
          :class="[
            { pointer: head.sortable && sortable === true, violet: currentSort === head.value && sortable },
            head.classes,
          ]"
          @click="handleSort(index)"
          :style="head.style"
          :key="index">
        {{ head.label }}
        <font-awesome-icon
            icon="caret-down"
            v-if="currentSort === head.value && sortable"
            class="sortIcon"
            :class="{ turn: !sortDesc }"/>
        <font-awesome-icon v-else-if="head.sortable && sortable" class="sortIcon" icon="sort"/>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr
        v-for="(row, index) in tableBody"
        :key="index"
        @click="trClicked($event, row)"
        :class="{ pointer: trClickable, noHead: withHead === false, withSelect: withSelect }">
      <checkbox-component class="checkbox" :class="{ showAll: selectedEntries.length }" v-if="withSelect">
        <input class="input" type="checkbox" :value="row.id.value" v-model="selectedEntries"/>
      </checkbox-component>
      <td v-for="(td, index) in row" :class="td.classes" :style="[td.style]" :key="index">
        <!-- Link -->
        <span v-if="td.link" class="link" :class="[td.valClasses]" @click="$emit(td.event, row)">
            <font-awesome-icon v-if="td.iconBefore" :icon="td.iconBefore"/>
            {{ td.value }}
          </span>

        <status-dot-component
            v-else-if="Object.prototype.hasOwnProperty.call(td, 'status')"
            :tooltip="td.tooltip"
            :status="td.status"
            :align="td.align"/>

        <!-- Hoverlink -->
        <section v-else-if="td.hoverLink" :class="[td.valClasses]" class="pointer hoverLink">
          <span class="hoverText">{{ td.value }}</span>
          <span class="link" @click="$emit(td.hoverLinkData.event, row)">
              <font-awesome-icon
                  v-if="td.hoverLinkData.iconBefore"
                  :icon="td.hoverLinkData.iconBefore"
                  class="hoverLinkIcon"/>
              {{ td.hoverLinkData.text }}
            </span>
        </section>

        <section v-else-if="td.tagBefore" class="flex-column jst-fe">
          <span class="tinyText">{{ td.tagBefore }}</span>
          <span :class="td.valClasses">{{ td.value }}</span>
        </section>

        <!-- time tag -->
        <section v-else-if="td.timeTag" class="timeTag">
          <span class="tag gray">🕒&nbsp;&nbsp;{{ td.value }}</span>
        </section>

        <!-- time tag -->
        <section v-else-if="td.tags" class="tagsContainer" :class="[td.classes]">
          <tag-component
              v-for="(tag, index) in td.tags"
              :key="index"
              :name="tag.name"
              :color="tag.color"
              :in-table="true"
              :class="[td.valClasses]"/>
        </section>
        <!-- nothing special -->
        <span v-else :class="[td.valClasses]" :title="td.value">{{ td.value }}</span>

        <!-- multiple values -->
        <span v-for="(val, index) in td.values" :key="index">
            <span v-if="val.link" class="link" :class="[val.valClasses]" @click="$emit(val.event, row)">
              <font-awesome-icon v-if="val.iconBefore" :icon="val.iconBefore"/>
              {{ val.value }}<br/>
            </span>
            <span v-else :class="[val.valClasses]">{{ val.value }}</span>
          </span>
        <span v-if="td.controlButton" :class="[td.classes]">
            <ControlButton
                v-on="$listeners"
                :options="td.controlOptions"
                :identifier="td.controlIdentifier"
                :locked="td.locked"
                :lockedToolTip="getLockedToolTip(td)"/>
          </span>
      </td>
    </tr>
    <tr v-if="noEntries" :class="{ noHead: withHead === false }">
      <td :colspan="visibleHeaderCount" class="greyText" style="width: 100%">{{ emptyText }}</td>
    </tr>
    </tbody>
  </table>
  <table v-else>
    <tr>
      <td style="text-align: center; width: 100%">
        <Loading/>
      </td>
    </tr>
  </table>
</template>

<script>
import ControlButton from '@/modules/ControlButtonModule';
import Loading from './LoadingSpinnerComponent';
import TagComponent from './TagComponent';
import CheckboxComponent from './CheckboxComponent';
import StatusDotComponent from './StatusDotComponent';

export default {
  name: 'TableNew',
  components: {StatusDotComponent, CheckboxComponent, TagComponent, Loading, ControlButton},
  props: {
    header: {
      type: Array,
      required: true,
    },
    body: {
      type: Array,
      required: true,
    },
    sortable: {
      type: Boolean,
      default: true,
    },
    highlightFirst: {
      type: Boolean,
      default: false,
    },
    secondarySortDefault: {
      type: String,
      default: 'number',
    },
    primarySortDefault: {
      type: String,
      required: false,
      default: '',
    },
    trClickable: {
      type: Boolean,
      required: false,
      default: false,
    },
    emptyText: {
      type: String,
      required: false,
      default: 'Keine Einträge gefunden.',
    },
    value: {
      type: Array,
    },
    withSelect: {
      type: Boolean,
      default: false,
    },
    withHead: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      tableBody: this.getBodyCopy(this.body),
      currentSort: this.primarySortDefault === '' ? 'date' : this.primarySortDefault,
      sortDesc: true,
      selectedEntries: [],
      lastCheckedIndex: null,
    };
  },
  computed: {
    areAllEntriesSelected() {
      return this.selectedEntries.length === this.tableBody.length;
    },
    noEntries() {
      return this.tableBody.length === 0;
    },
    visibleHeaderCount() {
      let objs = this.header.filter(obj => {
        if (Object.prototype.hasOwnProperty.call(obj, 'hidden') && obj.hidden === true) {
          return false;
        } else if (Object.prototype.hasOwnProperty.call(obj, 'classes')) {
          if ((Array.isArray(obj.classes) && obj.classes.includes('hidden')) || obj.classes === 'hidden') {
            return false;
          }
        }
        return obj;
      });
      return objs.length;
    },
    allLoaded() {
      return this.$store.getters['loadingIsFinished'];
    },
    tableRowCount() {
      return document.querySelectorAll('tr');
    },
  },
  methods: {
    setCheckboxHeight() {
      if (!this.withSelect || this.body.length === 0) return;
      let trs = document.querySelectorAll('tr');
      trs.forEach(tr => {
        let trHeight = tr.offsetHeight + tr.style.paddingTop + tr.style.paddingBottom;
        tr.querySelector('span').style.height = trHeight + 'px';
      });
    },

    getBodyCopy(oldBody) {
      return JSON.parse(JSON.stringify(oldBody));
    },
    handleSort(index) {
      // Avoid sorting a not sortable column.
      if (this.sortable === false || !this.header[index].sortable) {
        return;
      }
      let sortKey = this.header[index].value;
      if (sortKey === this.currentSort) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortDesc = true;
      }
      this.currentSort = sortKey;
      this.tableBody = this.sort(this.tableBody, sortKey, this.secondarySortDefault);
    },
    sort(objs, sortBy, secondarySortVal) {
      objs.sort(function (a, b) {
        let aSort = a[sortBy].sortable;
        let bSort = b[sortBy].sortable;

        if (typeof aSort === 'string' && typeof bSort === 'string') {
          aSort = aSort.toLowerCase();
          bSort = bSort.toLowerCase();
        }

        if (!aSort) {
          return -1;
        }
        if (aSort > bSort) {
          return 1;
        }
        if (aSort < bSort) {
          return -1;
        }

        // Fields must now be the same value and it should now compare to the secondary sort value.
        if (a[secondarySortVal].sortable > b[secondarySortVal].sortable) {
          return 1;
        }
        if (a[secondarySortVal].sortable < b[secondarySortVal].sortable) {
          return -1;
        }
        return 0;
      });
      if (this.sortDesc) {
        objs = objs.reverse();
      }
      return objs;
    },
    doHighlight() {
      document.querySelector('table > tbody > tr:first-of-type').classList.add('new', 'highlight');
      setTimeout(function () {
        document.querySelector('table > tbody > tr:first-of-type').classList.remove('new');
      }, 700);
    },
    trClicked(event, row) {
      // If the class 'preventTrClick' is on the clicked td, the row-click will not be triggered.
      let clickedTd = event.target.closest('td');
      if (clickedTd == null) {
        return;
      }
      if (clickedTd.classList.contains('preventTrClick')) {
        return;
      }

      if (this.trClickable) {
        this.$emit('trClicked', row);
      }
    },
    checkAll() {
      if (this.areAllEntriesSelected) {
        this.selectedEntries = [];
        return;
      }
      let allIds = [];
      this.tableBody.forEach(entry => {
        allIds.push(entry.id.value);
      });
      this.selectedEntries = allIds;
    },
    getLockedToolTip(td) {
      if (Object.prototype.hasOwnProperty.call(td, 'lockedToolTip')) {
        return td.lockedToolTip;
      }
      return '';
    },
  },
  watch: {
    body: {
      handler(newBody) {
        if (this.highlightFirst) {
          this.doHighlight();
        }
        if (this.sortable) {
          this.tableBody = this.sort(this.getBodyCopy(newBody), this.currentSort, this.secondarySortDefault);
        } else {
          this.tableBody = this.getBodyCopy(newBody);
        }
      },
      deep: true,
    },
    selectedEntries: {
      deep: true,
      handler() {
        this.$emit('input', this.selectedEntries);
      },
    },
    value: {
      handler(newValue) {
        this.selectedEntries = newValue;
      },
    },
    tableBody: {
      deep: true,
      handler() {
        this.$nextTick(() => this.setCheckboxHeight());
      },
    },
  },
};
</script>

<style scoped lang="scss">
table {
  table-layout: fixed;
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.16);
  border-collapse: collapse;
  text-align: left;
  font-size: 14px;
  width: 100%;

  th {
    background: rgb(249, 250, 251);
    color: $labelGray;
    font-weight: 300;

    &:first-of-type {
      border-top-left-radius: 10px;
    }

    &:last-child {
      border-top-right-radius: 10px;
    }

    &.violet {
      color: $violet;
    }

    > .sortIcon {
      margin-left: 4px;
    }
  }

  td,
  th {
    padding: 0.75rem 1.2rem;
    border-bottom: 1px solid $tableGray;
  }

  td {
    padding: 1.05rem 1.2rem;
    color: $black;
    font-weight: 300;

    .tinyText {
      font-size: 9px;
      font-weight: 300;
      width: fit-content;
    }

    .grayTag {
      margin-top: 4px;
    }
  }

  tr {
    position: relative;

    &.withSelect::before {
      content: '';
      position: absolute;
      left: -60px;
      width: 60px;
      max-height: 50px;
      height: 100%;
    }

    &:hover {
      background: rgb(249, 250, 251);

      & > .checkbox {
        visibility: visible;
      }
    }

    &:last-child {
      td {
        border-bottom: none;
      }
    }

    &.noHead {
      &:first-of-type {
        &:hover {
          td:first-of-type {
            border-top-left-radius: 10px;
          }

          td:last-of-type {
            border-top-right-radius: 10px;
          }
        }
      }
    }

    &:last-of-type {
      &:hover {
        td:first-of-type {
          border-bottom-left-radius: 10px;
        }

        td:last-of-type {
          border-bottom-right-radius: 10px;
        }
      }
    }
  }

  .loading {
    td {
      color: white;

      span {
        border-radius: 3px;
        animation-duration: 1.8s;
        color: transparent;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: placeHolderShimmer;
        animation-timing-function: linear;
        background: #f6f7f8;
        background: linear-gradient(to right, #fafafa 8%, #f4f4f4 38%, #fafafa 54%);
        background-size: 1000px 640px;
        position: relative;
        top: 50%;
      }
    }
  }
}

.tag.gray {
  background-color: $darkGray;
  color: $grayText;
  font-weight: bold;
}

.timeTag {
  .tag.gray {
    border-radius: 50px;
  }
}

.tagsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;

  &.alignRight {
    justify-content: flex-end;
  }
}

.hidden {
  display: none;
}

.turn {
  transform: rotate(180deg);
}

.new {
  background-color: rgba(101, 86, 235, 0.18);
}

.highlight {
  transition: all 0.5s;
}

.link {
  font-size: 12px;

  svg {
    margin-right: 8px;
  }
}

.hoverLink {
  position: relative;
  font-size: 12px;

  .hoverLinkIcon {
    position: absolute;
    left: -20px;
  }

  .link {
    display: none;
    text-decoration: none;
    background: none;
  }

  &:hover {
    .hoverText {
      display: none;

      & + .link {
        display: inline-block;
      }
    }
  }
}

.checkbox {
  position: absolute;
  left: -50px;
  visibility: hidden;
  transition: all 0.2ms;
  padding-right: 22px;
  padding-left: 8px;

  &.showAll {
    visibility: visible;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@media screen and (max-width: 800px) {
  table {
    td,
    th {
      padding: 0.75rem 0.75rem;
      border-bottom: 1px solid $tableGray;
    }
  }
}
</style>
