<template>
  <div class="tooltip pointer hideOnTablet">
    <span class="tooltipIcon" @click="showTooltip = !showTooltip"><font-awesome-icon icon="question-circle" /></span>
    <div class="tooltipText" :class="{show: showTooltip === true}">
      <slot></slot>
    </div>
    <div class="backdrop" @click="showTooltip = !showTooltip" v-if="showTooltip"></div>
  </div>
</template>

<script>
export default {
  name: "TooltipComponent",
  props: {
    text: {
      type: String
    }
  },
  data() {
    return {
      showTooltip: false
    }
  }
}
</script>

<style scoped lang="scss">
.tooltip {
  display: inline-flex;
  position: relative;
  .tooltipIcon {
    color: $grayText;
    font-size: 0.9rem;
  }
}
.tooltipText {
  opacity: 0;
  z-index: -1000;
  position: absolute;
  left: 16px;
  top:0;
  background: $black;
  color: white;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  padding: 6px 8px;
  font-size: 0.85rem;
  white-space: nowrap;
  transition: opacity 0.2s;

}
.tooltipText.show{
  opacity: 1;
  z-index: 100;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.0);
}

a {
  color: $blueLinkColor;
}
</style>
