import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import VueApexCharts from 'vue-apexcharts';
import VueGtag from 'vue-gtag';
import wb from './registerServiceWorker';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import VuePapaParse from 'vue-papa-parse';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { BrowserTracing } from '@sentry/tracing';
import vueMultiselect from 'vue-multiselect';
import Toast, { POSITION } from 'vue-toastification';
// Import the CSS or use your own!
import 'vue-toastification/dist/index.css';
import * as Sentry from '@sentry/vue';
import Hotjar from 'vue-hotjar';

if (process.env.VUE_APP_ENV === 'prod') {
  Vue.use(Hotjar, {
    id: '3335320',
  });
  // Sentry.
  Sentry.init({
    Vue,
    dsn: 'https://2ec13d34586d49cf8a6f8ac5de7eb874@o4504197664079872.ingest.sentry.io/4504197665259520',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ['*'],
        tracingOrigins: ['*'],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

Vue.use(VuePapaParse);

const options = {
  position: POSITION.TOP_LEFT,
  timeout: 3000,
};
Vue.use(Toast, options);

Vue.prototype.$workbox = wb;
if (process.env.VUE_APP_ENV === 'prod') {
  Vue.use(VueReCaptcha, { siteKey: '6LdiHZgfAAAAAO26Jxz4JOIUutZBaFyBLTUVGEfT' });
} else {
  Vue.use(VueReCaptcha, { siteKey: '6LfvoOMhAAAAADOaXLefXL-b9tb4T5__EvHidMtI' });
}

Vue.use(VueGtag, {
  config: { id: 'G-0CHT9Z3LGN' },
  //includes: [
  //{ id: 'AW-10825606845' },
  //]
});

Vue.use(VueApexCharts);
Vue.component('VueApexCharts', VueApexCharts);
Vue.component('v-select', vSelect);
Vue.component('v-multiselect', vueMultiselect);

// Focus in load
Vue.directive('focus', {
  // When the bound element is inserted into the DOM...
  inserted: function (el) {
    // Focus the element
    el.focus();
  },
});

library.add(fas);

Vue.config.productionTip = false;
Vue.component('font-awesome-icon', FontAwesomeIcon);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
