import Vue from 'vue';
import Vuex from 'vuex';

import {auth} from './auth.module';
import {notices} from './notices.module'
import {mobileModal} from "./mobileModal.module";
import {loadingModule} from "./loading.module";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        notices,
        mobileModal,
        loadingModule
    }
});
