<template>
  <div class="flex-column gap40 profileSettingsModule jst-spc-bt">
    <white-box>
      <h3>Meine Zahlungsangaben</h3>
      <label class="flex-column">
        <span class="label">IBAN</span>
        <input class="input" type="text" v-model="user.iban"/>
      </label>
      <label class="flex-column">
        <span class="label">Standardzahlungsfrist in Tagen</span>
        <input class="input" type="number" v-model="user.defaultGrace"/>
      </label>
      <section class="flex-row jst-fe">
        <button class="small primary" @click="saveUser">Speichern</button>
      </section>
    </white-box>
    <white-box>
      <h3>Rechnungs-Design</h3>
      <span class="label">Logo</span>
      <div class="logo">
        <img v-if="logoURL && !logoLoading" :src="logoURL" alt="Logo"/>
        <LoadingSpinnerComponent v-else-if="logoLoading"/>
      </div>
      <span class="label">Neues logo hochladen (max. 2MB)</span>
      <FileUploadComponent v-model="newLogo" @change="uploadNewLogo" accept="image/png, image/jpeg"/>
      <section class="checkbox">
        <span class="label">Ausrichtung Adresszeile</span>
        <CheckboxComponent>
          <label>
            <input type="radio" class="small" v-model="user.invoiceAlign" value="right"> Adresszeile rechts
          </label>
        </CheckboxComponent>
        <CheckboxComponent>
          <label>
            <input type="radio" class="small" v-model="user.invoiceAlign" value="left"> Adresszeile links
          </label>
        </CheckboxComponent>
      </section>
      <section>
        <label class="label">
          Schriftart für Rechnungsdokumente
          <tooltip-component>
            Die Schriftart wird erst bei neuen Rechnungen verändert.<br>
          </tooltip-component>
          <select class="input" v-model="user.invoiceFont">
            <option value="poppins">Poppins</option>
            <option value="roboto">Roboto</option>
            <option value="publicsans">Public Sans</option>
            <option value="inter">Inter</option>
            <option value="opensans">Open Sans</option>
            <option value="montserrat">Montserrat</option>
          </select>
        </label>
      </section>

      <section class="flex-row jst-fe">
        <button class="small primary" @click="saveUser">Speichern</button>
      </section>
    </white-box>
    <white-box class="mwst">
      <h3>MWST</h3>
      <section class="checkbox">
        <span class="label">MWST aktivieren</span>
        <CheckboxComponent>
          <label>
            <input type="checkbox" class="small" v-model="user.vatActive" value="right"> MWST
            <span v-if="user.vatActive">&nbsp;ist aktiv</span>
            <span v-else>&nbsp;ist deaktiviert</span>
          </label>
        </CheckboxComponent>
      </section>
      <div>
        <label class="flex-column" v-if="user.vatActive">
          <span class="label">MWST-Nummer</span>
          <input class="input" type="text" v-model="user.vatNumber" placeholder="CHE-123.456.789 MWST"/>
        </label>
      </div>
      <section v-if="user.vatActive">
        <label class="label">
          MWST-Satz auswählen
          {{ user.vatRate }}
          <select class="input" v-model="user.vatRate">
            <option value="7.7">Normalsatz 7.7%</option>
            <option value="3.7">Sondersatz (3.7%)</option>
            <option value="2.5">Reduzierter Satz (2.5%)</option>
            <option value="0">Befreit(0%)</option>
          </select>
        </label>
      </section>
      <section class="checkbox" v-if="user.vatActive">
        <span class="label">MWST inklusiv / exklusiv</span>
        <CheckboxComponent>
          <label>
            <input type="radio" class="small" v-model="user.includeVat" value="1"> Eingegebene Preise sind inkl. MWST
          </label>
        </CheckboxComponent>
        <CheckboxComponent>
          <label>
            <input type="radio" class="small" v-model="user.includeVat" value="0"> Eingegebene Preise sind exkl. MWST
          </label>
        </CheckboxComponent>
      </section>
      <section class="flex-row jst-fe">
        <button class="small primary" @click="saveUser">Speichern</button>
      </section>
    </white-box>
  </div>
</template>

<script>
import WhiteBox from '../components/WhiteBox';
import FileUploadComponent from '../components/FileUploadComponent.vue';
import axios from '@/axios';
import LoadingSpinnerComponent from '../components/LoadingSpinnerComponent.vue';
import CheckboxComponent from "../components/CheckboxComponent.vue";
import TooltipComponent from "../components/TooltipComponent.vue";

export default {
  name: 'InvoicesSettingsModule',
  components: {TooltipComponent, CheckboxComponent, LoadingSpinnerComponent, FileUploadComponent, WhiteBox},
  data() {
    return {
      logoURL: null,
      newLogo: null,
      logoLoading: false,
      user: null,
      oldPass: null,
      newPass: null,
      newRep: null,
    };
  },
  computed: {
    userComputed() {
      return this.$store.getters['auth/user'].user;
    },
  },
  created() {
    this.getLogoUrl();
    this.user = {...this.userComputed};
    this.formatIban();
  },
  methods: {
    getLogoUrl() {
      axios.get('auth/logoUrl').then(res => {
        this.logoURL = res.data.url;
      });
    },
    uploadNewLogo() {
      this.logoLoading = true;
      const formData = new FormData();
      formData.append('logo', this.newLogo);
      axios
          .post('auth/logo', formData)
          .then(res => {
            this.logoLoading = false;
            this.logoURL = res.data.url;
            this.newLogo = null;
            this.$toast.success('Logo wurde aktualisiert.');
          })
          .catch(e => {
            this.logoLoading = false;
            this.newLogo = null;
            this.$toast.error(e.response.data.message);
          });
    },
    saveUser() {
      let user = {...this.user};
      if (user.iban != null) {
        user.iban = user.iban.replaceAll(' ', '');
      } else {
        delete user.iban;
      }
      axios
          .post('auth/update', user)
          .then(res => {
            this.$store.dispatch('auth/updateUser', res.data);
            this.formatIban();
            this.$toast.success('Deine Daten wurden aktualisiert.');
          })
          .catch(e => {
            this.user = {...this.userComputed};
            this.$toast.error(e.response.data.message);
          });
    },
    formatIban() {
      if (Object.prototype.hasOwnProperty.call(this.user, 'iban') && this.user.iban != null) {
        let iban = this.user.iban.replaceAll(' ', '');
        iban = [...iban];
        this.user.iban = iban
            .map((d, i) => (i % 4 == 0 ? ' ' + d : d))
            .join('')
            .trim();
      }
    },
    resetPass() {
      if (this.newPass === this.newRep) {
        axios
            .post('/auth/update', {
              oldPassword: this.oldPass,
              newPassword: this.newPass,
            })
            .then(res => {
              this.user = res.data;
              this.$toast.success('Dein Passwort wurde aktualisiert.');
            })
            .catch(e => {
              this.$store.dispatch('notices/addNotice', {
                type: 'error',
                notice: e.response.data.message,
              });
            });
      } else {
        this.$toast.error('Passwörter stimmen nicht überein.');
      }
    },
  },
  watch: {
    newLogo: {
      deep: true,
      handler(newLogo) {
        if (newLogo != null) {
          this.uploadNewLogo();
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">

h3 {
  margin-bottom: 12px;
}

.input {
  max-width: 400px;
  width: 100%;
}

.logo {
  max-height: 100px;

  img {
    height: 100%;
    max-height: 100px;
  }
}

section.checkbox {
  margin-bottom: 12px;
}

.whiteBox {
  min-width: 370px;
}
</style>
