<template>
  <div v-if="reset" class="verifyView">
    <h1>Passwort zurücksetzen</h1>
  </div>
  <div v-else class="verifyView">
    <h1>E-Mail verifizieren</h1>
    <div v-if="verified === false && failed === false">
      <p>Wir verifizieren deine E-Mail...</p>
    </div>
    <div v-if="verified">
      <p>Deine E-Mail wurde erfolgreich verifiziert.</p>
    </div>
    <div v-if="failed === true">
      <p>{{ failedMessage }}</p>
    </div>
    <button class="primary" @click="$router.push('/')" v-if="loggedIn">Zurück zum Dashboard</button>
    <button class="primary" @click="$router.push('/login')" v-else>Einloggen</button>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "VerifyView",
  data() {
    return {
      reset: this.$route.path.startsWith('/reset/'),
      verified: false,
      failed: false,
      failedMessage: '',
      id: this.$route.params.id,
      code: this.$route.params.code
    }
  },
  computed: {
    loggedIn() {
      return this.$store.getters["auth/loggedIn"];
    }
  },
  created() {
    this.verify()
  },
  mounted() {
    this.$gtag.pageview({page_path: '/verify'})
  },
  methods: {
    verify() {
      axios.get(process.env.VUE_APP_BASE_URL + '/auth/verify/' + this.id + '/' + this.code).then(res => {
        if (this.loggedIn) {
          this.$store.dispatch('auth/updateUser', res.data)
          this.$router.go();
        }
        this.verified = true;
      }).catch(res => {
        this.failedMessage = res.response.data.message;
        this.failed = true;
      })
    }
  }
}
</script>

<style scoped>

</style>
