<template>
  <div class="flex-column gap20">
    <div class="headline flex-row jst-spc-bt alg-it-c">
      <h1>Benachrichtigungen</h1>
      <section class="flex-row gap8">
        <decent-button-drop-down-component text="Aktionen" :options="buttonOptions" @send="showSendModal=true" @edit="$refs.modal.show()" />
      </section>
    </div>
    <div>
      <tag-component name="versendet" color="green" v-if="notification.status === 'sent'"/>
      <tag-component name="entwurf" color="gray" v-if="notification.status === 'draft'"/>
    </div>
    <div class="flex-row gap20">
      <white-box class="tag">
        {{ notification.openRate }} %
        <span>geöffnet</span>
      </white-box>
      <white-box class="tag">
        {{ notification.sentCount }}
        <span>versendet</span>
      </white-box><white-box class="tag">
        {{ notification.openCount }}
        <span>geöffnet</span>
      </white-box>
    </div>
    <div>
      <h3>Ausgewählte User ({{users.length}})</h3>
      <br>
      <table-module :table-data="{ head: head, body: body }" primary-sort-default="name" secondary-sort-default="email" @removeUser="removeUser"/>
    </div>
    <NotificationModalModule ref="modal" :id="id" @success="getNotification"/>
    <modal-module v-if="showSendModal">
      <template v-slot:header>
        <h2>Benachrichtigung versenden</h2>
      </template>
      <template v-slot:body>
        <p>Bist du dir sicher, dass du diese Nachricht versenden willst?</p>
      </template>
      <template v-slot:footer>
          <button class="gray bold" @click="showSendModal = false">Abbrechen</button>
          <button class="primary bold" @click="sendNotification">Versenden</button>
      </template>
    </modal-module>
  </div>
</template>

<script>

import NotificationModalModule from "@/modules/NotificationModalModule.vue";
import TableModule from "@/modules/TableModule.vue";
import axios from "@/axios";
import DecentButtonDropDownComponent from "@/components/DecentButtonDropDownComponent.vue";
import ModalModule from "@/modules/ModalModule.vue";
import TagComponent from "@/components/TagComponent.vue";
import WhiteBox from "@/components/WhiteBox.vue";

export default {
  name: "AdminNotificationsView",
  components: {WhiteBox, TagComponent, ModalModule, DecentButtonDropDownComponent, TableModule, NotificationModalModule},
  data() {
    return {
      id: this.$route.params.id,
      showSendModal: false,
      notification: {},
      users: [],
      buttonOptions: [
        {title: 'Versenden', callback: 'send', icon: 'paper-plane'},
        {title: 'Bearbeiten', callback: 'edit', icon: 'edit'},
      ],
      head: [
        {value: 'name', label: 'Name', sortable: true, style: {width: '20%'}},
        {value: 'email', label: 'E-Mail', sortable: true, style: {width: '40%'}},
        {value: 'trash', label: 'Entfernen', sortable: false, style: {width: '20%', textAlign: 'right'}},
      ],
      body: []
    }
  },
  created() {
    this.getNotification();
  },
  methods: {
    trClicked(row) {
      this.$router.push('/notifications/' + row.id.value);
    },
    getNotification() {
      axios.get('admin/notifications/'+this.id).then(res => {
        this.notification = res.data;
        this.getUsers();
      });
    },
    sendNotification() {
      axios.put('admin/notifications/'+this.id+'/send').then(res => {
        this.notification = res.data;
        this.showSendModal = false;
        this.$toast.success('Benachrichtigung wurde versendet');
      }).catch(e => {
        console.log(e.response.data.message)
      });
    },
    removeUser(user) {
      axios.delete('admin/notifications/'+this.id+'/users/'+user.id+'/remove').then(() => {
        this.getUsers();
        this.$toast.success = 'User wurde entfernt';
      });
    },
    getUsers() {
      axios.get('admin/notifications/'+this.id+'/users').then(res => {
        this.users = res.data;
        let body = [];
        this.users.forEach(user => {
          body.push({
            name: {value: user.name, sortable: user.name},
            email: {value: user.email, sortable: user.email},
            id: {value: user.id, sortable: user.id, classes: ['hidden']},
            edit: {
              controlButton: true,
              controlIdentifier: user,
              controlOptions: [
                {
                  title: 'User entfernen',
                  callback: 'removeUser',
                  icon: 'trash',
                },
              ],
              lockedToolTip: 'Dieses Jahr wurde bereits abgeschlossen.',
            },
          });
        });
        this.body = body;
      });
    }
  },
}
</script>

<style scoped>

</style>